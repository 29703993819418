import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import {
  Cases,
  DELETE_CONFIRM,
  SinglePage,
  btnName,
  modalsName,
} from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import ImagesPopup from "./imagesPopup";
import EditIcon from "@mui/icons-material/Edit";
import GalleryContext from "../../../context/GalleryContext/GalleryContext";
import {
  deleteImagesParent,
  getImages,
  getImagesDetails,
  getImagesParent,
  getImagesParentDetails,
  postImages,
  postImagesParent,
  putUpdateImages,
  putUpdateImagesParent,
  updateStatus,
  updateStatusImages,
} from "../../../apis/ImageApi/ImageApi";
import ImageParentPopup from "./ImagesPerentPopup";
import { FILE_UPLOAD_API } from "../../../apis/SliderApi/SliderApi";
import { randomStr } from "../../../../utils/helper";
import { FILE_TYPE, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";

const Images = (props) => {
  const { webMasterDetail, navbarData, getData } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const simpleValidatorImages = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState();
  const {
    apiData: imagesData,
    setApiData,
    fromData,
    setFormData,
    formDataImageParent,
    setFormDataImageParent,
    id,
    setId,
    setIsEdit,
    isEdit,
    filter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    galleryImgId,
    setGalleryImgId,
  } = useContext(GalleryContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * get images list
   */
  const getImagesList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiData((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter, galleryId: id },
        showAll: true,
        galleryId: id,
      };
      const { data } = await getImages(paramObj);
      if (data.status === 200) {
        setApiData({
          ...imagesData,
          isLoading: false,
          data: data.payload.data.map((elem) => {
            return {
              id: elem?.title + elem?.galleryImgId,
              Id: elem?.galleryImgId,
              name: elem?.title,
              image: elem?.path,
              isDefault: elem?.isDefault,
              webId: elem?.webId,
              createdAt: elem?.createdAt,
              isActive: elem?.isActive,
            };
          }),
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * get our team list
   * @param {status} status
   */
  const getImagesParentList = async (status) => {
    try {
      if (!filter.length && status) {
        setApiDataImageParent((prev) => {
          return { ...prev, isLoading: true };
        });
      }
      let paramObj = {
        search: { title: filter },
      };
      const { data } = await getImagesParent(paramObj);
      if (data.status === 200) {
        setApiDataImageParent({
          ...apiDataImageParent,
          isLoading: false,
          data: data.payload.data,
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  useMemo(() => {
    if (allPopupState?.imagesPopup) {
      getImagesList();
    }
  }, [filter, allPopupState?.imagesPopup]);

  useEffect(() => {
    getImagesParentList();
  }, []);

  /**
   * images enable disable api call
   * @param {galleryImg id} galleryImgId
   * @param {status} isActive
   */

  const handleChangeStatus = async (galleryImgId, isActive) => {
    try {
      let body = {
        galleryImgId,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatusImages(body);
      if (data.status === 200) {
        getImagesList(false);
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  /**
   * images parent enable disable api call
   * @param {gallery id} galleryId
   * @param {status} isActive
   */

  const handleChangeImageParent = async (galleryId, isActive) => {
    try {
      let body = {
        galleryId,
        isActive: isActive ? false : true,
        navMapId: navbarData[0].navMapId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        getImagesParentList();
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.BYTE_SLIDER);
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setFormData((prev) => {
          return {
            ...prev,
            image: data.payload.baseUrl + data.payload.imageUrl,
          };
        });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * create and update images items
   */
  const handleSubmit = async () => {
    debugger;
    const formValid = simpleValidatorImages.current.allValid();
    if (!formValid) {
      simpleValidatorImages.current.showMessages();
      forceUpdate1(1);
    } else {
      if (galleryImgId >= -1 && isEdit) {
        setLoaderShow(true);
        let newData = {
          ...fromData,
          galleryId: id,
          galleryImgId: galleryImgId,
          navMapId: navbarData[0].navMapId,
        };
        if (fromData.image.length) {
          setFormData((prev) => {
            return {
              ...prev,
              path: prev.image
                ? prev.image
                    .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                    .pop()
                : "",
              galleryId: id,
              galleryImgId: galleryImgId,
              navMapId: navbarData[0].navMapId,
            };
          });
          newData["path"] = fromData.image
            ? fromData.image
                .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                .pop()
            : "";
        }
        try {
          const { data } = await putUpdateImages(newData);
          if (data.status === 200) {
            getImagesList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newData = {
            ...fromData,
            galleryId: id,
            order: imagesData.data.length + 1,
            navMapId: navbarData[0].navMapId,
          };
          if (fromData.image.length) {
            setFormData((prev) => {
              return {
                ...prev,
                path: prev.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop(),
                galleryId: id,
                order: imagesData.data.length + 1,
                navMapId: navbarData[0].navMapId,
              };
            });
            newData["path"] = fromData.image
              ? fromData.image
                  .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
                  .pop()
              : "";
          }
          // if (!newData?.title) delete newData?.title;
          console.log("newData >> ", newData);

          const { data } = await postImages(newData);
          if (data.status === 200) {
            getImagesList();
            emptyData();
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * create and update images
   */
  const handleSubmitImages = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (id >= -1 && isEdit) {
        setLoaderShow(true);
        try {
          let newFormData = {
            ...formDataImageParent,
            navMapId: navbarData[0].navMapId,
          };
          const { data } = await putUpdateImagesParent(newFormData);
          if (data.status === 200) {
            getImagesParentList();
            emptyData();
            OpenModalCommonFunc(Cases.IMAGES_PARENT, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      } else {
        try {
          setLoaderShow(true);
          let newFormData = {
            ...formDataImageParent,
            navMapId: navbarData[0].navMapId,
          };
          const { data } = await postImagesParent(newFormData);
          if (data.status === 200) {
            getImagesParentList();
            emptyData();
            OpenModalCommonFunc(Cases.IMAGES_PARENT, setAllPopupState, false);
            TostMessage({ message: data, type: "success" });
          }
        } catch (error) {
          TostMessage({ message: error, type: "error" });
          setLoaderShow(false);
        }
      }
    }
  };

  /**
   * get images items details
   * @param {id} galleryImgId
   */
  const handleEdit = async (galleryImgId) => {
    try {
      setLoaderShow(true);
      setGalleryImgId(galleryImgId);
      const { data } = await getImagesDetails(galleryImgId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setIsEdit(true);
        setFormData({
          title: payload.title,
          path: payload.path,
          image: payload.path,
          galleryImgId: payload.galleryImgId,
          isDefault: payload.isDefault,
          webId: localStorage.getItem("webId"),
        });
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get data images
   * @param {id} galleryId
   */
  const handleEditImagesParent = async (galleryId) => {
    try {
      setLoaderShow(true);
      setId(galleryId);
      const { data } = await getImagesParentDetails(galleryId);
      if (data.status === 200) {
        let payload = data.payload.data;
        setFormDataImageParent({
          title: payload.title,
          description: payload.description,
          isAlbum: payload.isAlbum,
          galleryId: galleryId,
          webId: localStorage.getItem("webId"),
        });
        OpenModalCommonFunc(Cases.IMAGES_PARENT, setAllPopupState);
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete images
   * @param {id} teamId
   */
  const handleDelete = async (teamId) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteImagesParent(teamId);
        if (data.status === 200) {
          getImagesParentList();
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */
  const emptyData = () => {
    setIsEdit(false);
    setFormData({
      title: "",
      path: "",
      image: "",
      webId: localStorage.getItem("webId"),
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataImageParent({
      title: "",
      headLine: "",
      image: "",
      webId: localStorage.getItem("webId"),
    });
    setLoaderShow(false);
    simpleValidatorImages.current.hideMessages();
    forceUpdate1(1);
    simpleValidator.current.hideMessages();
    forceUpdate(1);
  };

  const filterValue = () => {
    let [value] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Certificates
    );
    return value;
  };

  const value = useMemo(() => filterValue(), [navbarData]);
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody className="support-ticket-font box-border-div">
            <Row>
              <CardBuilderHeaderSection
                name={value?.label || "Gallery"}
                isAdd={
                  webMasterDetail?.webCategory?.name ===
                    SinglePage?.WebCategory &&
                  apiDataImageParent?.data?.length !==
                    SinglePage?.Image_Parent_Limit
                    ? true
                    : false
                }
                addBtnTitle={"Gallery Section"}
                openModal={() =>
                  OpenModalCommonFunc(Cases.IMAGES_PARENT, setAllPopupState)
                }
              />
              {apiDataImageParent.data.map((elem) => {
                return (
                  <Col xl="3 xl-50 box-col-6" md="6" key={elem.galleryId}>
                    <Card className="ecommerce-widget ">
                      <CardBody className="support-ticket-font">
                        <div className="col-12">
                          <CardBuilderHeaderSection
                            name={elem?.title}
                            checked={elem?.isActive}
                            switchToggle={true}
                            id={elem?.galleryId}
                            handleChange={handleChangeImageParent}
                          />
                          <OpenPopupBtn
                            openPopup={() => {
                              OpenModalCommonFunc(
                                Cases.IMAGES,
                                setAllPopupState
                              );
                              setId(elem.galleryId);
                            }}
                            name={btnName.IMAGES}
                          />
                        </div>
                        <div className="d-flex justify-content-end ">
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() =>
                              handleEditImagesParent(elem.galleryId)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            edge="start"
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                            onClick={() => handleDelete(elem.galleryId)}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.imagesPopup}
        close={() => OpenModalCommonFunc(Cases.IMAGES, setAllPopupState, false)}
        modalName={modalsName.IMAGES}
        modalCase={Cases.IMAGES}
        handleSubmit={handleSubmit}
        emptyData={emptyData}
        inputData={fromData}
        edit={true}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <ImagesPopup
            handleChangeStatus={handleChangeStatus}
            getData={getImagesList}
            handleEdit={handleEdit}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={simpleValidatorImages}
            webMasterDetail={webMasterDetail}
          />
        }
      />

      {/* images parent popup */}
      <CommonPopup
        open={allPopupState.imagesParentPopup}
        close={() =>
          OpenModalCommonFunc(Cases.IMAGES_PARENT, setAllPopupState, false)
        }
        modalName={modalsName.IMAGES_PARENT}
        modalCase={Cases.IMAGES_PARENT}
        handleSubmit={handleSubmitImages}
        emptyData={emptyData}
        inputData={formDataImageParent}
        edit={false}
        content={<ImageParentPopup simpleValidator={simpleValidator} />}
      />
    </>
  );
};

export default Images;
