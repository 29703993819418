import React, { useContext, useEffect } from "react";
import { DELETE_CONFIRM, ServiceAndCategoryTheme } from "../../../constant";
import { Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { getNavbarList } from "../apis/AboutApi/AboutUsApi";
import { delete_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { TostMessage } from "../../../utils";
import { Zoom, toast } from "react-toastify";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import {
  ThemNo,
  ThemeNo,
  serviceAndCategoryTheme1,
  serviceAndCategoryTheme10,
  serviceAndCategoryTheme11,
  serviceAndCategoryTheme12,
  serviceAndCategoryTheme13,
  serviceAndCategoryTheme14,
  serviceAndCategoryTheme15,
  serviceAndCategoryTheme2,
  serviceAndCategoryTheme3,
  serviceAndCategoryTheme4,
  serviceAndCategoryTheme6,
  serviceAndCategoryTheme7,
  serviceAndCategoryTheme8,
  serviceAndCategoryTheme9,
} from "../../../../constant";
import { slugUrlConverter } from "../../../../utils/helper";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";

const ServiceCategoryPopup = (props) => {
  const { imageUpload, simpleValidator } = props;
  const { setFormDataOurData, formDataOurTeam, isEdit } = useContext(
    ServiceAndCategoryContext
  );
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { name, value, checked } = e.target;
    setFormDataOurData((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeFifteen) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme15,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeFourteen) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme14,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeThirteen) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme13,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeTwelve) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme12,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeEleven) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme11,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeTen) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme10,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeNine) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme9,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeEight) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme8,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeSeven) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme7,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeSix) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme6,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeFive) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme2,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeFour) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme4,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeThree) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme3,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeTwo) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme2,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeOne) {
        return {
          ...prev,
          [name]: value,
          style: serviceAndCategoryTheme1,
        };
      } else if (name === "title") {
        return {
          ...prev,
          [name]: value,
          slugUrl: slugUrlConverter({ value }),
        };
      } else if (name === "slugUrl") {
        return {
          ...prev,
          [name]: slugUrlConverter({ value }),
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setFormDataOurData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: formDataOurTeam.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
            .pop(),
        };
        const { data } = await delete_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setFormDataOurData({ ...formDataOurTeam, image: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              marginBottom: "3rem",
            }}
          >
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formDataOurTeam.isTitleShow}
                      onChange={changeFormValue}
                    />
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataOurTeam.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataOurTeam.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title *"
                    value={formDataOurTeam.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Slug Url *"}</Label>
                  <Input
                    className="form-control"
                    name="slugUrl"
                    type="text"
                    placeholder="Slug Url *"
                    value={formDataOurTeam.slugUrl}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "headline",
                      formDataOurTeam.slugUrl,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Theme"}</Label>
                  <select
                    name="theme"
                    id="theme"
                    className="form-select"
                    style={{ borderRadius: "0", color: "black" }}
                    value={formDataOurTeam.theme}
                    onChange={(e) => changeFormValue(e)}
                  >
                    {ServiceAndCategoryTheme.map((ele) => {
                      return (
                        <option value={ele.theme} key={ele.theme}>
                          {ele.themeName}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Button text"}</Label>
                  <Input
                    className="form-control"
                    name="buttonText"
                    type="text"
                    placeholder="Button text"
                    value={formDataOurTeam?.buttonText}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Item Limit"}</Label>
                  <Input
                    className="form-control"
                    name="itemLimit"
                    type="number"
                    placeholder="Item Limit"
                    value={formDataOurTeam?.itemLimit}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
              </Row>
              {/* <Dividers divide="IMAGE" />
              <p className="mb-4">
                File type required JPG, PNG (512px X 512px)
              </p>
              <Row>
                <Col md="6 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      onClick={() =>
                        OpenModalCommonFunc(
                          Cases.PRODUCT_UPLOAD,
                          setAllPopupState
                        )
                      }
                    >
                      <UploadCloud />
                    </span>
                    {formDataOurTeam?.image && (
                      <img
                        src={
                          formDataOurTeam?.image ? formDataOurTeam?.image : ""
                        }
                        alt=""
                        style={{
                          width: "80%",
                          height: "98%",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() =>
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          )
                        }
                      />
                    )}
                    {formDataOurTeam.image && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{ fontSize: "25px", fontWeight: "bold" }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
              </Row> */}
            </div>
          </div>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataOurTeam.image}
          setImage={setFormDataOurData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1 / 1}
        />
      </Container>
    </>
  );
};

export default ServiceCategoryPopup;
