import React, { useContext } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import ByteCardBuilderContext from "../../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../../Modals/imageUploade/productImageUploadPopup";
import HeaderContext from "../../../../context/HeaderContext/HeaderContext";
import NavbarStyleTwo from "./NavbarStyleTwo";
import NavbarStyleOne from "./NavbarStyleOne";
import {
  navBarStyleOne,
  navBarStyleThree,
  navBarStyleTwo,
} from "./NavbarStyleFunction";
import { ThemeNo } from "../../../../../constant";
import ThemeImageShow from "../../../../common/ThemeImageShow";
import NavbarTheme1 from "../../ScreenShorts/NavBar/NavbarTheme1.png";
import NavbarTheme2 from "../../ScreenShorts/NavBar/NavbarTheme2.png";
import NavbarTheme3 from "../../ScreenShorts/NavBar/NavbarTheme3.png";
import NavbarStyleThree from "./NavbarStyleThree";

const SettingHeadersPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting } = props;
  const {
    settingFormData,
    setSettingFormData,
    isEdit,
    setIsBackgroundColor,
    theme,
    setTheme,
    imgShow,
    setImgShow,
  } = useContext(HeaderContext);

  const resetHandler = () => {
    setSettingFormData((prev) => {
      if (+theme === ThemeNo.ThemeOne) {
        return {
          ...navBarStyleOne,
          logo: prev?.logo,
          Footer: prev?.Footer,
          custom: "",
        };
      } else if (+theme === ThemeNo.ThemeTwo) {
        return {
          ...navBarStyleTwo,
          isCenterNavs: false,
          logo: prev?.logo,
          Footer: prev?.Footer,
          custom: "",
        };
      } else if (+theme === ThemeNo.ThemeThree) {
        return {
          ...navBarStyleThree,
          isCenterNavs: false,
          logo: prev?.logo,
          Footer: prev?.Footer,
          custom: "",
        };
      }
    });

    setIsBackgroundColor(false);
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const handleTheme = (e) => {
    const { value } = e.target;
    setTheme(value);
    setSettingFormData((prev) => {
      if (+value === ThemeNo.ThemeOne) {
        return {
          ...navBarStyleOne,
          isCenterNavs: false,
          logo: prev?.logo,
          favicon: prev?.favicon,
          Footer: prev?.Footer,
          custom: "",
        };
      } else if (+value === ThemeNo.ThemeTwo) {
        return {
          ...navBarStyleTwo,
          isCenterNavs: false,
          logo: prev?.logo,
          favicon: prev?.favicon,
          Footer: prev?.Footer,
          custom: "",
        };
      } else if (+value === ThemeNo.ThemeThree) {
        return {
          ...navBarStyleThree,
          isCenterNavs: false,
          logo: prev?.logo,
          favicon: prev?.favicon,
          Footer: prev?.Footer,
          custom: "",
        };
      }
    });
  };
  const handleClose = () => {
    setImgShow(false);
  };
  const themeImg = {
    1: NavbarTheme1,
    2: NavbarTheme2,
    3: NavbarTheme3,
  };
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "87px", zIndex: "100", background: "white" }}
              >
                <h6>{"Edit"}</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button
                    onClick={() => handleSubmitSetting(false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row>
                <Col>
                  <label htmlFor="">Theme</label>
                  <select
                    name="theme"
                    id="font-weight"
                    className="form-select"
                    value={theme}
                    onChange={(e) => handleTheme(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--Select--</option>
                    <option value="1">Theme 1</option>
                    <option value="2">Theme 2</option>
                    <option value="3">Theme 3</option>
                  </select>
                </Col>
              </Row>
              {!imgShow && (
                <div>
                  <Button
                    onClick={() => setImgShow(true)}
                    color="primary"
                    className="mt-2"
                  >
                    Show Theme Image
                  </Button>
                </div>
              )}
              {imgShow && (
                <ThemeImageShow
                  themeImg={themeImg[theme]}
                  handleClose={handleClose}
                />
              )}
              {+theme === ThemeNo.ThemeThree && (
                <NavbarStyleThree imageUpload={imageUpload} />
              )}
              {+theme === ThemeNo.ThemeTwo && (
                <NavbarStyleTwo imageUpload={imageUpload} />
              )}
              {+theme === ThemeNo.ThemeOne && (
                <NavbarStyleOne imageUpload={imageUpload} />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SettingHeadersPopup;
