import React, { useContext } from "react";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { THEME_UPDATE_MESSAGE, fontWeight } from "../../../../constant";
import ServiceCounterContext from "../../../context/ServiceCounterContext/ServiceCounterContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { Switch } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { UploadCloud } from "react-feather";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { IconButton } from "@material-ui/core";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";
import { putUpdateServiceCounter } from "../apis/ServiceCounterApi/ServiceCounterApi";
import ServiceCounterTheme1 from "../ScreenShorts/ServiceCounter/ServiceCounterTheme1.png";
import ServiceCounterTheme3 from "../ScreenShorts/ServiceCounter/ServiceCounterTheme3.png";
import ServiceCounterTheme2 from "../ScreenShorts/ServiceCounter/ServiceCounterTheme2.png";
import ThemeImageShow from "../../../common/ThemeImageShow";

const ServiceCounterStyleTwo = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    isBackgroundColor,
    setIsBackgroundColor,
    setImageIs,
    setTheme,
    theme,
    id,
    imgShow,
    setImgShow,
  } = useContext(ServiceCounterContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const { imageUpload, handleSubmitSetting, handleChangeSettings } = props;
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangCounterSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".counter1-section"]: {
          ...prev[".counter1-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangCounterSectionDiv = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".counter1-section .countdiv"]: {
          ...prev[".counter1-section .countdiv"],
          [name]: value,
        },
      };
    });
  };

  const handleChangCounterSectionNumber = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".counter1-section .countdiv .counternumb"]: {
          ...prev[".counter1-section .countdiv .counternumb"],
          [name]: value,
        },
      };
    });
  };

  const handleChangCounterSectionTitleAreaTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [".counter1-section .countdiv .counter-title"]: {
          ...prev[".counter1-section .countdiv .counter-title"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetHandler = () => {
    setSettingFormData({
      ".counter1-section": {
        padding: "50px 0",
        "background-color": { r: 29, g: 33, b: 51, a: 100 },
      },
      ".counter1-section.bg-image": {},
      ".counter1-section.bg-image::before": {
        // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
      },
      ".counter1-section .countdiv": {
        "padding-left": "70px",
        margin: "10px 0",
      },
      ".counter1-section .countdiv .counternumb": {
        "font-size": "40px",
        "font-weight": "600",
        "line-height": "1.1",
        color: { r: 255, g: 255, b: 255, a: 100 },
      },
      ".counter1-section .countdiv .counter-title": {
        "font-size": "18px",
        "font-weight": "500",
        "line-height": "1.2",
        color: { r: 255, g: 255, b: 255, a: 100 },
      },
      serviceCounterId: "",
      custom: "",
    });
    setBackImageToggle(false);
    setBoxSection(false);
    setIsBackgroundColor(true);
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".counter1-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".counter1-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  const handleTheme = async (e) => {
    setLoaderShow(true);
    const { value } = e.target;
    setTheme(value);
    try {
      let formData = {
        theme: value,
        serviceCounterId: id,
      };
      const { data } = await putUpdateServiceCounter(formData);
      if (data.status === 200) {
        await handleChangeSettings(id, true);
        TostMessage({
          message: { message: THEME_UPDATE_MESSAGE },
          type: "success",
        });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    } finally {
      setLoaderShow(false);
    }
  };
  const handleClose = () => {
    setImgShow(false);
  };
  const themeImg = {
    1: ServiceCounterTheme1,
    2: ServiceCounterTheme2,
    3: ServiceCounterTheme3,
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center position-relative">
              <div
                className="d-flex justify-content-between position-sticky"
                style={{ top: "87px", background: "white", zIndex: 999 }}
              >
                <h6>{isEdit ? "Edit" : "Add New"} 2</h6>
                <div className="d-flex gap-2">
                  <Button onClick={resetHandler}>Reset</Button>
                  <Button onClick={() => handleSubmitSetting(true)}>
                    Restore
                  </Button>
                  <Button
                    onClick={() => handleSubmitSetting(null, false)}
                    color="success"
                  >
                    Save & View
                  </Button>
                </div>
              </div>
              <Row>
                <Col md="12 mb-3">
                  <label htmlFor="">Theme</label>
                  <select
                    name="theme"
                    id="font-weight"
                    className="form-select"
                    value={theme}
                    onChange={(e) => handleTheme(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value={1}>Theme 1</option>
                    <option value={2}>Theme 2</option>
                    <option value={3}>Theme 3</option>
                  </select>
                </Col>
              </Row>
              {!imgShow && (
                <div>
                  <Button
                    onClick={() => setImgShow(true)}
                    color="primary"
                    className="mt-2"
                  >
                    Show Theme Image
                  </Button>
                </div>
              )}
              {imgShow && (
                <ThemeImageShow
                  themeImg={themeImg[theme]}
                  handleClose={handleClose}
                />
              )}
              <Row className="mt-4">
                <h4 className="mb-3">Make change in Main section</h4>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (default : 50)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[".counter1-section"]?.["padding"]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangCounterSection(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="4 mb-3">
                  <div className="d-flex align-items-center">
                    <Label htmlFor="validationCustom03">
                      {isBackgroundColor
                        ? "Add Background Color"
                        : "Remove Background Color"}
                    </Label>
                    <Switch
                      checked={isBackgroundColor}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e) => {
                        setIsBackgroundColor(e.target.checked);
                        setSettingFormData((prev) => {
                          if (e.target.checked) {
                            if (boxSection) {
                              return {
                                ...prev,
                                [".counter1-section"]: {
                                  "background-color": "#000000",
                                  padding:
                                    settingFormData?.[".counter1-section"]?.[
                                      "padding"
                                    ],
                                  width: settingFormData?.[
                                    ".counter1-section"
                                  ]?.["width"]?.replace("rem)", ""),
                                  margin: settingFormData?.[
                                    ".counter1-section"
                                  ]?.["margin"]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    ".counter1-section"
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [".counter1-section"]: {
                                  "background-color": "#000000",
                                  padding:
                                    settingFormData?.[".counter1-section"]?.[
                                      "padding"
                                    ],
                                },
                              };
                            }
                          } else {
                            if (boxSection) {
                              return {
                                ...prev,
                                [".counter1-section"]: {
                                  padding:
                                    settingFormData?.[".counter1-section"]?.[
                                      "padding"
                                    ],
                                  width: settingFormData?.[
                                    ".counter1-section"
                                  ]?.["width"]?.replace("rem)", ""),
                                  margin: settingFormData?.[
                                    ".counter1-section"
                                  ]?.["margin"]
                                    ?.split(" ")[0]
                                    ?.replace(/px/g, ""),
                                  "border-radius": settingFormData?.[
                                    ".counter1-section"
                                  ]?.["border-radius"]?.replace(/rem/g, ""),
                                },
                              };
                            } else {
                              return {
                                ...prev,
                                [".counter1-section"]: {
                                  padding:
                                    settingFormData?.[".counter1-section"]?.[
                                      "padding"
                                    ],
                                },
                              };
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  {isBackgroundColor && (
                    <CommonColor
                      color={
                        settingFormData?.[".counter1-section"]?.[
                          "background-color"
                        ]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".counter1-section"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  )}
                </Col>
                <p>{"Box Section : convert your section to Box section"}</p>
                <div>
                  <Switch
                    checked={boxSection}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBoxSection(e.target.checked);
                      setSettingFormData((prev) => {
                        return {
                          ...prev,
                          ".counter1-section": {
                            padding: settingFormData?.[".counter1-section"]?.[
                              "padding"
                            ]
                              ?.split(" ")[0]
                              ?.replace(/px/g, ""),
                            "background-color":
                              settingFormData?.[".counter1-section"]?.[
                                "background-color"
                              ],
                            width: e.target.checked ? "10" : "",
                            margin: e.target.checked ? "0" : "",
                            "border-radius": e.target.checked ? "3" : "",
                          },
                        };
                      });
                    }}
                  />
                </div>
                {boxSection && (
                  <>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Width (Default : 10)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="width"
                        type="number"
                        placeholder="width"
                        value={settingFormData?.[".counter1-section"]?.[
                          "width"
                        ]?.replace("rem)", "")}
                        onChange={(e) => handleChangCounterSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Margin (Default : 0)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="margin"
                        type="number"
                        placeholder="margin"
                        value={settingFormData?.[".counter1-section"]?.[
                          "margin"
                        ]
                          ?.split(" ")[0]
                          ?.replace(/px/g, "")}
                        onChange={(e) => handleChangCounterSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                    <Col md="4 mb-3">
                      <Label htmlFor="validationCustom03">
                        {"Border Radius (Default : 3)"}
                      </Label>
                      <Input
                        className="form-control"
                        name="border-radius"
                        type="number"
                        placeholder="Border Radius"
                        value={settingFormData?.[".counter1-section"]?.[
                          "border-radius"
                        ]?.replace(/rem/g, "")}
                        onChange={(e) => handleChangCounterSection(e)}
                        style={{ borderRadius: "0", color: "black" }}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <hr />
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <h4 className="mb-3">Add Background Image in section</h4>

                  <p>do you want to upload Background image</p>
                  <Switch
                    checked={backImageToggle}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(e) => {
                      setBackImageToggle(e.target.checked);
                      setSettingFormData((prev) => {
                        if (e.target.checked) {
                          return {
                            ...prev,
                            image: "",
                            ".counter1-section.bg-image": {},
                            ".counter1-section.bg-image::before": {
                              "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                            },
                          };
                        } else {
                          return {
                            ...prev,
                            image: "",
                            ".counter1-section.bg-image": {},
                            ".counter1-section.bg-image::before": {},
                          };
                        }
                      });
                    }}
                  />
                  {backImageToggle && (
                    <>
                      {" "}
                      <Col md="6 mb-3">
                        <Dividers divide="IMAGE" />
                        <p className="mb-4">
                          File type required JPG, PNG (1905px X 485px)
                        </p>
                      </Col>
                      <Row>
                        <Col md="4 mb-3">
                          <div
                            className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            {!settingFormData?.[".counter1-section.bg-image"]?.[
                              "background-image"
                            ] && (
                              <span
                                onClick={() => {
                                  OpenModalCommonFunc(
                                    Cases.PRODUCT_UPLOAD,
                                    setAllPopupState
                                  );
                                  setImageIs("serImage2");
                                }}
                              >
                                <UploadCloud />
                              </span>
                            )}

                            {settingFormData?.[".counter1-section.bg-image"]?.[
                              "background-image"
                            ] && (
                              <img
                                src={
                                  settingFormData?.[
                                    ".counter1-section.bg-image"
                                  ]?.["background-image"] || ""
                                }
                                alt=""
                                style={{
                                  width: "85%",
                                  height: "90px",
                                  objectFit: "contain",
                                  position: "absolute",
                                }}
                                onClick={() => deleteImagePermanent()}
                              />
                            )}
                            {settingFormData?.[".counter1-section.bg-image"]?.[
                              "background-image"
                            ] && (
                              <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: "-22px",
                                  left: "1px",
                                }}
                                onClick={() => deleteImagePermanent()}
                              >
                                <Close
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                  }}
                                />
                              </IconButton>
                            )}
                          </div>
                        </Col>
                        <Col md="6 mb-3">
                          <h4 className="mb-3">
                            Add Background color in section
                          </h4>
                          <Col md="6 mb-3">
                            <CommonColor
                              color={
                                settingFormData?.[
                                  ".counter1-section.bg-image::before"
                                ]?.["background-color"]
                              }
                              setSettingFormData={setSettingFormData}
                              className={".counter1-section.bg-image::before"}
                              classProperty={"background-color"}
                              label={"Select Background Color"}
                            />
                          </Col>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">Make space in Div</h4>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"padding-left (Default : 70)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="padding-left"
                    type="number"
                    placeholder="padding-left"
                    value={settingFormData?.[".counter1-section .countdiv"]?.[
                      "padding-left"
                    ]?.replace(/px/g, "")}
                    onChange={(e) => handleChangCounterSectionDiv(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin (Default : 10)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="margin"
                    type="number"
                    placeholder="Margin"
                    value={settingFormData?.[".counter1-section .countdiv"]?.[
                      "margin"
                    ]
                      ?.split(" ")[0]
                      ?.replace(/px/g, "")}
                    onChange={(e) => handleChangCounterSectionDiv(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">Make change in section number</h4>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 40)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".counter1-section .countdiv .counternumb"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangCounterSectionNumber(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Line Height (Default : 1.1)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="line-height"
                    type="number"
                    placeholder="Line Height"
                    value={settingFormData?.[
                      ".counter1-section .countdiv .counternumb"
                    ]?.["line-height"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangCounterSectionNumber(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 600)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".counter1-section .countdiv .counternumb"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangCounterSectionNumber(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="3 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".counter1-section .countdiv .counternumb"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".counter1-section .countdiv .counternumb"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-4">
                <h4 className="mb-3">Make change in section Title</h4>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Size (Default : 18)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="font-size"
                    type="number"
                    placeholder="Font Size"
                    value={settingFormData?.[
                      ".counter1-section .countdiv .counter-title"
                    ]?.["font-size"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangCounterSectionTitleAreaTitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Line Height (Default : 1.2)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="line-height"
                    type="number"
                    placeholder="Line Height"
                    value={settingFormData?.[
                      ".counter1-section .countdiv .counter-title"
                    ]?.["line-height"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangCounterSectionTitleAreaTitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
                <Col md="3 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Font Weight (Default : 500)"}
                  </Label>
                  <select
                    name="font-weight"
                    id="font-weight"
                    className="form-select"
                    value={
                      settingFormData?.[
                        ".counter1-section .countdiv .counter-title"
                      ]?.["font-weight"]
                    }
                    onChange={(e) => handleChangCounterSectionTitleAreaTitle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {fontWeight.map((elem) => {
                      return (
                        <option value={elem["font-weight"]}>
                          {elem["font-weight"]}
                        </option>
                      );
                    })}
                  </select>
                </Col>
                <Col md="3 mb-3">
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".counter1-section .countdiv .counter-title"
                      ]?.["color"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".counter1-section .countdiv .counter-title"}
                    classProperty={"color"}
                    label={"Select Color"}
                  />
                </Col>
              </Row>
              <hr />
              <CustomStyle
                handleChange={handleChangeCustomStyle}
                value={settingFormData?.custom}
              />
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={settingFormData?.image}
          setImage={setSettingFormData}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={3.93 / 1}
        />
      </Container>
    </>
  );
};

export default ServiceCounterStyleTwo;
