import { convertRGBA, rgbColorCreate } from "../../../../../utils/helper";

export const navBarStyleOne = {
  ".custom-nav": {
    padding: "0",
    "background-color": { r: 255, g: 255, b: 255, a: 1 },
  },
  ".custom-nav .navbar-brand img": {
    "max-width": "200px",
  },
  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".custom-nav .navbar-nav .nav-link": {
    "font-size": "16px",
    "font-weight": "600",
    padding: "13px 12px",
    p1: "13px",
    p2: "12px",
    margin: "17px 3px",
    m1: "17px",
    m2: "3px",
    "border-radius": "5px",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  ".custom-nav .navbar-nav .dropdown-menu": {
    "border-top": "3px solid #ff497c",
    borderPx: "3px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".custom-nav .navbar-nav .dropdown-menu .dropdown-item": {
    "font-size": "14px",
    "font-weight": "500",
    "line-height": "2",
    "border-bottom": "1px solid #ddd",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 137, g: 137, b: 137, a: 1 },
    "background-color": "transparent",
  },
  ".custom-nav .navbar-nav .dropdown-mega .subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item": {
    "font-size": "14px",
    "font-weight": "500",
    "line-height": "1.3",
  },
  ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".custom-nav .left-menu-icons .links": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    padding: "3px",
  },
  ".custom-nav .login-user .dropdown-toggle": {
    "border-radius": "15px",
    border: "1px solid #ffffff",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".custom-nav .navbar-toggler": {
    fill: { r: 0, g: 0, b: 0, a: 100 },
    "border-color": { r: 0, g: 0, b: 0, a: 100 },
  },
  ".custom-nav .navbar-collapse": {
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  isCenterNavs: false,
};
export const navBarStyleTwo = {
  isCenterNavs: false,
  "header.style-one": {
    padding: "20px 30px",
    p1: "20px",
    p2: "30px",
    "background-color": { r: 247, g: 245, b: 245, a: 100 },
  },
  "header.style-one::after": {
    width: "615px",
    background: { r: 31, g: 39, b: 50, a: 100 },
  },
  "header.style-one .header-wrapper .navbar-brand": {
    "margin-right": "55px",
    "line-height": "0px",
  },
  "header.style-one .header-wrapper .navbar-brand img": {
    "max-width": "200px",
  },
  "header.style-one .header-wrapper .navbar-nav": {
    background: { r: 255, g: 255, b: 255, a: 100 },
    "box-shadow": "0px 10px 40px rgba(0, 0, 0, 0.1)",
    bs1: "0",
    bs2: "10px",
    bs3: "40px",
    shadowColor: { r: 0, g: 0, b: 0, a: 0.1 },
    "border-radius": "50px",
    "padding-left": "40px",
    gap: "24px",
  },
  "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link": {
    padding: "28px 0",
    "font-size": "14px",
    color: { r: 0, g: 0, b: 0, a: 100 },
  },
  "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up": {
    padding: "0 58px 0 67px",
    p1: "58px",
    p2: "67px",
    background: { r: 31, g: 39, b: 50, a: 100 },
    "border-radius": "0 50px 50px 0",
    br1: "50px",
    br2: "50px",
  },
  "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link": {
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover": {
    color: { r: 255, g: 73, b: 124, a: 100 },
  },
  "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu":
    {
      background: { r: 255, g: 255, b: 255, a: 100 },
      //   padding: "6px 20px",
      //   p1: "6px",
      //   p2: "20px",
    },
  "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu li .nav-link":
    {
      padding: "8px 0",
    },
  //   "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu": {
  //     padding: "20px",
  //   },
  //   "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a": {
  //     padding: "6px 0",
  //     "font-size": "14px",
  //   },
  //   "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5": {
  //     "font-size": "18px",
  //     "padding-bottom": "10px",
  //   },
  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler":
    {
      width: "50px",
      height: "50px",
      color: { r: 34, g: 34, b: 34, a: 100 },
      "font-size": "20px",
      "line-height": "50px",
      "border-radius": "100%",
      "background-color": { r: 255, g: 255, b: 255, a: 100 },
      "box-shadow": "0px 15px 40px 10px rgba(0, 0, 0, 0.07)",
      bs1: "0",
      bs2: "15px",
      bs3: "40px",
      bs4: "10px",
      shadowColor: { r: 0, g: 0, b: 0, a: 0.07 },
    },
  "header.style-one .header-wrapper .left-menu-icons .navbar-toggler svg": {
    fill: { r: 0, g: 0, b: 0, a: 100 },
  },
  "header.style-one .header-wrapper .left-menu-icons .navbar-toggler": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
  "header.style-one .header-wrapper .navbar-nav .media": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
  },
};

export const navBarStyleThree = {
  ".custom-nav": {
    padding: "15px",
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
  ".custom-nav .navbar-brand img": {
    "max-width": "200px",
  },
  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
    {
      color: { r: 255, g: 73, b: 124, a: 100 },
    },
  ".custom-nav .navbar-nav .nav-link": {
    "font-size": "14px",
    "font-weight": "500",
    padding: "13px 10px",
    p1: "13px",
    p2: "10px",
    margin: "0 10px",
    m1: "0",
    m2: "10px",
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".custom-nav .navbar-nav .dropdown-menu": {
    "border-top": "3px solid #ff497c",
    borderPx: "3px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
  },
  ".custom-nav .navbar-nav .dropdown-menu .dropdown-item": {
    "font-size": "14px",
    "font-weight": "500",
    "line-height": "2",
    "border-bottom": "1px solid #ddd",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 221, g: 221, b: 221, a: 1 },
    "background-color": "transparent",
  },
  ".custom-nav .navbar-nav .dropdown-mega .subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item": {
    "font-size": "14px",
    "font-weight": "500",
    "line-height": "1.3",
  },
  ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before": {
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".custom-nav .left-menu-icons .links": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    padding: "3px",
  },
  ".custom-nav .login-user .dropdown-toggle": {
    "border-radius": "15px",
    border: "1px solid #ffffff",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".custom-nav .navbar-collapse": {
    "background-color": { r: 31, g: 39, b: 50, a: 100 },
  },
};

// export const navBarStyleTwo = {
//   "header.style-one": {
//     padding: "20px 30px",
//     "background-color": "#F7F5F5",
//     "z-index": "10",
//   },
//   "header.style-one::after": {
//     width: "615px",
//     background: "#1f2732",
//   },
//   "header.style-one .header-wrapper": {
//     "z-index": 1,
//   },
//   "header.style-one .header-wrapper .navbar-brand": {
//     "margin-right": "55px",
//     "line-height": 0,
//   },
//   "header.style-one .header-wrapper .navbar-brand img": {
//     "max-width": "200px",
//   },
//   "header.style-one .header-wrapper .navbar-collapse": {
//     "flex-basis": "auto",
//     "flex-grow": 0,
//   },
//   "header.style-one .header-wrapper .navbar-nav": {
//     background: "#fff",
//     "box-shadow": "0px 10px 40px rgba(0, 0, 0, 0.1)",
//     "border-radius": "50px",
//     "padding-left": "40px",
//     gap: "24px",
//   },
//   "header.style-one .header-wrapper .navbar-nav .nav-item": {
//     position: "relative",
//   },
//   "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link": {
//     padding: "28px 0",
//     "font-size": "14px",
//     color: "#000000",
//   },
//   "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up": {
//     padding: "0 58px 0 67px",
//     background: "#1f2732",
//     "border-radius": "0 50px 50px 0",
//   },
//   "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link": {
//     color: "#ffffff",
//   },
//   "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover": {
//     color: "#ff497c",
//   },
//   "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu":
//     {
//       background: "#fff",
//       padding: "6px 20px",
//     },
//   "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu li .nav-link":
//     {
//       padding: "8px 0",
//     },
//   "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu": {
//     padding: "20px",
//   },
//   "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a": {
//     "white-space": "nowrap",
//     padding: "6px 0",
//     "font-size": "14px",
//   },
//   "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5": {
//     "font-size": "18px",
//     "padding-bottom": "10px",
//   },
//   "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler":
//     {
//       width: "50px",
//       height: "50px",
//       color: "#222",
//       "font-size": "20px",
//       "text-align": "center",
//       "line-height": "50px",
//       "border-radius": "100%",
//       "background-color": "#fff",
//       "box-shadow": "0px 15px 40px 10px rgba(0, 0, 0, 0.07)",
//     },
// };

export const navBarStyleSetFunction = (settingFormData) => {
  return {
    ".custom-nav": {
      ...settingFormData?.[".custom-nav"],
      padding: "0",
      "background-color": settingFormData?.[".custom-nav"]?.["background-color"]
        ? rgbColorCreate({
            rgbString: settingFormData?.[".custom-nav"]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .navbar-brand img": {
      "max-width": `${settingFormData?.[`.custom-nav .navbar-brand img`]?.[
        "max-width"
      ]?.replace("px", "")}px`,
    },
    ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
      {
        ...settingFormData?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ],
        color: settingFormData?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[
                  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                ]?.["color"],
            })
          : "",
        "background-color": settingFormData?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[
                  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                ]?.["background-color"],
            })
          : "",
      },
    ".custom-nav .navbar-nav .nav-link": {
      ...settingFormData?.[".custom-nav .navbar-nav .nav-link"],
      "font-size": `${settingFormData?.[`.custom-nav .navbar-nav .nav-link`]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData?.[`.custom-nav .navbar-nav .nav-link`]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        `.custom-nav .navbar-nav .nav-link`
      ]?.["p2"]?.replace("px", "")}`,
      margin: `${settingFormData?.[`.custom-nav .navbar-nav .nav-link`]?.[
        "m1"
      ]?.replace("px", "")}px ${settingFormData?.[
        `.custom-nav .navbar-nav .nav-link`
      ]?.["m2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        `.custom-nav .navbar-nav .nav-link`
      ]?.["border-radius"]?.replace("px", "")}px`,
      color: settingFormData[`.custom-nav .navbar-nav .nav-link`]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[`.custom-nav .navbar-nav .nav-link`]?.["color"],
          })
        : "",
    },
    ".custom-nav .navbar-nav .dropdown-menu": {
      ...settingFormData?.[".custom-nav .navbar-nav .dropdown-menu"],
      "border-top": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-menu`
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[`.custom-nav .navbar-nav .dropdown-menu`]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[`.custom-nav .navbar-nav .dropdown-menu`]?.[
            "borderColor"
          ],
      })}`,
    },
    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item": {
      ...settingFormData?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
      ],
      "font-size": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-menu .dropdown-item`
      ]?.["font-size"]?.replace("px", "")}px`,
      "border-bottom": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-menu .dropdown-item`
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[
          `.custom-nav .navbar-nav .dropdown-menu .dropdown-item`
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[
            `.custom-nav .navbar-nav .dropdown-menu .dropdown-item`
          ]?.["borderColor"],
      })}`,
    },
    ".custom-nav .navbar-nav .dropdown-mega .subtitle": {
      ...settingFormData?.[".custom-nav .navbar-nav .dropdown-mega .subtitle"],
      "font-size": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-mega .subtitle`
      ]?.["font-size"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-mega .subtitle`
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item": {
      ...settingFormData?.[
        ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
      ],
      "font-size": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item`
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before": {
      ...settingFormData?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
      ],
      "background-color": settingFormData[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
              ]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .left-menu-icons .links": {
      ...settingFormData?.[".custom-nav .left-menu-icons .links"],
      padding: `${settingFormData?.[`.custom-nav .left-menu-icons .links`]?.[
        "padding"
      ]?.replace("px", "")}px`,
      color: settingFormData[".custom-nav .left-menu-icons .links"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".custom-nav .left-menu-icons .links"]?.["color"],
          })
        : "",
    },
    ".custom-nav .login-user .dropdown-toggle": {
      ...settingFormData?.[".custom-nav .login-user .dropdown-toggle"],
      "border-radius": `${settingFormData?.[
        `.custom-nav .login-user .dropdown-toggle`
      ]?.["border-radius"]?.replace("px", "")}px`,
      border: `${settingFormData[`.custom-nav .login-user .dropdown-toggle`]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[`.custom-nav .login-user .dropdown-toggle`]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[`.custom-nav .login-user .dropdown-toggle`]?.[
            "borderColor"
          ],
      })}`,
      "background-color": settingFormData[
        ".custom-nav .login-user .dropdown-toggle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".custom-nav .login-user .dropdown-toggle"]?.[
                "background-color"
              ],
          })
        : "",
      color: settingFormData[".custom-nav .login-user .dropdown-toggle"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".custom-nav .login-user .dropdown-toggle"]?.[
                "color"
              ],
          })
        : "",
    },
    ".custom-nav .navbar-toggler": {
      fill: settingFormData[".custom-nav .navbar-toggler"]?.["fill"]
        ? rgbColorCreate({
            rgbString: settingFormData[".custom-nav .navbar-toggler"]?.["fill"],
          })
        : "",
      "border-color": settingFormData[".custom-nav .navbar-toggler"]?.[
        "border-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".custom-nav .navbar-toggler"]?.["border-color"],
          })
        : "",
    },
    "@media screen and (max-width: 991px)": {
      ".custom-nav .navbar-collapse": {
        "background-color": settingFormData[".custom-nav .navbar-collapse"]?.[
          "background-color"
        ]
          ? rgbColorCreate({
              rgbString:
                settingFormData[".custom-nav .navbar-collapse"]?.[
                  "background-color"
                ],
            })
          : "",
      },
    },
  };
};
export const navBarTwoStyleSetFunction = (settingFormData) => {
  return {
    "header.style-one": {
      ...settingFormData?.["header.style-one"],
      padding: `${settingFormData["header.style-one"]?.["p1"]?.replace(
        "px",
        ""
      )}px ${settingFormData["header.style-one"]?.["p2"]?.replace("px", "")}px`,
      "background-color": settingFormData?.["header.style-one"]?.[
        "background-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.["header.style-one"]?.["background-color"],
          })
        : "",
    },
    "header.style-one::after": {
      ...settingFormData?.["header.style-one::after"],
      width: `${settingFormData?.["header.style-one::after"]?.[
        "width"
      ]?.replace("px", "")}px`,
      background: settingFormData?.["header.style-one::after"]?.["background"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.["header.style-one::after"]?.["background"],
          })
        : "",
    },
    "header.style-one .header-wrapper .navbar-brand": {
      ...settingFormData?.["header.style-one .header-wrapper .navbar-brand"],
      "margin-right": `${settingFormData?.[
        "header.style-one .header-wrapper .navbar-brand"
      ]?.["margin-right"]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[
        "header.style-one .header-wrapper .navbar-brand"
      ]?.["line-height"]?.replace("px", "")}px`,
    },
    "header.style-one .header-wrapper .navbar-brand img": {
      ...settingFormData?.[
        "header.style-one .header-wrapper .navbar-brand img"
      ],
      "max-width": `${settingFormData?.[
        "header.style-one .header-wrapper .navbar-brand img"
      ]?.["max-width"]?.replace("px", "")}px`,
    },
    "header.style-one .header-wrapper .navbar-nav": {
      ...settingFormData?.["header.style-one .header-wrapper .navbar-nav"],
      background: settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav"
      ]?.["background"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                "header.style-one .header-wrapper .navbar-nav"
              ]?.["background"],
          })
        : "",
      "box-shadow": `${settingFormData[
        "header.style-one .header-wrapper .navbar-nav"
      ]["bs1"]?.replace("px", "")}px ${settingFormData[
        "header.style-one .header-wrapper .navbar-nav"
      ]["bs2"]?.replace("px", "")}px ${settingFormData[
        "header.style-one .header-wrapper .navbar-nav"
      ]["bs3"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData["header.style-one .header-wrapper .navbar-nav"]?.[
            "shadowColor"
          ],
      })}`,
      "border-radius": `${settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "padding-left": `${settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav"
      ]?.["padding-left"]?.replace("px", "")}px`,
      gap: `${settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav"
      ]?.["gap"]?.replace("px", "")}px`,
    },
    "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link": {
      ...settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
      ],
      color: settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
              ]?.["color"],
          })
        : "",
      padding: `${settingFormData[
        "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
      ]["padding"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      "font-size": `${settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up": {
      ...settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ],
      background: settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]?.["background"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
              ]?.["background"],
          })
        : "",
      padding: `0 ${settingFormData[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]["p1"]?.replace("px", "")}px 0 ${settingFormData[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]["p2"]?.replace("px", "")}px`,
      "border-radius": `0 ${settingFormData[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]["br1"]?.replace("px", "")}px ${settingFormData[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]["br2"]?.replace("px", "")}px 0`,
    },
    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link":
      {
        ...settingFormData?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link"
        ],
        color: settingFormData?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link"
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link"
                ]?.["color"],
            })
          : "",
      },
    "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover": {
      ...settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover"
      ],
      color: settingFormData?.[
        "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover"
              ]?.["color"],
          })
        : "",
    },
    "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu":
      {
        ...settingFormData?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
        ],
        background: settingFormData?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
        ]?.["background"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
                ]?.["background"],
            })
          : "",
        // padding: `0 ${settingFormData[
        //   "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
        // ]["p1"]?.replace("px", "")}px 0 ${settingFormData[
        //   "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
        // ]["p2"]?.replace("px", "")}px`,
      },
    "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu li .nav-link":
      {
        padding: `${settingFormData[
          "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu li .nav-link"
        ]["padding"]
          ?.split(" ")[0]
          ?.replace("px", "")}px 0`,
      },
    // "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu": {
    //   padding: `${settingFormData?.[
    //     "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
    //   ]?.["padding"]?.replace("px", "")}px`,
    // },
    // "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a": {
    //   padding: `${settingFormData[
    //     "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a"
    //   ]["padding"]
    //     ?.split(" ")[0]
    //     ?.replace("px", "")}px 0`,
    //   "font-size": `${settingFormData?.[
    //     "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a"
    //   ]?.["font-size"]?.replace("px", "")}px`,
    // },
    // "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5": {
    //   "font-size": `${settingFormData?.[
    //     "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5"
    //   ]?.["font-size"]?.replace("px", "")}px`,
    //   "padding-bottom": `${settingFormData?.[
    //     "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5"
    //   ]?.["padding-bottom"]?.replace("px", "")}px`,
    // },
    "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler":
      {
        ...settingFormData?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ],
        color: settingFormData?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["color"],
            })
          : "",
        width: `${settingFormData?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["width"]?.replace("px", "")}px`,
        height: `${settingFormData?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["height"]?.replace("px", "")}px`,
        "font-size": `${settingFormData?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["font-size"]?.replace("px", "")}px`,
        "line-height": `${settingFormData?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["line-height"]?.replace("px", "")}px`,
        "border-radius": `${settingFormData?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["border-radius"]?.replace("%", "")}%`,
        "background-color": settingFormData?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["background-color"],
            })
          : "",
        "box-shadow": `${settingFormData[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]["bs1"]?.replace("px", "")}px ${settingFormData[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]["bs2"]?.replace("px", "")}px ${settingFormData[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]["bs3"]?.replace("px", "")}px ${settingFormData[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]["bs4"]?.replace("px", "")}px ${rgbColorCreate({
          rgbString:
            settingFormData[
              "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
            ]?.["shadowColor"],
        })}`,
      },
    "header.style-one .header-wrapper .left-menu-icons .navbar-toggler svg": {
      fill: settingFormData?.[
        "header.style-one .header-wrapper .left-menu-icons .navbar-toggler svg"
      ]?.["fill"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                "header.style-one .header-wrapper .left-menu-icons .navbar-toggler svg"
              ]?.["fill"],
          })
        : "",
    },
    "header.style-one .header-wrapper .left-menu-icons .navbar-toggler": {
      "background-color": settingFormData?.[
        "header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                "header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
              ]?.["background-color"],
          })
        : "",
    },
    "@media screen and (max-width: 1199px)": {
      "header.style-one .header-wrapper .navbar-nav": {
        background: settingFormData[
          "header.style-one .header-wrapper .navbar-nav .media"
        ]?.["background"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  "header.style-one .header-wrapper .navbar-nav .media"
                ]?.["background"],
            })
          : "",
      },
    },
  };
};
export const navBarThreeStyleSetFunction = (settingFormData) => {
  return {
    ".custom-nav": {
      ...settingFormData?.[".custom-nav"],
      padding: `${settingFormData?.[".custom-nav"]?.["padding"]?.replace(
        "px",
        ""
      )}px`,
      "background-color": settingFormData?.[".custom-nav"]?.["background-color"]
        ? rgbColorCreate({
            rgbString: settingFormData?.[".custom-nav"]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .navbar-brand img": {
      "max-width": `${settingFormData?.[`.custom-nav .navbar-brand img`]?.[
        "max-width"
      ]?.replace("px", "")}px`,
    },
    ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
      {
        ...settingFormData?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ],
        color: settingFormData?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[
                  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                ]?.["color"],
            })
          : "",
        "background-color": settingFormData?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData?.[
                  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                ]?.["background-color"],
            })
          : "",
      },
    ".custom-nav .navbar-nav .nav-link": {
      ...settingFormData?.[".custom-nav .navbar-nav .nav-link"],
      "font-size": `${settingFormData?.[`.custom-nav .navbar-nav .nav-link`]?.[
        "font-size"
      ]?.replace("px", "")}px`,
      padding: `${settingFormData?.[`.custom-nav .navbar-nav .nav-link`]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        `.custom-nav .navbar-nav .nav-link`
      ]?.["p2"]?.replace("px", "")}`,
      margin: `${settingFormData?.[`.custom-nav .navbar-nav .nav-link`]?.[
        "m1"
      ]?.replace("px", "")}px ${settingFormData?.[
        `.custom-nav .navbar-nav .nav-link`
      ]?.["m2"]?.replace("px", "")}px`,
      color: settingFormData[`.custom-nav .navbar-nav .nav-link`]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[`.custom-nav .navbar-nav .nav-link`]?.["color"],
          })
        : "",
    },
    ".custom-nav .navbar-nav .dropdown-menu": {
      ...settingFormData?.[".custom-nav .navbar-nav .dropdown-menu"],
      "border-top": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-menu`
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[`.custom-nav .navbar-nav .dropdown-menu`]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[`.custom-nav .navbar-nav .dropdown-menu`]?.[
            "borderColor"
          ],
      })}`,
    },
    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item": {
      ...settingFormData?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
      ],
      "font-size": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-menu .dropdown-item`
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${
        settingFormData?.[
          `.custom-nav .navbar-nav .dropdown-menu .dropdown-item`
        ]?.["font-weight"]
      }`,
      "border-bottom": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-menu .dropdown-item`
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[
          `.custom-nav .navbar-nav .dropdown-menu .dropdown-item`
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[
            `.custom-nav .navbar-nav .dropdown-menu .dropdown-item`
          ]?.["borderColor"],
      })}`,
    },
    ".custom-nav .navbar-nav .dropdown-mega .subtitle": {
      ...settingFormData?.[".custom-nav .navbar-nav .dropdown-mega .subtitle"],
      "font-size": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-mega .subtitle`
      ]?.["font-size"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-mega .subtitle`
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item": {
      ...settingFormData?.[
        ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
      ],
      "font-size": `${settingFormData?.[
        `.custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item`
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before": {
      ...settingFormData?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
      ],
      "background-color": settingFormData[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
              ]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .left-menu-icons .links": {
      ...settingFormData?.[".custom-nav .left-menu-icons .links"],
      padding: `${settingFormData?.[`.custom-nav .left-menu-icons .links`]?.[
        "padding"
      ]?.replace("px", "")}px`,
      color: settingFormData[".custom-nav .left-menu-icons .links"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".custom-nav .left-menu-icons .links"]?.["color"],
          })
        : "",
    },
    ".custom-nav .login-user .dropdown-toggle": {
      ...settingFormData?.[".custom-nav .login-user .dropdown-toggle"],
      "border-radius": `${settingFormData?.[
        `.custom-nav .login-user .dropdown-toggle`
      ]?.["border-radius"]?.replace("px", "")}px`,
      border: `${settingFormData[`.custom-nav .login-user .dropdown-toggle`]?.[
        "borderPx"
      ]?.replace("px", "")}px ${
        settingFormData?.[`.custom-nav .login-user .dropdown-toggle`]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData[`.custom-nav .login-user .dropdown-toggle`]?.[
            "borderColor"
          ],
      })}`,
      "background-color": settingFormData[
        ".custom-nav .login-user .dropdown-toggle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".custom-nav .login-user .dropdown-toggle"]?.[
                "background-color"
              ],
          })
        : "",
      color: settingFormData[".custom-nav .login-user .dropdown-toggle"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".custom-nav .login-user .dropdown-toggle"]?.[
                "color"
              ],
          })
        : "",
    },
    ".custom-nav .navbar-toggler": {
      fill: settingFormData[".custom-nav .navbar-toggler"]?.["fill"]
        ? rgbColorCreate({
            rgbString: settingFormData[".custom-nav .navbar-toggler"]?.["fill"],
          })
        : "",
      "border-color": settingFormData[".custom-nav .navbar-toggler"]?.[
        "border-color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData[".custom-nav .navbar-toggler"]?.["border-color"],
          })
        : "",
    },
    "@media screen and (max-width: 991px)": {
      ".custom-nav .navbar-collapse": {
        "background-color": settingFormData[".custom-nav .navbar-collapse"]?.[
          "background-color"
        ]
          ? rgbColorCreate({
              rgbString:
                settingFormData[".custom-nav .navbar-collapse"]?.[
                  "background-color"
                ],
            })
          : "",
      },
    },
  };
};

export const navbarSetData = (payload) => {
  return {
    ".custom-nav": {
      padding: payload?.[".custom-nav"]?.["padding"],
      "background-color": payload?.[".custom-nav"]?.["background-color"]
        ? convertRGBA({
            rgbString: payload?.[".custom-nav"]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .navbar-brand img": {
      "max-width": payload?.[".custom-nav .navbar-brand img"]?.["max-width"],
    },
    ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
      {
        color: payload?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ]?.["color"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                ]?.["color"],
            })
          : "",
        "background-color": payload?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ]?.["background-color"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                ]?.["background-color"],
            })
          : "",
      },
    ".custom-nav .navbar-nav .nav-link": {
      "font-size":
        payload?.[".custom-nav .navbar-nav .nav-link"]?.["font-size"],
      "font-weight":
        payload?.[".custom-nav .navbar-nav .nav-link"]?.["font-weight"],
      padding: payload?.[".custom-nav .navbar-nav .nav-link"]?.["padding"],
      p1: payload?.[".custom-nav .navbar-nav .nav-link"]?.["p1"],
      p2: payload?.[".custom-nav .navbar-nav .nav-link"]?.["p2"],
      margin: "17px 3px",
      m1: payload?.[".custom-nav .navbar-nav .nav-link"]?.["m1"],
      m2: payload?.[".custom-nav .navbar-nav .nav-link"]?.["m2"],
      // padding: "35px 0",
      // p1: "35px",
      // margin: "0 15px",
      // m2: "15px",
      color: payload?.[".custom-nav .navbar-nav .nav-link"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .navbar-nav .nav-link"]?.["color"],
          })
        : "",
      "border-radius":
        payload?.[".custom-nav .navbar-nav .nav-link"]?.["border-radius"],
    },
    ".custom-nav .navbar-nav .dropdown-menu": {
      "border-top":
        payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.["border-top"],
      borderPx:
        payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.["borderPx"],
      borderThik:
        payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.["borderThik"],
      borderColor: payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.[
                "borderColor"
              ],
          })
        : "",
      "background-color": payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item": {
      "font-size":
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "font-size"
        ],
      "font-weight":
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "font-weight"
        ],
      "line-height":
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "line-height"
        ],
      "border-bottom":
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "border-bottom"
        ],
      borderPx:
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "borderPx"
        ],
      borderThik:
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "borderThik"
        ],
      borderColor: payload?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
              ]?.["borderColor"],
          })
        : "",
      "background-color": payload?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
              ]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .navbar-nav .dropdown-mega .subtitle": {
      "font-size":
        payload?.[".custom-nav .navbar-nav .dropdown-mega .subtitle"]?.[
          "font-size"
        ],
      "font-weight":
        payload?.[".custom-nav .navbar-nav .dropdown-mega .subtitle"]?.[
          "font-weight"
        ],
      "margin-bottom":
        payload?.[".custom-nav .navbar-nav .dropdown-mega .subtitle"]?.[
          "margin-bottom"
        ],
    },
    ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item": {
      "font-size":
        payload?.[
          ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
        ]?.["font-size"],
      "font-weight":
        payload?.[
          ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
        ]?.["font-weight"],
      "line-height":
        payload?.[
          ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
        ]?.["line-height"],
    },
    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before": {
      "background-color": payload?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
              ]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .left-menu-icons .links": {
      color: payload?.[".custom-nav .left-menu-icons .links"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .left-menu-icons .links"]?.["color"],
          })
        : "",
      padding: payload?.[".custom-nav .left-menu-icons .links"]?.["padding"],
    },
    ".custom-nav .login-user .dropdown-toggle": {
      "border-radius":
        payload?.[".custom-nav .login-user .dropdown-toggle"]?.[
          "border-radius"
        ],
      border: payload?.[".custom-nav .login-user .dropdown-toggle"]?.["border"],
      borderPx:
        payload?.[".custom-nav .login-user .dropdown-toggle"]?.["borderPx"],
      borderThik:
        payload?.[".custom-nav .login-user .dropdown-toggle"]?.["borderThik"],
      borderColor:
        payload?.[".custom-nav .login-user .dropdown-toggle"]?.["borderColor"],
      "background-color": payload?.[
        ".custom-nav .login-user .dropdown-toggle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .login-user .dropdown-toggle"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload?.[".custom-nav .login-user .dropdown-toggle"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .login-user .dropdown-toggle"]?.["color"],
          })
        : "",
    },
    ".custom-nav .navbar-toggler": {
      fill: payload?.[".custom-nav .navbar-toggler"]?.["fill"]
        ? convertRGBA({
            rgbString: payload?.[".custom-nav .navbar-toggler"]?.["fill"],
          })
        : "",
      "border-color": payload?.[".custom-nav .navbar-toggler"]?.["border-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .navbar-toggler"]?.["border-color"],
          })
        : "",
    },
    ".custom-nav .navbar-collapse": {
      "background-color": payload?.["@media screen and (max-width: 991px)"]?.[
        ".custom-nav .navbar-collapse"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.["@media screen and (max-width: 991px)"]?.[
                ".custom-nav .navbar-collapse"
              ]?.["background-color"],
          })
        : "",
    },
  };
};
export const navbarTwoSetData = (payload) => {
  return {
    "header.style-one": {
      padding: payload?.["header.style-one"]?.["padding"],
      p1: payload?.["header.style-one"]?.["p1"],
      p2: payload?.["header.style-one"]?.["p2"],
      "background-color": payload?.["header.style-one"]?.["background-color"]
        ? convertRGBA({
            rgbString: payload?.["header.style-one"]?.["background-color"],
          })
        : "",
    },
    "header.style-one::after": {
      width: payload?.["header.style-one::after"]?.["width"],
      background: payload?.["header.style-one::after"]?.["background"]
        ? convertRGBA({
            rgbString: payload?.["header.style-one::after"]?.["background"],
          })
        : "",
    },
    "header.style-one .header-wrapper .navbar-brand": {
      "margin-right":
        payload?.["header.style-one .header-wrapper .navbar-brand"]?.[
          "margin-right"
        ],
      "line-height":
        payload?.["header.style-one .header-wrapper .navbar-brand"]?.[
          "line-height"
        ],
    },
    "header.style-one .header-wrapper .navbar-nav": {
      background: payload?.["header.style-one .header-wrapper .navbar-nav"]?.[
        "background"
      ]
        ? convertRGBA({
            rgbString:
              payload?.["header.style-one .header-wrapper .navbar-nav"]?.[
                "background"
              ],
          })
        : "",
      "box-shadow":
        payload?.["header.style-one .header-wrapper .navbar-nav"]?.[
          "box-shadow"
        ],
      bs1: payload?.["header.style-one .header-wrapper .navbar-nav"]?.["bs1"],
      bs2: payload?.["header.style-one .header-wrapper .navbar-nav"]?.["bs2"],
      bs3: payload?.["header.style-one .header-wrapper .navbar-nav"]?.["bs3"],

      shadowColor: payload?.["header.style-one .header-wrapper .navbar-nav"]?.[
        "shadowColor"
      ]
        ? convertRGBA({
            rgbString:
              payload?.["header.style-one .header-wrapper .navbar-nav"]?.[
                "shadowColor"
              ],
          })
        : "",
      "border-radius":
        payload?.["header.style-one .header-wrapper .navbar-nav"]?.[
          "border-radius"
        ],
      "padding-left":
        payload?.["header.style-one .header-wrapper .navbar-nav"]?.[
          "padding-left"
        ],
      gap: payload?.["header.style-one .header-wrapper .navbar-nav"]?.["gap"],
    },
    "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link": {
      padding:
        payload?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
        ]?.["padding"],
      "font-size":
        payload?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
        ]?.["font-size"],
      color: payload?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                "header.style-one .header-wrapper .navbar-nav .nav-item .nav-link"
              ]?.["color"],
          })
        : "",
    },
    "header.style-one .header-wrapper .navbar-brand img": {
      "max-width":
        payload?.["header.style-one .header-wrapper .navbar-brand img"]?.[
          "max-width"
        ],
    },
    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up": {
      padding:
        payload?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
        ]?.["padding"],
      p1: payload?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]?.["p1"],
      p2: payload?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]?.["p2"],
      background: payload?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]?.["background"]
        ? convertRGBA({
            rgbString:
              payload?.[
                "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
              ]?.["background"],
          })
        : "",
      "border-radius":
        payload?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
        ]?.["border-radius"],
      br1: payload?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]?.["br1"],
      br2: payload?.[
        "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up"
      ]?.["br2"],
    },
    "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link":
      {
        color: payload?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link"
        ]?.["color"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item.sign-up .nav-link"
                ]?.["color"],
            })
          : "",
      },
    "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover": {
      color: payload?.[
        "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                "header.style-one .header-wrapper .navbar-nav li a.nav-link:hover"
              ]?.["color"],
          })
        : "",
    },
    "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu":
      {
        background: payload?.[
          "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
        ]?.["background"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
                ]?.["background"],
            })
          : "",
        // padding:
        //   payload?.[
        //     "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
        //   ]?.["padding"],
        // p1: payload?.[
        //   "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
        // ]?.["p1"],
        // p2: payload?.[
        //   "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu, header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
        // ]?.["p2"],
      },
    "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu li .nav-link":
      {
        padding:
          payload?.[
            "header.style-one .header-wrapper .navbar-nav .nav-item .sub-menu li .nav-link"
          ]?.["padding"],
      },
    // "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu": {
    //   padding:
    //     payload?.[
    //       "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu"
    //     ]?.["padding"],
    // },
    // "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a": {
    //   padding:
    //     payload?.[
    //       "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a"
    //     ]?.["padding"],
    //   "font-size":
    //     payload?.[
    //       "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu a"
    //     ]?.["font-size"],
    // },
    // "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5": {
    //   "font-size":
    //     payload?.[
    //       "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5"
    //     ]?.["font-size"],
    //   "padding-bottom":
    //     payload?.[
    //       "header.style-one .header-wrapper .navbar-nav .nav-item .megamenu h5"
    //     ]?.["padding-bottom"],
    // },
    "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler":
      {
        width:
          payload?.[
            "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
          ]?.["width"],
        height:
          payload?.[
            "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
          ]?.["height"],
        "font-size":
          payload?.[
            "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
          ]?.["font-size"],
        "line-height":
          payload?.[
            "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
          ]?.["line-height"],
        "border-radius":
          payload?.[
            "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
          ]?.["border-radius"],
        color: payload?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["color"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["color"],
            })
          : "",
        "background-color": payload?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["background-color"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["background-color"],
            })
          : "",
        "box-shadow":
          payload?.[
            "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
          ]?.["box-shadow"],
        bs1: payload?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["bs1"],
        bs2: payload?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["bs2"],
        bs3: payload?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["bs3"],
        bs4: payload?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["bs4"],
        shadowColor: payload?.[
          "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
        ]?.["shadowColor"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  "header.style-one .header-wrapper .left-menu-icons .links, header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
                ]?.["shadowColor"],
            })
          : "",
      },
    "header.style-one .header-wrapper .left-menu-icons .navbar-toggler svg": {
      fill: payload?.[
        "header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
      ]?.["fill"]
        ? convertRGBA({
            rgbString:
              payload?.[
                "header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
              ]?.["fill"],
          })
        : "",
    },
    "header.style-one .header-wrapper .left-menu-icons .navbar-toggler": {
      "background-color": payload?.[
        "header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                "header.style-one .header-wrapper .left-menu-icons .navbar-toggler"
              ]?.["background-color"],
          })
        : "",
    },
    "header.style-one .header-wrapper .navbar-nav .media": {
      background: payload?.["@media screen and (max-width: 1199px)"]?.[
        "header.style-one .header-wrapper .navbar-nav"
      ]?.["background"]
        ? convertRGBA({
            rgbString:
              payload?.["@media screen and (max-width: 1199px)"]?.[
                "header.style-one .header-wrapper .navbar-nav"
              ]?.["background"],
          })
        : "",
    },
  };
};
export const navbarThreeSetData = (payload) => {
  return {
    ".custom-nav": {
      padding: payload?.[".custom-nav"]?.["padding"],
      "background-color": payload?.[".custom-nav"]?.["background-color"]
        ? convertRGBA({
            rgbString: payload?.[".custom-nav"]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .navbar-brand img": {
      "max-width": payload?.[".custom-nav .navbar-brand img"]?.["max-width"],
    },
    ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
      {
        color: payload?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ]?.["color"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                ]?.["color"],
            })
          : "",
        "background-color": payload?.[
          ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
        ]?.["background-color"]
          ? convertRGBA({
              rgbString:
                payload?.[
                  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                ]?.["background-color"],
            })
          : "",
      },
    ".custom-nav .navbar-nav .nav-link": {
      "font-size":
        payload?.[".custom-nav .navbar-nav .nav-link"]?.["font-size"],
      "font-weight":
        payload?.[".custom-nav .navbar-nav .nav-link"]?.["font-weight"],
      padding: payload?.[".custom-nav .navbar-nav .nav-link"]?.["padding"],
      p1: payload?.[".custom-nav .navbar-nav .nav-link"]?.["p1"],
      p2: payload?.[".custom-nav .navbar-nav .nav-link"]?.["p2"],
      margin: payload?.[".custom-nav .navbar-nav .nav-link"]?.["margin"],
      m1: payload?.[".custom-nav .navbar-nav .nav-link"]?.["m1"],
      m2: payload?.[".custom-nav .navbar-nav .nav-link"]?.["m2"],
      // padding: "35px 0",
      // p1: "35px",
      // margin: "0 15px",
      // m2: "15px",
      color: payload?.[".custom-nav .navbar-nav .nav-link"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .navbar-nav .nav-link"]?.["color"],
          })
        : "",
    },
    ".custom-nav .navbar-nav .dropdown-menu": {
      "border-top":
        payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.["border-top"],
      borderPx:
        payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.["borderPx"],
      borderThik:
        payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.["borderThik"],
      borderColor: payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.[
        "borderColor"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.[
                "borderColor"
              ],
          })
        : "",
      "background-color": payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .navbar-nav .dropdown-menu"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item": {
      "font-size":
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "font-size"
        ],
      "font-weight":
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "font-weight"
        ],
      "line-height":
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "line-height"
        ],
      "border-bottom":
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "border-bottom"
        ],
      borderPx:
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "borderPx"
        ],
      borderThik:
        payload?.[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"]?.[
          "borderThik"
        ],
      borderColor: payload?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
              ]?.["borderColor"],
          })
        : "",
      "background-color": payload?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
              ]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .navbar-nav .dropdown-mega .subtitle": {
      "font-size":
        payload?.[".custom-nav .navbar-nav .dropdown-mega .subtitle"]?.[
          "font-size"
        ],
      "font-weight":
        payload?.[".custom-nav .navbar-nav .dropdown-mega .subtitle"]?.[
          "font-weight"
        ],
      "margin-bottom":
        payload?.[".custom-nav .navbar-nav .dropdown-mega .subtitle"]?.[
          "margin-bottom"
        ],
    },
    ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item": {
      "font-size":
        payload?.[
          ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
        ]?.["font-size"],
      "font-weight":
        payload?.[
          ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
        ]?.["font-weight"],
      "line-height":
        payload?.[
          ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
        ]?.["line-height"],
    },
    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before": {
      "background-color": payload?.[
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[
                ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
              ]?.["background-color"],
          })
        : "",
    },
    ".custom-nav .left-menu-icons .links": {
      color: payload?.[".custom-nav .left-menu-icons .links"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .left-menu-icons .links"]?.["color"],
          })
        : "",
      padding: payload?.[".custom-nav .left-menu-icons .links"]?.["padding"],
    },
    ".custom-nav .login-user .dropdown-toggle": {
      "border-radius":
        payload?.[".custom-nav .login-user .dropdown-toggle"]?.[
          "border-radius"
        ],
      border: payload?.[".custom-nav .login-user .dropdown-toggle"]?.["border"],
      borderPx:
        payload?.[".custom-nav .login-user .dropdown-toggle"]?.["borderPx"],
      borderThik:
        payload?.[".custom-nav .login-user .dropdown-toggle"]?.["borderThik"],
      borderColor:
        payload?.[".custom-nav .login-user .dropdown-toggle"]?.["borderColor"],
      "background-color": payload?.[
        ".custom-nav .login-user .dropdown-toggle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .login-user .dropdown-toggle"]?.[
                "background-color"
              ],
          })
        : "",
      color: payload?.[".custom-nav .login-user .dropdown-toggle"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .login-user .dropdown-toggle"]?.["color"],
          })
        : "",
    },
    ".custom-nav .navbar-toggler": {
      fill: payload?.[".custom-nav .navbar-toggler"]?.["fill"]
        ? convertRGBA({
            rgbString: payload?.[".custom-nav .navbar-toggler"]?.["fill"],
          })
        : "",
      "border-color": payload?.[".custom-nav .navbar-toggler"]?.["border-color"]
        ? convertRGBA({
            rgbString:
              payload?.[".custom-nav .navbar-toggler"]?.["border-color"],
          })
        : "",
    },
    ".custom-nav .navbar-collapse": {
      "background-color": payload?.["@media screen and (max-width: 991px)"]?.[
        ".custom-nav .navbar-collapse"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload?.["@media screen and (max-width: 991px)"]?.[
                ".custom-nav .navbar-collapse"
              ]?.["background-color"],
          })
        : "",
    },
  };
};
