import React, { useContext } from "react";
import { OpenModalCommonFunc, TostMessage } from "../../../../utils";
import CommonPopup from "../../../../Modals/commonPopup";
import { Cases, modalsName } from "../../../../constant";
import ByteCardBuilderContext from "../../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { FILE_TYPE } from "../../../../../constant";
import { FILE_UPLOAD_API } from "../../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../../context/LoaderContext/LoaderContext";
import { Update_Nav_Style } from "../../apis/DomainChangeApi/DomainChangeApi";
import HeaderContext from "../../../../context/HeaderContext/HeaderContext";
import SettingHeadersPopup from "./SettingHeadersPopup";
import {
  navBarStyleOne,
  navBarStyleSetFunction,
  navBarThreeStyleSetFunction,
  navBarTwoStyleSetFunction,
  navbarSetData,
  navbarThreeSetData,
  navbarTwoSetData,
} from "./NavbarStyleFunction";
import { Button } from "react-bootstrap";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";

const Header = ({ ByteMiniWeb_Dashboard, webCategoryDetails }) => {
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    settingFormData,
    setSettingFormData,
    setIsBackgroundColor,
    theme,
    setTheme,
    setImgShow,
    checkImageType,
  } = useContext(HeaderContext);

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    let formData = new FormData();
    formData.append(
      "type",
      checkImageType === "loader" ? FILE_TYPE.LOADER : FILE_TYPE.SEO
    );
    formData.append("file", file);
    setLoaderShow(true);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        setSettingFormData((prev) => {
          if (checkImageType === "logo") {
            return {
              ...prev,
              logo: data.payload.baseUrl + data.payload.imageUrl,
              image: "",
            };
          } else if (checkImageType === "favicon") {
            return {
              ...prev,
              favicon: data.payload.baseUrl + data.payload.imageUrl,
              image: "",
            };
          } else if (checkImageType === "loader") {
            return {
              ...prev,
              loader: data.payload.baseUrl + data.payload.imageUrl,
              image: "",
            };
          }
        });

        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };
  const handleSubmitSetting = async (isClose = true) => {
    let formDataStyle;
    if (+theme === 1) {
      formDataStyle = navBarStyleSetFunction(settingFormData);
    } else if (+theme === 2) {
      formDataStyle = navBarTwoStyleSetFunction(settingFormData);
    } else if (+theme === 3) {
      formDataStyle = navBarThreeStyleSetFunction(settingFormData);
    }
    try {
      let paramObj = {
        ...settingFormData,
        ...formDataStyle,
        theme: theme,
      };
      paramObj["logo"] = paramObj?.logo
        ?.split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
        .pop();
      paramObj["favicon"] = paramObj?.favicon
        ?.split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
        .pop();
      paramObj["loader"] = paramObj?.loader
        ?.split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
        .pop();
      delete paramObj?.image;
      const { data } = await Update_Nav_Style(paramObj);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
        if (isClose) {
          OpenModalCommonFunc(
            Cases.SETTING_NAVBAR_PARENT,
            setAllPopupState,
            false
          );
          emptyData();
        }
        ByteMiniWeb_Dashboard();
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };
  const handleSetting = () => {
    const payload = webCategoryDetails[0]?.otherSetting;
    let formStyle;
    if (+webCategoryDetails[0]?.otherSetting?.theme === 1) {
      formStyle = navbarSetData(payload);
    } else if (+webCategoryDetails[0]?.otherSetting?.theme === 2) {
      formStyle = navbarTwoSetData(payload);
    } else if (+webCategoryDetails[0]?.otherSetting?.theme === 3) {
      formStyle = navbarThreeSetData(payload);
    } else {
      formStyle = navBarStyleOne;
    }
    setTheme(webCategoryDetails[0]?.otherSetting?.theme ?? "1");
    setImgShow(true);
    if (
      payload?.[
        ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
      ]?.["background-color"]
    ) {
      setIsBackgroundColor(true);
    }
    setSettingFormData({
      ...webCategoryDetails[0]?.otherSetting,
      ...formStyle,
      logo: webCategoryDetails[0]?.otherSetting?.logo
        ? `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/${webCategoryDetails[0]?.otherSetting?.logo}`
        : "",
      favicon: webCategoryDetails[0]?.otherSetting?.favicon
        ? `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/${webCategoryDetails[0]?.otherSetting?.favicon}`
        : "",
      loader: webCategoryDetails[0]?.otherSetting?.loader
        ? `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/${webCategoryDetails[0]?.otherSetting?.loader}`
        : "",
    });
    OpenModalCommonFunc(Cases.SETTING_NAVBAR_PARENT, setAllPopupState);
  };

  const emptyData = () => {
    setIsBackgroundColor(false);
    setSettingFormData({
      ".custom-nav": {
        padding: "0",
        "background-color": "rgb(31 39 50 / 1)",
      },
      ".custom-nav .navbar-brand img": {
        "max-width": "200px",
      },
      ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
        {
          color: "rgb(255 73 124 / 100)",
        },
      ".custom-nav .navbar-nav .nav-link": {
        "font-size": "14px",
        "font-weight": "500",
        padding: "13px 12px",
        p1: "13px",
        p2: "12px",
        margin: "17px 3px",
        m1: "17px",
        m2: "3px",
        // padding: "35px 0",
        // p1: "35px",
        // margin: "0 15px",
        // m2: "15px",
        color: "rgb(255 255 255 / 100)",
      },
      ".custom-nav .navbar-nav .dropdown-menu": {
        "border-top": "3px solid #ff497c",
        borderPx: "3px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
      },
      ".custom-nav .navbar-nav .dropdown-menu .dropdown-item": {
        "font-size": "14px",
        "font-weight": "500",
        "line-height": "2",
        "border-bottom": "1px solid #ddd",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgba(137 137 137 / 1)",
        "background-color": "transparent",
      },
      ".custom-nav .navbar-nav .dropdown-mega .subtitle": {
        "font-size": "16px",
        "font-weight": "600",
        "margin-bottom": "10px",
      },
      ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item": {
        "font-size": "14px",
        "font-weight": "500",
        "line-height": "1.3",
      },
      ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before": {
        "background-color": "rgb(255 73 124 / 100)",
      },
      ".custom-nav .left-menu-icons .links": {
        color: "rgb(255 255 255 / 100)",
        padding: "3px",
      },
      ".custom-nav .login-user .dropdown-toggle": {
        "border-radius": "15px",
        border: "1px solid #fff",
        borderPx: "1px",
        borderThik: "solid",
        borderColor: "rgb(255 255 255 / 100)",
        "background-color": "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
      },
    });
  };

  return (
    <>
      <Button
        aria-label="edit"
        onClick={handleSetting}
        style={{ marginBottom: "5px", padding: "5px 10px" }}
        className="d-flex align-item-center justify-content-center"
      >
        <span style={{ paddingRight: "5px", paddingTop: "2px" }}>
          <ViewHeadlineIcon />
        </span>{" "}
        <span style={{ paddingTop: "5px" }}>Menu Theme</span>
      </Button>
      <CommonPopup
        open={allPopupState.navbarSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_NAVBAR_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={modalsName.SETTING_POPUP_DIALOG_PARENT}
        modalCase={Cases.SETTING_NAVBAR_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={settingFormData}
        edit={false}
        content={
          <SettingHeadersPopup
            imageUpload={imageUploadImages}
            handleSubmitSetting={handleSubmitSetting}
          />
        }
      />
    </>
  );
};

export default Header;
