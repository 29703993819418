import {
  addUrlImage,
  convertRGBA,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";

export const TestimonialThemeOneFunction = (payload) => {
  return {
    ".testimonial-section": {
      padding: payload.style?.[".testimonial-section"]?.["padding"],
      "background-color": payload.style?.[".testimonial-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".testimonial-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".testimonial-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".testimonial-section"]?.["margin"],
      "border-radius":
        payload.style?.[".testimonial-section"]?.["border-radius"],
    },
    ".testimonial-section.bg-image": {
      "background-image": payload.style?.[".testimonial-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".testimonial-section.bg-image"]?.[
                "background-image"
              ],
          })
        : "",
    },
    ".testimonial-section.bg-image::before": {
      "background-color": payload.style?.[
        ".testimonial-section.bg-image::before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".testimonial-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".testimonial-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".testimonial-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".testimonial-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".testimonial-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".testimonial-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".testimonial-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".testimonial-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".testimonial-slider .slick-arrow::before": {
      color: payload.style?.[".testimonial-slider .slick-arrow::before"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".testimonial-slider .slick-arrow::before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
    },
    ".testimonial-slider .testimonial-content": {
      "box-shadow":
        payload.style?.[".testimonial-slider .testimonial-content"]?.[
          "box-shadow"
        ],
      bs1: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "box-shadow"
      ]?.split(" ")[0],
      bs2: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "box-shadow"
      ]?.split(" ")[1],
      bs3: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "box-shadow"
      ]?.split(" ")[2],
      bs4: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "box-shadow"
      ]?.split(" ")[3],
      shadowColor: payload.style?.[
        ".testimonial-slider .testimonial-content"
      ]?.["shadowColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".testimonial-slider .testimonial-content"]?.[
                "shadowColor"
              ],
          })
        : "",
      "background-color": payload.style?.[
        ".testimonial-slider .testimonial-content"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".testimonial-slider .testimonial-content"]?.[
                "background-color"
              ],
          })
        : "",
      margin:
        payload.style?.[".testimonial-slider .testimonial-content"]?.["margin"],
      padding:
        payload.style?.[".testimonial-slider .testimonial-content"]?.[
          "padding"
        ],
      p1: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "padding"
      ]?.split(" ")[1],
      p3: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "padding"
      ]?.split(" ")[2],
      p4: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "padding"
      ]?.split(" ")[3],
      "border-radius":
        payload.style?.[".testimonial-slider .testimonial-content"]?.[
          "border-radius"
        ],
      "text-align":
        payload.style?.[".testimonial-slider .testimonial-content"]?.[
          "text-align"
        ],
    },
    ".testimonial-slider .testimonial-content:before": {
      "font-family":
        payload.style?.[".testimonial-slider .testimonial-content:before"]?.[
          "font-family"
        ],
      color: payload.style?.[
        ".testimonial-slider .testimonial-content:before"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content:before"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".testimonial-slider .testimonial-content:before"]?.[
          "font-size"
        ],
      opacity:
        payload.style?.[".testimonial-slider .testimonial-content:before"]?.[
          "opacity"
        ],
      top: payload.style?.[".testimonial-slider .testimonial-content:before"]?.[
        "top"
      ],
    },
    ".testimonial-slider .testimonial-content .avatar-img":
      payload?.theme == "1"
        ? {
            width:
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["width"],
            height:
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["height"],
            "border-radius":
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["border-radius"],
            border:
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["border"],
            borderPx:
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["border"]?.split(" ")[0],
            borderThik:
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["border"]?.split(" ")[1],
            borderColor: payload.style?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["borderColor"]
              ? convertRGBA({
                  rgbString:
                    payload.style?.[
                      ".testimonial-slider .testimonial-content .avatar-img"
                    ]?.["borderColor"],
                })
              : "",
          }
        : {
            "min-width":
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["min-width"],
            "min-height":
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["min-height"],
            "border-radius":
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["border-radius"],
            border:
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["border"],
            borderPx:
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["border"]?.split(" ")[0],
            borderThik:
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["border"]?.split(" ")[1],
            borderColor: payload.style?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["borderColor"]
              ? convertRGBA({
                  rgbString:
                    payload.style?.[
                      ".testimonial-slider .testimonial-content .avatar-img"
                    ]?.["borderColor"],
                })
              : "",
          },
    ".testimonial-slider .testimonial-content .comments": {
      color: payload.style?.[
        ".testimonial-slider .testimonial-content .comments"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content .comments"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".testimonial-slider .testimonial-content .comments"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".testimonial-slider .testimonial-content .comments"]?.[
          "font-weight"
        ],
      "line-height":
        payload.style?.[".testimonial-slider .testimonial-content .comments"]?.[
          "line-height"
        ],
      margin:
        payload.style?.[".testimonial-slider .testimonial-content .comments"]?.[
          "margin"
        ],
    },
    ".testimonial-slider .testimonial-content .client-name": {
      color: payload.style?.[
        ".testimonial-slider .testimonial-content .client-name"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content .client-name"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-name"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-name"
        ]?.["font-weight"],
      "margin-bottom":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-name"
        ]?.["margin-bottom"],
    },
    ".testimonial-slider .testimonial-content .client-position": {
      color: payload.style?.[
        ".testimonial-slider .testimonial-content .client-position"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content .client-position"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-position"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-position"
        ]?.["font-weight"],
      "margin-bottom":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-position"
        ]?.["margin-bottom"],
    },
    ".testimonial-slider .testimonial-content .star-rating": {
      "text-align":
        payload.style?.[
          ".testimonial-slider .testimonial-content .star-rating"
        ]?.["text-align"],
    },
    ".testimonial-slider .testimonial-content .star-rating i": {
      color: payload.style?.[
        ".testimonial-slider .testimonial-content .star-rating i"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content .star-rating i"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".testimonial-slider .testimonial-content .star-rating i"
        ]?.["font-size"],
    },
    ".testimonial-slider .slick-dots li button:before": {
      "font-size": payload.style?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["font-size"]?.replace(" !important", ""),
      color: payload.style?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".testimonial-slider .slick-dots li button:before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
      opacity: payload.style?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["opacity"]?.replace(" !important", ""),
    },
    ".testimonial-slider .slick-dots li.slick-active button:before": {
      color: payload.style?.[
        ".testimonial-slider .slick-dots li.slick-active button:before"
      ]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".testimonial-slider .slick-dots li.slick-active button:before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
    },
  };
};
export const TestimonialThemeFourFunction = (payload) => {
  return {
    ".testimonial-section": {
      padding: payload.style?.[".testimonial-section"]?.["padding"],
      "background-color": payload.style?.[".testimonial-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".testimonial-section"]?.["background-color"],
          })
        : "",
      width: payload.style?.[".testimonial-section"]?.["width"]?.replace(
        "calc(100% - ",
        ""
      ),
      margin: payload.style?.[".testimonial-section"]?.["margin"],
      "border-radius":
        payload.style?.[".testimonial-section"]?.["border-radius"],
    },
    ".testimonial-section.bg-image": {
      "background-image": payload.style?.[".testimonial-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".testimonial-section.bg-image"]?.[
                "background-image"
              ],
          })
        : "",
    },
    ".testimonial-section.bg-image::before": {
      "background-color": payload.style?.[
        ".testimonial-section.bg-image::before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".testimonial-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".testimonial-section .section-title-area": {
      "margin-bottom":
        payload.style?.[".testimonial-section .section-title-area"]?.[
          "margin-bottom"
        ],
    },
    ".testimonial-section .section-title-area .section-subtitle": {
      "font-size":
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["font-weight"],
      color: payload.style?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": payload.style?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding:
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["padding"],
      p1: payload.style?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[0],
      p2: payload.style?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["padding"]?.split(" ")[1],
      "border-radius":
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["border-radius"],
      "margin-bottom":
        payload.style?.[
          ".testimonial-section .section-title-area .section-subtitle"
        ]?.["margin-bottom"],
    },
    ".testimonial-section .section-title-area .section-title": {
      "font-size":
        payload.style?.[
          ".testimonial-section .section-title-area .section-title"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".testimonial-section .section-title-area .section-title"
        ]?.["font-weight"],
      color: payload.style?.[
        ".testimonial-section .section-title-area .section-title"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".testimonial-slider .slick-arrow::before": {
      color: payload.style?.[".testimonial-slider .slick-arrow::before"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".testimonial-slider .slick-arrow::before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
    },
    ".testimonial-slider .testimonial-content": {
      "box-shadow":
        payload.style?.[".testimonial-slider .testimonial-content"]?.[
          "box-shadow"
        ],
      bs1: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "box-shadow"
      ]?.split(" ")[0],
      bs2: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "box-shadow"
      ]?.split(" ")[1],
      bs3: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "box-shadow"
      ]?.split(" ")[2],
      bs4: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "box-shadow"
      ]?.split(" ")[3],
      shadowColor: payload.style?.[
        ".testimonial-slider .testimonial-content"
      ]?.["shadowColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".testimonial-slider .testimonial-content"]?.[
                "shadowColor"
              ],
          })
        : "",
      "background-color": payload.style?.[
        ".testimonial-slider .testimonial-content"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".testimonial-slider .testimonial-content"]?.[
                "background-color"
              ],
          })
        : "",
      margin:
        payload.style?.[".testimonial-slider .testimonial-content"]?.["margin"],
      padding:
        payload.style?.[".testimonial-slider .testimonial-content"]?.[
          "padding"
        ],
      p1: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "padding"
      ]?.split(" ")[0],
      p2: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "padding"
      ]?.split(" ")[1],
      p3: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "padding"
      ]?.split(" ")[2],
      p4: payload.style?.[".testimonial-slider .testimonial-content"]?.[
        "padding"
      ]?.split(" ")[3],
      "border-radius":
        payload.style?.[".testimonial-slider .testimonial-content"]?.[
          "border-radius"
        ],
      "text-align":
        payload.style?.[".testimonial-slider .testimonial-content"]?.[
          "text-align"
        ],
    },
    ".testimonial-slider .testimonial-content .avatar-img": {
      width:
        payload.style?.[
          ".testimonial-slider .testimonial-content .avatar-img"
        ]?.["width"],
      height:
        payload.style?.[
          ".testimonial-slider .testimonial-content .avatar-img"
        ]?.["height"],
      "border-radius":
        payload.style?.[
          ".testimonial-slider .testimonial-content .avatar-img"
        ]?.["border-radius"],
      border:
        payload.style?.[
          ".testimonial-slider .testimonial-content .avatar-img"
        ]?.["border"],
      borderPx:
        payload.style?.[
          ".testimonial-slider .testimonial-content .avatar-img"
        ]?.["border"]?.split(" ")[0],
      borderThik:
        payload.style?.[
          ".testimonial-slider .testimonial-content .avatar-img"
        ]?.["border"]?.split(" ")[1],
      borderColor: payload.style?.[
        ".testimonial-slider .testimonial-content .avatar-img"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["borderColor"],
          })
        : "",
    },
    ".testimonial-slider .testimonial-content .comments": {
      color: payload.style?.[
        ".testimonial-slider .testimonial-content .comments"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content .comments"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".testimonial-slider .testimonial-content .comments"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".testimonial-slider .testimonial-content .comments"]?.[
          "font-weight"
        ],
      "line-height":
        payload.style?.[".testimonial-slider .testimonial-content .comments"]?.[
          "line-height"
        ],
      margin:
        payload.style?.[".testimonial-slider .testimonial-content .comments"]?.[
          "margin"
        ],
    },
    ".testimonial-slider .testimonial-content .client-info": {
      width:
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-info"
        ]?.["width"],
      gap: payload.style?.[
        ".testimonial-slider .testimonial-content .client-info"
      ]?.["gap"],
    },
    ".testimonial-slider .testimonial-content .client-name": {
      color: payload.style?.[
        ".testimonial-slider .testimonial-content .client-name"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content .client-name"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-name"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-name"
        ]?.["font-weight"],
      "margin-bottom":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-name"
        ]?.["margin-bottom"],
    },
    ".testimonial-slider .testimonial-content .client-position": {
      color: payload.style?.[
        ".testimonial-slider .testimonial-content .client-position"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content .client-position"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-position"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".testimonial-slider .testimonial-content .client-position"
        ]?.["font-weight"],
    },
    ".testimonial-slider .testimonial-content .star-rating": {
      "text-align":
        payload.style?.[
          ".testimonial-slider .testimonial-content .star-rating"
        ]?.["text-align"],
    },
    ".testimonial-slider .testimonial-content .star-rating i": {
      color: payload.style?.[
        ".testimonial-slider .testimonial-content .star-rating i"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".testimonial-slider .testimonial-content .star-rating i"
              ]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[
          ".testimonial-slider .testimonial-content .star-rating i"
        ]?.["font-size"],
    },
    ".testimonial-slider .slick-dots li button:before": {
      "font-size": payload.style?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["font-size"]?.replace(" !important", ""),
      color: payload.style?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".testimonial-slider .slick-dots li button:before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
      opacity: payload.style?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["opacity"]?.replace(" !important", ""),
    },
    ".testimonial-slider .slick-dots li.slick-active button:before": {
      color: payload.style?.[
        ".testimonial-slider .slick-dots li.slick-active button:before"
      ]?.["color"]
        ? convertRGBA({
            rgbString: payload.style?.[
              ".testimonial-slider .slick-dots li.slick-active button:before"
            ]?.["color"]?.replace(" !important", ""),
          })
        : "",
    },
  };
};

export const TestimonialThemeTwoFunction = (payload) => {
  return {
    ".zig-zag-section": {
      padding: payload.style?.[".zig-zag-section"]?.["padding"],
      "background-color": payload.style?.[".zig-zag-section"]?.[
        "background-color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".zig-zag-section"]?.["background-color"],
          })
        : "",
    },
    ".zig-zag-section.bg-image": {
      "background-image": payload.style?.[".zig-zag-section.bg-image"]?.[
        "background-image"
      ]
        ? removeUrlKeyInPath({
            urlString:
              payload.style?.[".zig-zag-section.bg-image"]?.[
                "background-image"
              ],
          })
        : "",
    },
    ".zig-zag-section.bg-image::before": {
      "background-color": payload.style?.[
        ".zig-zag-section.bg-image::before"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".zig-zag-section.bg-image::before"]?.[
                "background-color"
              ],
          })
        : "",
    },
    ".zig-zag-section .testimonial-divs .testimonial": {
      padding:
        payload.style?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
          "padding"
        ],
      "border-radius":
        payload.style?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
          "border-radius"
        ],
      border:
        payload.style?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
          "border"
        ],
      borderPx:
        payload.style?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
          "borderPx"
        ],
      borderThik:
        payload.style?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
          "borderThik"
        ],
      borderColor: payload.style?.[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".zig-zag-section .testimonial-divs .testimonial"
              ]?.["borderColor"],
          })
        : "",
      "box-shadow":
        payload.style?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
          "borderColor"
        ],
      bs1: payload.style?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
        "bs1"
      ],
      bs2: payload.style?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
        "bs2"
      ],
      bs3: payload.style?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
        "bs3"
      ],
      shadowColor: payload.style?.[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["shadowColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".zig-zag-section .testimonial-divs .testimonial"
              ]?.["shadowColor"],
          })
        : "",
      "background-color": payload.style?.[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["background-color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".zig-zag-section .testimonial-divs .testimonial"
              ]?.["background-color"],
          })
        : "",
    },
    ".zig-zag-section .testimonial-divs .avatar-img": {
      "border-radius":
        payload.style?.[".zig-zag-section .testimonial-divs .avatar-img"]?.[
          "border-radius"
        ],
      border:
        payload.style?.[".zig-zag-section .testimonial-divs .avatar-img"]?.[
          "border"
        ],
      borderPx:
        payload.style?.[".zig-zag-section .testimonial-divs .avatar-img"]?.[
          "borderPx"
        ],
      borderThik:
        payload.style?.[".zig-zag-section .testimonial-divs .avatar-img"]?.[
          "borderThik"
        ],
      borderColor: payload.style?.[
        ".zig-zag-section .testimonial-divs .avatar-img"
      ]?.["borderColor"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".zig-zag-section .testimonial-divs .avatar-img"
              ]?.["borderColor"],
          })
        : "",
    },
    ".zig-zag-section .testimonial-divs .client-name": {
      "font-size":
        payload.style?.[".zig-zag-section .testimonial-divs .client-name"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".zig-zag-section .testimonial-divs .client-name"]?.[
          "font-weight"
        ],
      "line-height":
        payload.style?.[".zig-zag-section .testimonial-divs .client-name"]?.[
          "line-height"
        ],
      color: payload.style?.[
        ".zig-zag-section .testimonial-divs .client-name"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".zig-zag-section .testimonial-divs .client-name"
              ]?.["color"],
          })
        : "",
    },
    ".zig-zag-section .testimonial-divs .client-position": {
      "font-size":
        payload.style?.[
          ".zig-zag-section .testimonial-divs .client-position"
        ]?.["font-size"],
      "font-weight":
        payload.style?.[
          ".zig-zag-section .testimonial-divs .client-position"
        ]?.["font-weight"],
      color: payload.style?.[
        ".zig-zag-section .testimonial-divs .client-position"
      ]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[
                ".zig-zag-section .testimonial-divs .client-position"
              ]?.["color"],
          })
        : "",
    },
    ".zig-zag-section .testimonial-divs .desq": {
      "font-size":
        payload.style?.[".zig-zag-section .testimonial-divs .desq"]?.[
          "font-size"
        ],
      "font-weight":
        payload.style?.[".zig-zag-section .testimonial-divs .desq"]?.[
          "font-weight"
        ],
      color: payload.style?.[".zig-zag-section .testimonial-divs .desq"]?.[
        "color"
      ]
        ? convertRGBA({
            rgbString:
              payload.style?.[".zig-zag-section .testimonial-divs .desq"]?.[
                "color"
              ],
          })
        : "",
      "line-height":
        payload.style?.[".zig-zag-section .testimonial-divs .desq"]?.[
          "line-height"
        ],
      margin:
        payload.style?.[".zig-zag-section .testimonial-divs .desq"]?.["margin"],
    },
    ".zig-zag-section .star-rating i": {
      color: payload.style?.[".zig-zag-section .star-rating i"]?.["color"]
        ? convertRGBA({
            rgbString:
              payload.style?.[".zig-zag-section .star-rating i"]?.["color"],
          })
        : "",
      "font-size":
        payload.style?.[".zig-zag-section .star-rating i"]?.["font-size"],
    },
  };
};

export const TestimonialOneSubFun = ({
  settingFormData,
  boxSection,
  theme,
}) => {
  let newData = {
    ...settingFormData,
    ".testimonial-section": boxSection
      ? {
          ...settingFormData?.[".testimonial-section"],
          padding: `${settingFormData?.[".testimonial-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".testimonial-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".testimonial-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".testimonial-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".testimonial-section"],
          padding: `${settingFormData?.[".testimonial-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".testimonial-section.bg-image": settingFormData?.[
      ".testimonial-section.bg-image"
    ]?.["background-image"]
      ? {
          ...settingFormData?.[".testimonial-section.bg-image"],
          "background-image": settingFormData?.[
            ".testimonial-section.bg-image"
          ]?.["background-image"]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".testimonial-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".testimonial-section.bg-image::before": settingFormData?.[
      ".testimonial-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".testimonial-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".testimonial-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".testimonial-section .section-title-area": {
      "margin-bottom": `${settingFormData[
        ".testimonial-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".testimonial-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".testimonial-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".testimonial-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".testimonial-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".testimonial-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".testimonial-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".testimonial-slider .slick-arrow::before": {
      ...settingFormData?.[".testimonial-slider .slick-arrow::before"],
      color: settingFormData?.[".testimonial-slider .slick-arrow::before"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".testimonial-slider .slick-arrow::before"]?.[
                "color"
              ],
          })
        : "",
    },
    ".testimonial-slider .testimonial-content": {
      ...settingFormData?.[".testimonial-slider .testimonial-content"],
      "box-shadow": `${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["bs1"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".testimonial-slider .testimonial-content"]?.[
            "shadowColor"
          ],
      })}`,
      "background-color": settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".testimonial-slider .testimonial-content"]?.[
                "background-color"
              ],
          })
        : "",
      margin: `${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["margin"]?.replace("px", "")}px`,
      padding: `${settingFormData[".testimonial-slider .testimonial-content"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["p2"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["p3"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["p4"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".testimonial-slider .testimonial-content"]?.["text-align"]}`,
    },
    ".testimonial-slider .testimonial-content:before": {
      ...settingFormData?.[".testimonial-slider .testimonial-content:before"],
      "font-family": `${settingFormData?.[".testimonial-slider .testimonial-content:before"]?.["font-family"]}`,
      color: settingFormData?.[
        ".testimonial-slider .testimonial-content:before"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .testimonial-content:before"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .testimonial-content:before"
      ]?.["font-size"]?.replace("px", "")}px`,
      opacity: `${settingFormData?.[".testimonial-slider .testimonial-content:before"]?.["opacity"]}`,
      top: `${settingFormData[
        ".testimonial-slider .testimonial-content:before"
      ]?.["top"]?.replace("px", "")}px`,
    },
    ".testimonial-slider .testimonial-content .avatar-img":
      theme == "1"
        ? {
            ...settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ],
            width: `${settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["width"]?.replace("px", "")}px`,
            height: `${settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["height"]?.replace("px", "")}px`,
            "border-radius": `${settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["border-radius"]?.replace("%", "")}%`,
            border: `${settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["borderPx"]?.replace("px", "")}px ${
              settingFormData?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["borderThik"]
            } ${rgbColorCreate({
              rgbString:
                settingFormData?.[
                  ".testimonial-slider .testimonial-content .avatar-img"
                ]?.["borderColor"],
            })}`,
          }
        : {
            ...settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ],
            "min-width": `${settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["min-width"]?.replace("px", "")}px`,
            "min-height": `${settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["min-height"]?.replace("px", "")}px`,
            "border-radius": `${settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["border-radius"]?.replace("%", "")}%`,
            border: `${settingFormData?.[
              ".testimonial-slider .testimonial-content .avatar-img"
            ]?.["borderPx"]?.replace("px", "")}px ${
              settingFormData?.[
                ".testimonial-slider .testimonial-content .avatar-img"
              ]?.["borderThik"]
            } ${rgbColorCreate({
              rgbString:
                settingFormData?.[
                  ".testimonial-slider .testimonial-content .avatar-img"
                ]?.["borderColor"],
            })}`,
          },
    ".testimonial-slider .testimonial-content .comments": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .comments"
      ],
      color: settingFormData?.[
        ".testimonial-slider .testimonial-content .comments"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .testimonial-content .comments"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .comments"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-slider .testimonial-content .comments"]?.["font-weight"]}`,
      "line-height": `${settingFormData?.[".testimonial-slider .testimonial-content .comments"]?.["line-height"]}`,
      margin: `${settingFormData[
        ".testimonial-slider .testimonial-content .comments"
      ]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
    },
    ".testimonial-slider .testimonial-content .client-name": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .client-name"
      ],
      color: settingFormData?.[
        ".testimonial-slider .testimonial-content .client-name"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .testimonial-content .client-name"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .client-name"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-slider .testimonial-content .client-name"]?.["font-weight"]}`,
      "margin-bottom": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .client-name"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".testimonial-slider .testimonial-content .client-position": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .client-position"
      ],
      color: settingFormData?.[
        ".testimonial-slider .testimonial-content .client-position"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .testimonial-content .client-position"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .client-position"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-slider .testimonial-content .client-position"]?.["font-weight"]}`,
      "margin-bottom": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .client-position"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".testimonial-slider .testimonial-content .star-rating": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .star-rating"
      ],
      "text-align": `${settingFormData?.[".testimonial-slider .testimonial-content .star-rating"]?.["text-align"]}`,
    },
    ".testimonial-slider .testimonial-content .star-rating i": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .star-rating i"
      ],
      color: settingFormData?.[
        ".testimonial-slider .testimonial-content .star-rating i"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .testimonial-content .star-rating i"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .star-rating i"
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    ".testimonial-slider .slick-dots li button:before": {
      ...settingFormData?.[".testimonial-slider .slick-dots li button:before"],
      color: settingFormData?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["color"]
        ? `${rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .slick-dots li button:before"
              ]?.["color"],
          })} !important`
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["font-size"]?.replace("px", "")}px !important`,
      opacity: `${settingFormData?.[".testimonial-slider .slick-dots li button:before"]?.["opacity"]} !important`,
    },
    ".testimonial-slider .slick-dots li.slick-active button:before": {
      color: settingFormData?.[
        ".testimonial-slider .slick-dots li.slick-active button:before"
      ]?.["color"]
        ? `${rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .slick-dots li.slick-active button:before"
              ]?.["color"],
          })} !important`
        : "",
      opacity: "1 !important",
    },
  };
  return newData;
};
export const TestimonialFourSubFun = ({
  settingFormData,
  boxSection,
  theme,
}) => {
  let newData = {
    ...settingFormData,
    ".testimonial-section": boxSection
      ? {
          ...settingFormData?.[".testimonial-section"],
          padding: `${settingFormData?.[".testimonial-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
          width: `calc(100% - ${settingFormData?.[".testimonial-section"]?.[
            "width"
          ]?.replace("rem)", "")}rem)`,
          margin: `${settingFormData?.[".testimonial-section"]?.["margin"]
            ?.split(" ")[0]
            ?.replace("px", "")}px auto`,
          "border-radius": `${settingFormData?.[".testimonial-section"]?.[
            "border-radius"
          ]?.replace("rem", "")}rem`,
        }
      : {
          ...settingFormData?.[".testimonial-section"],
          padding: `${settingFormData?.[".testimonial-section"]?.["padding"]
            ?.split(" ")[0]
            ?.replace("px", "")}px 0`,
        },
    ".testimonial-section.bg-image": settingFormData?.[
      ".testimonial-section.bg-image"
    ]?.["background-image"]
      ? {
          ...settingFormData?.[".testimonial-section.bg-image"],
          "background-image": settingFormData?.[
            ".testimonial-section.bg-image"
          ]?.["background-image"]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".testimonial-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".testimonial-section.bg-image::before": settingFormData?.[
      ".testimonial-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".testimonial-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".testimonial-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".testimonial-section .section-title-area": {
      "margin-bottom": `${settingFormData[
        ".testimonial-section .section-title-area"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".testimonial-section .section-title-area .section-subtitle": {
      ...settingFormData[
        ".testimonial-section .section-title-area .section-subtitle"
      ],
      "font-size": `${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-section .section-title-area .section-subtitle"]?.["font-weight"]}`,
      color: settingFormData[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["color"],
          })
        : "",
      "background-color": settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-section .section-title-area .section-subtitle"
              ]?.["background-color"],
          })
        : "",
      padding: `${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["p2"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "margin-bottom": `${settingFormData?.[
        ".testimonial-section .section-title-area .section-subtitle"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".testimonial-section .section-title-area .section-title": {
      ...settingFormData?.[
        ".testimonial-section .section-title-area .section-title"
      ],
      "font-size": `${settingFormData?.[
        ".testimonial-section .section-title-area .section-title"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-section .section-title-area .section-title"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".testimonial-section .section-title-area .section-title"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-section .section-title-area .section-title"
              ]?.["color"],
          })
        : "",
    },
    ".testimonial-slider .slick-arrow::before": {
      ...settingFormData?.[".testimonial-slider .slick-arrow::before"],
      color: settingFormData?.[".testimonial-slider .slick-arrow::before"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".testimonial-slider .slick-arrow::before"]?.[
                "color"
              ],
          })
        : "",
    },
    ".testimonial-slider .testimonial-content": {
      ...settingFormData?.[".testimonial-slider .testimonial-content"],
      "box-shadow": `${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["bs1"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["bs3"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["bs4"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[".testimonial-slider .testimonial-content"]?.[
            "shadowColor"
          ],
      })}`,
      "background-color": settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".testimonial-slider .testimonial-content"]?.[
                "background-color"
              ],
          })
        : "",
      margin: `${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["margin"]?.replace("px", "")}px`,
      padding: `${settingFormData[".testimonial-slider .testimonial-content"]?.[
        "p1"
      ]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["p2"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["p3"]?.replace("px", "")}px ${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["p4"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".testimonial-slider .testimonial-content"
      ]?.["border-radius"]?.replace("px", "")}px`,
      "text-align": `${settingFormData?.[".testimonial-slider .testimonial-content"]?.["text-align"]}`,
    },
    ".testimonial-slider .testimonial-content .avatar-img": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .avatar-img"
      ],
      width: `${settingFormData?.[
        ".testimonial-slider .testimonial-content .avatar-img"
      ]?.["width"]?.replace("px", "")}px`,
      height: `${settingFormData?.[
        ".testimonial-slider .testimonial-content .avatar-img"
      ]?.["height"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .avatar-img"
      ]?.["border-radius"]?.replace("%", "")}%`,
      border: `${settingFormData?.[
        ".testimonial-slider .testimonial-content .avatar-img"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[
          ".testimonial-slider .testimonial-content .avatar-img"
        ]?.["borderThik"]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[
            ".testimonial-slider .testimonial-content .avatar-img"
          ]?.["borderColor"],
      })}`,
    },

    ".testimonial-slider .testimonial-content .comments": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .comments"
      ],
      color: settingFormData?.[
        ".testimonial-slider .testimonial-content .comments"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .testimonial-content .comments"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .comments"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-slider .testimonial-content .comments"]?.["font-weight"]}`,
      "line-height": `${settingFormData?.[".testimonial-slider .testimonial-content .comments"]?.["line-height"]}`,
      margin: `${settingFormData[
        ".testimonial-slider .testimonial-content .comments"
      ]?.["margin"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
    },
    ".testimonial-slider .testimonial-content .client-info": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .client-info"
      ],
      width: `${settingFormData?.[
        ".testimonial-slider .testimonial-content .client-info"
      ]?.["width"]?.replace("%", "")}%`,
      gap: `${settingFormData?.[
        ".testimonial-slider .testimonial-content .client-info"
      ]?.["gap"]?.replace("px", "")}px`,
    },
    ".testimonial-slider .testimonial-content .client-name": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .client-name"
      ],
      color: settingFormData?.[
        ".testimonial-slider .testimonial-content .client-name"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .testimonial-content .client-name"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .client-name"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-slider .testimonial-content .client-name"]?.["font-weight"]}`,
      "margin-bottom": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .client-name"
      ]?.["margin-bottom"]?.replace("px", "")}px`,
    },
    ".testimonial-slider .testimonial-content .client-position": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .client-position"
      ],
      color: settingFormData?.[
        ".testimonial-slider .testimonial-content .client-position"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .testimonial-content .client-position"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .client-position"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".testimonial-slider .testimonial-content .client-position"]?.["font-weight"]}`,
    },
    ".testimonial-slider .testimonial-content .star-rating": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .star-rating"
      ],
      "text-align": `${settingFormData?.[".testimonial-slider .testimonial-content .star-rating"]?.["text-align"]}`,
    },
    ".testimonial-slider .testimonial-content .star-rating i": {
      ...settingFormData?.[
        ".testimonial-slider .testimonial-content .star-rating i"
      ],
      color: settingFormData?.[
        ".testimonial-slider .testimonial-content .star-rating i"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .testimonial-content .star-rating i"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .testimonial-content .star-rating i"
      ]?.["font-size"]?.replace("px", "")}px`,
    },
    ".testimonial-slider .slick-dots li button:before": {
      ...settingFormData?.[".testimonial-slider .slick-dots li button:before"],
      color: settingFormData?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["color"]
        ? `${rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .slick-dots li button:before"
              ]?.["color"],
          })} !important`
        : "",
      "font-size": `${settingFormData?.[
        ".testimonial-slider .slick-dots li button:before"
      ]?.["font-size"]?.replace("px", "")}px !important`,
      opacity: `${settingFormData?.[".testimonial-slider .slick-dots li button:before"]?.["opacity"]} !important`,
    },
    ".testimonial-slider .slick-dots li.slick-active button:before": {
      color: settingFormData?.[
        ".testimonial-slider .slick-dots li.slick-active button:before"
      ]?.["color"]
        ? `${rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".testimonial-slider .slick-dots li.slick-active button:before"
              ]?.["color"],
          })} !important`
        : "",
      opacity: "1 !important",
    },
  };
  return newData;
};
export const TestimonialTwoSubFun = ({ settingFormData, boxSection }) => {
  let newData = {
    ...settingFormData,
    ".zig-zag-section": {
      ...settingFormData?.[".zig-zag-section"],
      padding: `${settingFormData?.[".zig-zag-section"]?.["padding"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
      width: `calc(100% - ${settingFormData?.[".zig-zag-section"]?.[
        "width"
      ]?.replace("rem)", "")}rem)`,
    },
    ".zig-zag-section.bg-image": settingFormData?.[
      ".zig-zag-section.bg-image"
    ]?.["background-image"]
      ? {
          ...settingFormData?.[".zig-zag-section.bg-image"],
          "background-image": settingFormData?.[".zig-zag-section.bg-image"]?.[
            "background-image"
          ]
            ? addUrlImage({
                urlString: removeUrlImage({
                  urlString:
                    settingFormData[".zig-zag-section.bg-image"][
                      "background-image"
                    ],
                }),
              })
            : "",
        }
      : {},
    ".zig-zag-section.bg-image::before": settingFormData?.[
      ".zig-zag-section.bg-image::before"
    ]?.["background-color"]
      ? {
          "background-color": settingFormData?.[
            ".zig-zag-section.bg-image::before"
          ]?.["background-color"]
            ? rgbColorCreate({
                rgbString:
                  settingFormData?.[".zig-zag-section.bg-image::before"]?.[
                    "background-color"
                  ],
              })
            : "",
        }
      : {},
    ".zig-zag-section .testimonial-divs .testimonial": {
      ...settingFormData?.[".zig-zag-section .testimonial-divs .testimonial"],
      padding: `${settingFormData[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["padding"]?.replace("px", "")}px`,
      "border-radius": `${settingFormData[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["border-radius"]?.replace("px", "")}px`,
      border: `${settingFormData?.[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[".zig-zag-section .testimonial-divs .testimonial"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[
            ".zig-zag-section .testimonial-divs .testimonial"
          ]?.["borderColor"],
      })}`,
      "box-shadow": `${settingFormData?.[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["bs1"]?.replace("px", "")}px ${settingFormData?.[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["bs2"]?.replace("px", "")}px ${settingFormData?.[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["bs3"]?.replace("px", "")}px ${rgbColorCreate({
        rgbString:
          settingFormData?.[
            ".zig-zag-section .testimonial-divs .testimonial"
          ]?.["shadowColor"],
      })}`,
      "background-color": settingFormData?.[
        ".zig-zag-section .testimonial-divs .testimonial"
      ]?.["background-color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".zig-zag-section .testimonial-divs .testimonial"
              ]?.["background-color"],
          })
        : "",
    },
    ".zig-zag-section .testimonial-divs .avatar-img": {
      ...settingFormData[".zig-zag-section .testimonial-divs .avatar-img"],
      "border-radius": `${settingFormData?.[
        ".zig-zag-section .testimonial-divs .avatar-img"
      ]?.["border-radius"]?.replace("%", "")}%`,
      border: `${settingFormData?.[
        ".zig-zag-section .testimonial-divs .avatar-img"
      ]?.["borderPx"]?.replace("px", "")}px ${
        settingFormData?.[".zig-zag-section .testimonial-divs .avatar-img"]?.[
          "borderThik"
        ]
      } ${rgbColorCreate({
        rgbString:
          settingFormData?.[".zig-zag-section .testimonial-divs .avatar-img"]?.[
            "borderColor"
          ],
      })}`,
    },
    ".zig-zag-section .testimonial-divs .client-name": {
      ...settingFormData?.[".zig-zag-section .testimonial-divs .client-name"],
      "font-size": `${settingFormData?.[
        ".zig-zag-section .testimonial-divs .client-name"
      ]?.["font-size"]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[
        ".zig-zag-section .testimonial-divs .client-name"
      ]?.["line-height"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".zig-zag-section .testimonial-divs .client-name"]?.["font-weight"]}`,
      color: settingFormData?.[
        ".zig-zag-section .testimonial-divs .client-name"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".zig-zag-section .testimonial-divs .client-name"
              ]?.["color"],
          })
        : "",
    },
    ".zig-zag-section .testimonial-divs .client-position": {
      ...settingFormData?.[
        ".zig-zag-section .testimonial-divs .client-position"
      ],
      color: settingFormData?.[
        ".zig-zag-section .testimonial-divs .client-position"
      ]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[
                ".zig-zag-section .testimonial-divs .client-position"
              ]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".zig-zag-section .testimonial-divs .client-position"
      ]?.["font-size"]?.replace("px", "")}px`,
      "font-weight": `${settingFormData?.[".zig-zag-section .testimonial-divs .client-position"]?.["font-weight"]}`,
    },

    ".zig-zag-section .testimonial-divs .desq": {
      ...settingFormData?.[".zig-zag-section .testimonial-divs .desq"],
      color: settingFormData?.[".zig-zag-section .testimonial-divs .desq"]?.[
        "color"
      ]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".zig-zag-section .testimonial-divs .desq"]?.[
                "color"
              ],
          })
        : "",
      "font-size": `${settingFormData?.[
        ".zig-zag-section .testimonial-divs .desq"
      ]?.["font-size"]?.replace("px", "")}px`,
      "line-height": `${settingFormData?.[".zig-zag-section .testimonial-divs .desq"]?.["line-height"]}`,
      "margin-bottom": `${settingFormData?.[
        ".zig-zag-section .testimonial-divs .desq"
      ]?.["margin-bottom"]
        ?.split(" ")[0]
        ?.replace("px", "")}px 0`,
    },
    ".zig-zag-section .star-rating i": {
      ...settingFormData?.[".zig-zag-section .star-rating i"],
      color: settingFormData?.[".zig-zag-section .star-rating i"]?.["color"]
        ? rgbColorCreate({
            rgbString:
              settingFormData?.[".zig-zag-section .star-rating i"]?.["color"],
          })
        : "",
      "font-size": `${settingFormData?.[".zig-zag-section .star-rating i"]?.[
        "font-size"
      ]?.replace("px", "")}px`,
    },
  };
  return newData;
};
