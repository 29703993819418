import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import OpenPopupBtn from "../../../common/OpenPopupBtn";
import { Cases, DELETE_CONFIRM, modalsName } from "../../../constant";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import CardBuilderHeaderSection from "../../../common/cardBuilderHeaderSection";
import CommonPopup from "../../../Modals/commonPopup";
import ImageBannerPopup from "./ImageBannerPopup";
import EditIcon from "@mui/icons-material/Edit";
import ImageBannerParentPopup from "./ImageBannerParentPopup";
import { FILE_UPLOAD_API } from "../apis/SliderApi/SliderApi";
import {
  addUrlImage,
  convertRGBA,
  getRandomNumber,
  isCheckValue,
  isEmptyObject,
  randomStr,
  removeUrlImage,
  removeUrlKeyInPath,
  rgbColorCreate,
} from "../../../../utils/helper";
import { FILE_TYPE, ThemeNo, titleUpdateKey } from "../../../../constant";
import { IconButton } from "@material-ui/core";
import { Trash } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import SimpleReactValidator from "simple-react-validator";
import ImageBannerContext from "../../../context/ImageBannerContext/ImageBannerContext";
import {
  deleteImageBanner,
  getImageBanner,
  getImageBannerDetails,
  postImageBanner,
  putUpdateImageBanner,
  updateStatus,
} from "../apis/ImageBannerApi/ImageBannerApi";
import SettingImagesBannerParentPopup from "./SettingImagesBannerParentPopup";
import DeleteSection from "../../../common/DeleteSection";
import SettingMultiWebContext from "../../../../context/SettingMultiWebContext/SettingMultiWebContext";

const ImageBanner = (props) => {
  const {
    pageConfigDetails,
    webMasterDetail,
    navbarData,
    getData,
    imageBannerDetail,
    contentMapId,
    ByteMiniWeb_Dashboard,
    contentDetail,
  } = props;
  const { allPopupState, setAllPopupState } = useContext(
    ByteCardBuilderContext
  );
  const { setCloneAlignModal, setAlignFormData, setCloneFormData } = useContext(
    SettingMultiWebContext
  );
  const parentValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const childValidator = useRef(new SimpleReactValidator());
  const {
    fromData,
    setFormData,
    formDataChild,
    setFormDataChild,
    id,
    setId,
    setIsEdit,
    isEdit,
    setFilter,
    apiDataOurTeam: apiDataImageParent,
    setApiDataOurTeam: setApiDataImageParent,
    setSkill,
    setGalleryImgId,
    setSettingFormData,
    settingFormData,
    isParent,
    setIsParent,
    childId,
    setChildId,
    navMapId,
    setNavMapId,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    collId,
    setCollId,
    isBackgroundColor,
    setIsBackgroundColor,
    countOfList,
    setCountOfList,
    setTheme,
    setParentId,
    theme,
    setImageBannerId,
    mainThemeModal,
    setMainThemeModal,
    setImgShow,
    sectionPageName,
    setSectionPageName,
  } = useContext(ImageBannerContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const [childIdDelete, setChildIdDelete] = useState();
  const [mainParentId, setMainParentId] = useState();

  /**
   * get image banner parent list
   * @param {status} status
   */
  const getImageBannerParentList = async ({
    parentId = null,
    isClose = false,
    filter,
  }) => {
    try {
      let paramObj = {};
      if (
        ((parentId && parentId !== null) ||
          (id && isClose === false && isParent === false)) &&
        isParent === false
      ) {
        paramObj["search"] = { parentId: parentId || id, contentMapId };
        setId(parentId || id);
        setMainParentId(parentId);
        if (filter?.length) {
          paramObj["search"] = { ...paramObj.search, title: filter };
        }
      } else {
        paramObj["search"] = { parentId: null, contentMapId };
      }
      const { data } = await getImageBanner(paramObj);
      if (isCheckValue({ value: parentId })) {
        setChildId(parentId);
        setChildIdDelete(parentId);
      }
      if (data.status === 200) {
        if (
          isParent === false &&
          ((parentId && parentId !== null) || id) &&
          isClose === false
        ) {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.imageBannerId,
                Id: elem?.imageBannerId,
                name: elem?.title,
                image: elem?.imageLink,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
          OpenModalCommonFunc(Cases.IMAGE_BANNER, setAllPopupState);
        } else if (parentId && parentId !== null) {
          OpenModalCommonFunc(Cases.IMAGE_BANNER, setAllPopupState);
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data.map((elem) => {
              return {
                id: elem?.title + elem?.imageBannerId,
                Id: elem?.imageBannerId,
                name: elem?.title,
                image: elem?.imageLink,
                webId: elem?.webId,
                createdAt: elem?.createdAt,
                isActive: elem?.isActive,
                parentId: elem?.parentId,
              };
            }),
          });
        } else {
          setApiDataImageParent({
            ...apiDataImageParent,
            isLoading: false,
            data: data.payload.data,
          });
        }
        if (isClose && isClose !== false) {
          setIsParent(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * item image banner enable disable api call
   * @param {imageBannerId} imageBannerId
   * @param {status} isActive
   */

  const handleChangeImageBanner = async (
    imageBannerId,
    isActive,
    isChild,
    parentId
  ) => {
    try {
      let body = {
        imageBannerId,
        isActive: isActive ? false : true,
        parentId,
      };
      const { data } = await updateStatus(body);
      if (data.status === 200) {
        if (isChild) {
          getImageBannerParentList({ parentId: id });
        } else {
          ByteMiniWeb_Dashboard();
        }
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * file upload
   * @param {file} file
   */
  const imageUploadImages = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setFormDataChild((prev) => {
        return {
          ...prev,
          imageLink: Image,
          image: Image,
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUploadSetting = async (file) => {
    setLoaderShow(true);
    try {
      const Image = await imageUpload(file);
      setSettingFormData((prev) => {
        return {
          ...prev,
          [`.call-action-section${countOfList}.bg-image`]: {
            "background-image": Image,
          },
          imageLink: Image,
        };
      });
      setLoaderShow(false);
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  const imageUpload = async (file) => {
    let formData = new FormData();
    formData.append("type", FILE_TYPE.IMAGE_BANNER);
    formData.append("file", file);
    try {
      const { data } = await FILE_UPLOAD_API(formData);
      if (data.status === 200) {
        return data.payload.baseUrl + data.payload.imageUrl;
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  useEffect(() => {
    let countOfListValue = getRandomNumber();
    setCountOfList(countOfListValue);
    setSettingFormData({
      [`.call-action-section${countOfListValue}`]: {
        padding: "80px 0",
        "background-color": "rgb(33 37 41 / 100)",
      },
      [`.call-action-section${countOfListValue}.bg-image`]: {
        "background-image": "",
      },
      [`.call-action-section${countOfListValue}.bg-image::before`]: {
        "background-color": "",
      },
      [`.call-action-section${countOfListValue} .btn-pink`]: {
        "background-color": "rgb(255 73 124 / 100)",
        border: "2px solid rgb(255 73 124 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
        padding: "8px 25px",
        p1: "8px",
        p2: "25px",
      },
      imageBannerId: "",
    });
  }, [apiDataImageParent]);

  const submitStyleValue = (check) => {
    let countOfListValue;
    if (check === "new") {
      countOfListValue = countOfList;
    } else {
      countOfListValue = settingFormData?.countOfList;
    }
    let newData = {
      ...settingFormData,
      theme: theme,
      countOfList: countOfListValue,
      imageBannerId: settingFormData?.settingFormData ?? "",
      [`.call-action-section${countOfListValue}`]: boxSection
        ? {
            padding: `${settingFormData?.[
              `.call-action-section${countOfListValue}`
            ]?.["padding"]
              ?.split(" ")[0]
              ?.replace("px", "")}px 0`,
            width: `calc(100% - ${settingFormData?.[
              `.call-action-section${countOfListValue}`
            ]?.["width"]?.replace("rem)", "")}rem)`,
            margin: `${settingFormData?.[
              `.call-action-section${countOfListValue}`
            ]?.["margin"]
              ?.split(" ")[0]
              ?.replace("px", "")}px auto`,
            "border-radius": `${settingFormData?.[
              `.call-action-section${countOfListValue}`
            ]?.["border-radius"]?.replace("rem", "")}rem`,
          }
        : {
            ...settingFormData?.[`.call-action-section${countOfListValue}`],
            padding: `${settingFormData?.[
              `.call-action-section${countOfListValue}`
            ]?.["padding"]
              ?.split(" ")[0]
              ?.replace("px", "")}px 0`,
          },
      [`.call-action-section${countOfListValue}.bg-image`]: settingFormData?.[
        `.call-action-section${countOfListValue}.bg-image`
      ]?.["background-image"]
        ? {
            ...settingFormData?.[
              `.call-action-section${countOfListValue}.bg-image`
            ],
            "background-image": settingFormData?.[
              `.call-action-section${countOfListValue}.bg-image`
            ]?.["background-image"]
              ? addUrlImage({
                  urlString: removeUrlImage({
                    urlString:
                      settingFormData[
                        `.call-action-section${countOfListValue}.bg-image`
                      ]["background-image"],
                  }),
                })
              : "",
            "background-repeat": "no-repeat",
            "background-size": "cover",
          }
        : {},
      [`.call-action-section${countOfListValue}.bg-image::before`]:
        settingFormData?.[
          `.call-action-section${countOfListValue}.bg-image::before`
        ]?.["background-color"]
          ? {
              "background-color": settingFormData?.[
                `.call-action-section${countOfListValue}.bg-image::before`
              ]?.["background-color"]
                ? rgbColorCreate({
                    rgbString:
                      settingFormData[
                        `.call-action-section${countOfListValue}.bg-image::before`
                      ]["background-color"],
                  })
                : "",
            }
          : {},
      [`.call-action-section${countOfListValue} .btn-pink`]: {
        "background-color": settingFormData?.[
          `.call-action-section${countOfListValue} .btn-pink`
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.call-action-section${countOfListValue} .btn-pink`
                ]["background-color"],
            })
          : "",
        border: `${settingFormData?.[
          `.call-action-section${countOfListValue} .btn-pink`
        ]?.["borderPx"]?.replace("px", "")}px ${
          settingFormData?.[
            `.call-action-section${countOfListValue} .btn-pink`
          ]?.["borderThik"]
        } ${rgbColorCreate({
          rgbString:
            settingFormData[
              `.call-action-section${countOfListValue} .btn-pink`
            ]["borderColor"],
        })}`,
        borderColor: settingFormData?.[
          `.call-action-section${countOfListValue} .btn-pink`
        ]?.["borderColor"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.call-action-section${countOfListValue} .btn-pink`
                ]["borderColor"],
            })
          : "",
        color: settingFormData?.[
          `.call-action-section${countOfListValue} .btn-pink`
        ]?.["color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[
                  `.call-action-section${countOfListValue} .btn-pink`
                ]["color"],
            })
          : "",
        padding: `${settingFormData?.[
          `.call-action-section${countOfListValue} .btn-pink`
        ]?.["p1"]?.replace("px", "")}px ${settingFormData?.[
          `.call-action-section${countOfListValue} .btn-pink`
        ]?.["p2"]?.replace("px", "")}px`,
      },
    };
    if (isBackgroundColor) {
      newData[`.call-action-section${countOfListValue}`] = {
        ...newData?.[`.call-action-section${countOfListValue}`],
        "background-color": settingFormData?.[
          `.call-action-section${countOfListValue}`
        ]?.["background-color"]
          ? rgbColorCreate({
              rgbString:
                settingFormData[`.call-action-section${countOfListValue}`][
                  "background-color"
                ],
            })
          : "",
      };
    }
    if (boxSection) {
      newData[`.call-action-section${countOfListValue}.bg-image::before`] = {
        ...newData[`.call-action-section${countOfListValue}.bg-image::before`],
        "border-radius": `${settingFormData[
          `.call-action-section${countOfListValue}`
        ]["border-radius"]?.replace("rem", "")}rem`,
      };
    }

    return newData;
  };

  /**
   * create and update image banner
   */
  const submitForm = async () => {
    if (
      (isParent === false && childId >= -1 && isEdit) ||
      (id >= -1 && isEdit)
    ) {
      setLoaderShow(true);
      try {
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, contentMapId: collId };
          delete formData["style"];
        } else {
          formData = {
            ...formDataChild,
            navMapId,
            imageBannerId: childId,
            contentMapId: collId,
            // style: await submitStyleValue(),
          };
          delete formData.image;
          delete formData.imageLink;
          if (formDataChild.imageLink.length) {
            formData["imageLink"] = formDataChild.imageLink
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }
        }
        const { data } = await putUpdateImageBanner(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getImageBannerParentList({ parentId: childIdDelete });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.IMAGE_BANNER_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    } else {
      try {
        setLoaderShow(true);
        let formData = {};
        if (isParent && isParent !== false) {
          formData = { ...fromData, contentMapId: collId };
        } else {
          formData = {
            ...formDataChild,
            parentId: mainParentId,
            navMapId,
            order: apiDataImageParent.data.length + 1,
            contentMapId: collId,
            theme: theme,
            style: await submitStyleValue("new"),
          };
          delete formData.image;
          delete formData.imageLink;
          if (formDataChild.imageLink.length) {
            formData["imageLink"] = formDataChild.imageLink
              .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER}/`)
              .pop();
          }
        }
        const { data } = await postImageBanner(formData);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            ByteMiniWeb_Dashboard();
          } else {
            getImageBannerParentList({ parentId: childIdDelete });
          }
          emptyData();
          OpenModalCommonFunc(
            Cases.IMAGE_BANNER_PARENT,
            setAllPopupState,
            false
          );
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };
  const handleSubmit = async (formValidationCondition) => {
    let validatorFields;
    if (formValidationCondition === "parentForm") {
      validatorFields = parentValidator.current;
    } else if (formValidationCondition === "childForm") {
      validatorFields = childValidator.current;
    }
    validatorFields.showMessages();

    if (validatorFields.allValid()) {
      try {
        // Process form data based on the formType
        if (formValidationCondition === "parentForm") {
          submitForm();
        } else if (formValidationCondition === "childForm") {
          submitForm();
        }
        validatorFields.hideMessages();
      } catch (error) {
        // Handle error for form submission
      }
    } else {
      // Force update to display validation messages
      forceUpdate({});
    }
  };

  /**
   * create and update image banner
   */
  const handleSubmitSetting = async (isRestore = false, isClose = true) => {
    if (isEdit) {
      setLoaderShow(true);
      try {
        let newData;
        if (isRestore) {
          const isConfirm = window.confirm(
            "Are you sure want to restore style ?"
          );
          if (isConfirm) {
            newData = {
              styleRestore: true,
              imageBannerId: settingFormData?.imageBannerId,
              parentId: settingFormData?.parentId,
            };
          } else {
            setLoaderShow(false);
            return;
          }
        } else {
          newData = await submitStyleValue();
          const keysToRemove = ["borderPx", "borderThik", "p1", "p2", "image"];
          function removeKeys(obj, keys) {
            for (const key in obj) {
              if (typeof obj[key] === "object") {
                removeKeys(obj[key], keys);
              } else if (keys.includes(key)) {
                delete obj[key];
              }
            }
          }
          removeKeys(newData, keysToRemove);
          newData = {
            style: {
              ...newData,
              childId,
            },
            imageBannerId: settingFormData?.imageBannerId,
            parentId: settingFormData.parentId,
          };
          delete newData.style.parentId;
          delete newData.style.imageLink;
        }

        const { data } = await putUpdateImageBanner(newData);
        if (data.status === 200) {
          if (isClose) {
            OpenModalCommonFunc(
              Cases.SETTING_IMAGE_BANNER_PARENT,
              setAllPopupState,
              false
            );
            setBackImageToggle(false);
          }
          setLoaderShow(false);
          TostMessage({ message: data, type: "success" });
        }
      } catch (error) {
        TostMessage({ message: error, type: "error" });
        setLoaderShow(false);
      }
    }
  };

  /**
   * get data image banner
   * @param {id} imageBannerId
   */
  const handleEditImageBanner = async (imageBannerId, isParent) => {
    try {
      setLoaderShow(true);
      const { data } = await getImageBannerDetails(imageBannerId);
      if (data.status === 200) {
        let payload = data.payload.data;
        if (isParent) {
          setSectionPageName(payload?.title);
          setId(imageBannerId);
          setIsParent(true);
          setFormData({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            navMapId: payload?.navMapId || "",
            theme: payload?.theme || 1,
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            imageBannerId: payload?.imageBannerId || "",
            webId: localStorage.getItem("webId"),
            style: payload?.style || {
              [`.call-action-section${countOfList}`]: {
                padding: "80px 0",
                "background-color": { r: 33, g: 37, b: 41, a: 100 },
              },
              [`.call-action-section${countOfList}.bg-image`]: {
                "background-image": "",
              },
              [`.call-action-section${countOfList}.bg-image::before`]: {
                "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
              },
              [`.call-action-section${countOfList} .btn-pink`]: {
                "background-color": { r: 255, g: 73, b: 124, a: 100 },
                border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
                borderPx: "2px",
                borderThik: "solid",
                borderColor: { r: 255, g: 73, b: 124, a: 100 },
                color: { r: 255, g: 255, b: 255, a: 100 },
                padding: "8px 25px",
                p1: "8px",
                p2: "25px",
              },
            },
          });
          OpenModalCommonFunc(Cases.IMAGE_BANNER_PARENT, setAllPopupState);
        } else {
          setChildId(imageBannerId);
          setIsParent(false);
          setFormDataChild({
            title: payload?.title || "",
            subTitle: payload?.subTitle || "",
            image: payload?.imageLink || "",
            imageLink: payload?.imageLink || "",
            buttonText: payload?.buttonText || "",
            buttonValue: payload?.buttonValue || "",
            description: payload?.description || "",
            isTitleShow: payload?.isTitleShow ? payload?.isTitleShow : false,
            parentId: payload?.parentId || "",
            order: payload?.order || 1,
            webId: localStorage.getItem("webId"),
          });
          OpenModalCommonFunc(Cases.IMAGE_BANNER, setAllPopupState);
        }
        setIsEdit(true);
        TostMessage({ message: data, type: "success" });
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * get setting data
   * @param {id} imageBannerId
   */
  const handleChangeSettings = async (imageBannerId, isThemeEdit = false) => {
    try {
      setLoaderShow(true);
      setId(imageBannerId);
      setSettingFormData((prev) => {
        return {
          ...prev,
          imageBannerId,
        };
      });
      setChildId(imageBannerId);
      const { data } = await getImageBannerDetails(imageBannerId);
      if (data.status === 200) {
        let payload = data.payload.data;
        const themeCount = data?.payload?.data?.theme;
        setTheme(themeCount);
        setImgShow(true);
        setParentId(data?.payload?.data?.parentId);
        if (
          (+theme === ThemeNo.ThemeOne &&
            isEmptyObject(data?.payload?.data?.style)) ||
          (+theme !== ThemeNo.ThemeOne &&
            isEmptyObject(data?.payload?.data?.style))
        ) {
          let countOfListValue = getRandomNumber();
          // let countOfListValue =
          //   +apiDataImageParent.data[apiDataImageParent.data.length - 1]?.Id +
          //   1;
          setCountOfList(countOfListValue);
          setSettingFormData({
            parentId: payload.parentId,
            countOfList: countOfListValue,
            imageBannerId: payload.imageBannerId,
            [`.call-action-section${countOfListValue}`]: {
              padding: "80px 0",
              "background-color": { r: 33, g: 37, b: 41, a: 100 },
            },
            [`.call-action-section${countOfListValue}.bg-image`]: {},
            [`.call-action-section${countOfListValue}.bg-image::before`]: {
              // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
            },
            [`.call-action-section${countOfListValue} .btn-pink`]: {
              "background-color": { r: 255, g: 73, b: 124, a: 100 },
              border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
              borderPx: "2px",
              borderThik: "solid",
              borderColor: { r: 255, g: 73, b: 124, a: 100 },
              color: { r: 255, g: 255, b: 255, a: 100 },
              padding: "8px 25px",
              p1: "8px",
              p2: "25px",
            },
            custom: "",
          });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else if (
          (+theme === ThemeNo.ThemeTwo &&
            isEmptyObject(data?.payload?.data?.style)) ||
          (+theme !== ThemeNo.ThemeTwo &&
            isEmptyObject(data?.payload?.data?.style))
        ) {
          let countOfListValue = getRandomNumber();
          setCountOfList(countOfListValue);
          setSettingFormData({
            parentId: payload.parentId,
            countOfList: countOfListValue,
            imageBannerId: payload.imageBannerId,
            [`.call-action-section${countOfListValue}`]: {
              padding: "80px 0",
              "background-color": { r: 33, g: 37, b: 41, a: 100 },
            },
            [`.call-action-section${countOfListValue}.bg-image`]: {},
            [`.call-action-section${countOfListValue}.bg-image::before`]: {
              // "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
            },
            [`.call-action-section${countOfListValue} .btn-pink`]: {
              "background-color": { r: 255, g: 73, b: 124, a: 100 },
              border: "2px solid { r: 255, g: 73, b: 124, a: 100 }",
              borderPx: "2px",
              borderThik: "solid",
              borderColor: { r: 255, g: 73, b: 124, a: 100 },
              color: { r: 255, g: 255, b: 255, a: 100 },
              padding: "8px 25px",
              p1: "8px",
              p2: "25px",
            },
            custom: "",
          });
          setBoxSection(false);
          setBackImageToggle(false);
          setIsBackgroundColor(false);
        } else {
          setCountOfList(payload?.style?.countOfList);
          if (
            payload.style?.[
              `.call-action-section${payload?.style?.countOfList}`
            ]?.["width"] ||
            payload.style?.[
              `.call-action-section${payload?.style?.countOfList}`
            ]?.["margin"] ||
            payload.style?.[
              `.call-action-section${payload?.style?.countOfList}`
            ]?.["border-radius"]
          ) {
            setBoxSection(true);
          } else {
            setBoxSection(false);
          }
          if (
            payload.style?.[
              `.call-action-section${payload?.style?.countOfList}.bg-image`
            ]?.["background-image"]
          ) {
            setBackImageToggle(true);
          } else {
            setBackImageToggle(false);
          }
          if (
            payload.style?.[
              `.call-action-section${payload?.style?.countOfList}`
            ]?.["background-color"]
          ) {
            setIsBackgroundColor(true);
          } else {
            setIsBackgroundColor(false);
          }
          setSettingFormData((prev) => {
            return {
              parentId: payload.parentId,
              countOfList: payload?.style?.countOfList,
              imageBannerId: payload.imageBannerId,
              [`.call-action-section${payload?.style?.countOfList}`]: {
                padding:
                  payload.style?.[
                    `.call-action-section${payload?.style?.countOfList}`
                  ]?.["padding"],
                "background-color": payload.style?.[
                  `.call-action-section${payload?.style?.countOfList}`
                ]?.["background-color"]
                  ? convertRGBA({
                      rgbString:
                        payload.style?.[
                          `.call-action-section${payload?.style?.countOfList}`
                        ]?.["background-color"],
                    })
                  : "",
                width: payload.style?.[
                  `.call-action-section${payload?.style?.countOfList}`
                ]?.["width"]?.replace("calc(100% - ", ""),
                margin:
                  payload.style?.[
                    `.call-action-section${payload?.style?.countOfList}`
                  ]?.["margin"],
                "border-radius":
                  payload.style?.[
                    `.call-action-section${payload?.style?.countOfList}`
                  ]?.["border-radius"],
              },
              [`.call-action-section${payload?.style?.countOfList}.bg-image`]: {
                "background-image": payload.style?.[
                  `.call-action-section${payload?.style?.countOfList}.bg-image`
                ]?.["background-image"]
                  ? removeUrlKeyInPath({
                      urlString:
                        payload.style?.[
                          `.call-action-section${payload?.style?.countOfList}.bg-image`
                        ]?.["background-image"],
                    })
                  : "",
              },
              [`.call-action-section${payload?.style?.countOfList}.bg-image::before`]:
                {
                  "background-color": payload.style?.[
                    `.call-action-section${payload?.style?.countOfList}.bg-image::before`
                  ]?.["background-color"]
                    ? convertRGBA({
                        rgbString:
                          payload.style?.[
                            `.call-action-section${payload?.style?.countOfList}.bg-image::before`
                          ]?.["background-color"],
                      })
                    : "",
                },
              [`.call-action-section${payload?.style?.countOfList} .btn-pink`]:
                {
                  ...settingFormData[
                    `.call-action-section${payload?.style?.countOfList} .btn-pink`
                  ],
                  "background-color": payload.style?.[
                    `.call-action-section${payload?.style?.countOfList} .btn-pink`
                  ]?.["background-color"]
                    ? convertRGBA({
                        rgbString:
                          payload.style?.[
                            `.call-action-section${payload?.style?.countOfList} .btn-pink`
                          ]?.["background-color"],
                      })
                    : "",
                  border:
                    payload.style?.[
                      `.call-action-section${payload?.style?.countOfList} .btn-pink`
                    ]?.["border"],
                  borderPx:
                    payload.style?.[
                      `.call-action-section${payload?.style?.countOfList} .btn-pink`
                    ]?.["border"]?.split(" ")[0],
                  borderThik:
                    payload.style?.[
                      `.call-action-section${payload?.style?.countOfList} .btn-pink`
                    ]?.["border"]?.split(" ")[1],
                  borderColor: payload.style?.[
                    `.call-action-section${payload?.style?.countOfList} .btn-pink`
                  ]?.["borderColor"]
                    ? convertRGBA({
                        rgbString:
                          payload.style?.[
                            `.call-action-section${payload?.style?.countOfList} .btn-pink`
                          ]?.["borderColor"],
                      })
                    : "",
                  color: payload.style?.[
                    `.call-action-section${payload?.style?.countOfList} .btn-pink`
                  ]?.["color"]
                    ? convertRGBA({
                        rgbString:
                          payload.style?.[
                            `.call-action-section${payload?.style?.countOfList} .btn-pink`
                          ]?.["color"],
                      })
                    : "",
                  padding:
                    payload.style?.[
                      `.call-action-section${payload?.style?.countOfList} .btn-pink`
                    ]?.["padding"],
                  p1: payload.style?.[
                    `.call-action-section${payload?.style?.countOfList} .btn-pink`
                  ]?.["padding"]?.split(" ")[0],
                  p2: payload.style?.[
                    `.call-action-section${payload?.style?.countOfList} .btn-pink`
                  ]?.["padding"]?.split(" ")[1],
                },
              custom: payload?.style?.custom,
            };
          });
        }
        if (!isThemeEdit) {
          OpenModalCommonFunc(
            Cases.SETTING_IMAGE_BANNER_PARENT,
            setAllPopupState
          );
          TostMessage({ message: data, type: "success" });
        }
        setIsEdit(true);
        setLoaderShow(false);
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * delete image banner
   * @param {id} imageBannerId
   */
  const handleDelete = async (imageBannerId, isParent = false) => {
    let conformation = window.confirm(DELETE_CONFIRM);
    try {
      if (conformation) {
        setLoaderShow(true);
        const { data } = await deleteImageBanner(imageBannerId);
        if (data.status === 200) {
          if (isParent && isParent !== false) {
            // getImageBannerParentList(null, true);
            ByteMiniWeb_Dashboard();
          } else {
            getImageBannerParentList({ parentId: childIdDelete });
          }
          TostMessage({ message: data, type: "success" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  /**
   * clear data
   */

  const emptyData = () => {
    let myCount = getRandomNumber();
    setIsEdit(false);
    setCountOfList(myCount);
    setIsParent(false);
    setChildId("");
    setFormData({
      title: "",
      navMapId: "",
      theme: 1,
      imageBannerId: "",
      isTitleShow: true,
      webId: localStorage.getItem("webId"),
      style: {
        [`.call-action-section${myCount}`]: {
          padding: "80px 0",
          "background-color": "rgb(33 37 41 / 100)",
        },
        [`.call-action-section${myCount}.bg-image`]: {
          "background-image": "",
        },
        [`.call-action-section${myCount}.bg-image::before`]: {
          "background-color": "",
        },
        [`.call-action-section${myCount} .btn-pink`]: {
          "background-color": "rgb(255 73 124 / 100)",
          border: "2px solid rgb(255 73 124 / 100)",
          borderPx: "2px",
          borderThik: "solid",
          borderColor: "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
          padding: "8px 25px",
          p1: "8px",
          p2: "25px",
        },
      },
    });
    setSkill([{ id: randomStr(2), skill: "", parentageOfSkill: "" }]);
    setGalleryImgId("");
    setFormDataChild({
      title: "",
      subTitle: "",
      imageLink: "",
      image: "",
      buttonText: "",
      isTitleShow: true,
      buttonValue: "",
      description: "",
      order: 1,
      webId: localStorage.getItem("webId"),
      navMapId: "",
      style: {
        [`.call-action-section${myCount}`]: {
          padding: "80px 0",
          "background-color": "rgb(33 37 41 / 100)",
        },
        [`.call-action-section${myCount}.bg-image`]: {
          "background-image": "",
        },
        [`.call-action-section${myCount}.bg-image::before`]: {
          "background-color": "",
        },
        [`.call-action-section${myCount} .btn-pink`]: {
          "background-color": "rgb(255 73 124 / 100)",
          border: "2px solid rgb(255 73 124 / 100)",
          borderPx: "2px",
          borderThik: "solid",
          borderColor: "rgb(255 73 124 / 100)",
          color: "rgb(255 255 255 / 100)",
          padding: "8px 25px",
          p1: "8px",
          p2: "25px",
        },
      },
    });
    setLoaderShow(false);
    setSettingFormData({
      [`.call-action-section${myCount}`]: {
        padding: "80px 0",
        "background-color": "rgb(33 37 41 / 100)",
      },
      [`.call-action-section${myCount}.bg-image`]: {
        "background-image": "",
      },
      [`.call-action-section${myCount}.bg-image::before`]: {
        "background-color": "",
      },
      [`.call-action-section${myCount} .btn-pink`]: {
        "background-color": "rgb(255 73 124 / 100)",
        border: "2px solid rgb(255 73 124 / 100)",
        borderPx: "2px",
        borderThik: "solid",
        borderColor: "rgb(255 73 124 / 100)",
        color: "rgb(255 255 255 / 100)",
        padding: "8px 25px",
        p1: "8px",
        p2: "25px",
      },
    });
  };

  const value = useMemo(() => {
    let [label] = navbarData.filter(
      (elem) => elem.navbarDetail.label === titleUpdateKey.Certificates
    );
    return label;
  }, [navbarData]);

  const handleAlignAndCloneSection = ({ isClone = false }) => {
    if (isClone) {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          cloneModal: true,
        };
      });
      setCloneFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          pageConfigDetailsLabel: value?.label || pageConfigDetails[0]?.label,
          contentDetailName: contentDetail.name,
        };
      });
    } else {
      setCloneAlignModal((prev) => {
        return {
          ...prev,
          alignModal: true,
        };
      });
      setAlignFormData((prev) => {
        return {
          ...prev,
          contentMapId,
          label: value?.label || pageConfigDetails[0]?.label,
        };
      });
    }
  };
  return (
    <>
      <Col xl="12 box-col-6" md="12">
        <Card className="ecommerce-widget mt-4 cardHover">
          <CardBody
            className="support-ticket-font box-border-div"
            style={{ padding: "20px" }}
          >
            <Row>
              <CardBuilderHeaderSection
                addBtnTitle={pageConfigDetails[0].label}
                name={value?.label || pageConfigDetails[0]?.label}
                isAdd={!imageBannerDetail && true}
                mainEditSection={true}
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
                openModal={() => {
                  OpenModalCommonFunc(
                    Cases.IMAGE_BANNER_PARENT,
                    setAllPopupState
                  );
                  setSectionPageName(modalsName.IMAGE_BANNER);
                  emptyData();
                  setIsParent(true);
                  setCollId(contentMapId);
                }}
                isCloneSection={true}
                isAlignSection={true}
                handleAlignAndCloneSection={handleAlignAndCloneSection}
              />
              {imageBannerDetail && (
                <Col xl="12 xl-50 box-col-6" md="6">
                  <Card className="ecommerce-widget mb-0 ">
                    <CardBody
                      className="support-ticket-font"
                      style={{ padding: "20px" }}
                    >
                      <div className="col-12">
                        <CardBuilderHeaderSection
                          name={imageBannerDetail?.title}
                          checked={imageBannerDetail?.isActive}
                          switchToggle={true}
                          id={imageBannerDetail?.imageBannerId}
                          handleChange={handleChangeImageBanner}
                        />
                        <OpenPopupBtn
                          openPopup={() => {
                            setSectionPageName(imageBannerDetail.title);
                            getImageBannerParentList({
                              parentId: imageBannerDetail.imageBannerId,
                            });
                            setNavMapId(imageBannerDetail?.navMapId);
                            setFilter("");
                            setCollId(contentMapId);
                            setImageBannerId(imageBannerDetail.imageBannerId);
                            setTheme(imageBannerDetail.theme);
                            setMainThemeModal(imageBannerDetail.theme);
                          }}
                          name={`Add or View ${imageBannerDetail?.title}`}
                        />
                      </div>
                      <div className="d-flex justify-content-end ">
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleEditImageBanner(
                              imageBannerDetail.imageBannerId,
                              true
                            );
                            setCollId(contentMapId);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            handleDelete(imageBannerDetail.imageBannerId, true)
                          }
                        >
                          <Trash />
                        </IconButton>
                        {/* <IconButton
                          edge="start"
                          color="inherit"
                          style={{ marginLeft: "5px" }}
                          onClick={() => {
                            handleChangeSettings(
                              imageBannerDetail.imageBannerId
                            );
                          }}
                        >
                          <Settings />
                        </IconButton> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}{" "}
              <DeleteSection
                ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
                pageId={pageConfigDetails[0]?.pageId}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
      <CommonPopup
        open={allPopupState.imageBannerChildPopup}
        close={() => {
          OpenModalCommonFunc(Cases.IMAGE_BANNER, setAllPopupState, false);
          setIsParent(false);
        }}
        // apiCall={getImageBannerParentList}
        modalName={sectionPageName ? sectionPageName : modalsName.IMAGE_BANNER}
        modalCase={Cases.IMAGE_BANNER}
        handleSubmit={handleSubmit}
        validationForm={"childForm"}
        emptyData={emptyData}
        inputData={fromData}
        edit={false}
        oldTitle={titleUpdateKey.isGallery}
        label={titleUpdateKey.Certificates}
        getData={getData}
        content={
          <ImageBannerPopup
            getImageBannerParentList={getImageBannerParentList}
            ByteMiniWeb_Dashboard={ByteMiniWeb_Dashboard}
            handleChangeStatus={handleChangeImageBanner}
            handleEdit={handleEditImageBanner}
            imageUpload={imageUploadImages}
            emptyData={emptyData}
            simpleValidator={childValidator}
            webMasterDetail={webMasterDetail}
            handleDelete={handleDelete}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />

      {/* item Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.imageBannerPopup}
        close={() => {
          OpenModalCommonFunc(
            Cases.IMAGE_BANNER_PARENT,
            setAllPopupState,
            false
          );
          setIsParent(false);
        }}
        modalName={
          sectionPageName ? sectionPageName : modalsName.IMAGE_BANNER_PARENT
        }
        modalCase={Cases.IMAGE_BANNER_PARENT}
        handleSubmit={handleSubmit}
        validationForm={"parentForm"}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={<ImageBannerParentPopup simpleValidator={parentValidator} />}
      />

      {/* setting Service Counter parent popup */}
      <CommonPopup
        open={allPopupState.imageBannerSettingPopup}
        close={() =>
          OpenModalCommonFunc(
            Cases.SETTING_IMAGE_BANNER_PARENT,
            setAllPopupState,
            false
          )
        }
        modalName={
          sectionPageName
            ? sectionPageName
            : modalsName.SETTING_IMAGE_BANNER_PARENT
        }
        modalCase={Cases.SETTING_IMAGE_BANNER_PARENT}
        handleSubmit={handleSubmitSetting}
        emptyData={emptyData}
        inputData={formDataChild}
        edit={false}
        content={
          <SettingImagesBannerParentPopup
            imageUpload={imageUploadSetting}
            handleSubmitSetting={handleSubmitSetting}
            handleChangeSettings={handleChangeSettings}
          />
        }
      />
    </>
  );
};

export default ImageBanner;
