import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { toast, Zoom } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  CreateOrgPageItem,
  DropDownPageItemList,
  Edit_OrgPageItem,
  FILE_API,
} from "../../api/Organization Api/OrganizationApi";
import PageItemContext from "../../context/PageItemContext/PageItmContext";
import PersonalPageContext from "../../context/PersonalPageContext/PersonalPageContaxt";
import CKEditors from "react-ckeditor-component";
import { FILE_TYPE, PAGE_ITEM_TYPE, TITLES } from "../../constant";
import { AddCircle, DeleteForever } from "@mui/icons-material";
import { randomStr } from "../../utils/helper";
import { IconButton } from "@mui/material";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import PhoneInput from "react-phone-number-input";
import SimpleReactValidator from "simple-react-validator";
import { TostMessage } from "../../components ByteCard/utils";
import SaveContact from "./SaveContact";
import Editor from "../../components ByteCard/common/editor";

const PersonalPageAddmodal = (props) => {
  const {
    modal,
    setModal,
    feilds,
    setFilds,
    silderFeild,
    setSliderFilds,
    tabelData,
  } = props;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const {
    change,
    setChange,
    label,
    setLabel,
    value,
    setValue,
    qpiId,
    type,
    setType,
    logo,
    setLogo,
    editIndex,
    shareValue,
    setShareValue,
    dialCode,
    setDialCode,
    contactDetail,
    setContactDetail,
    isGalleryCustome,
    setIsGalleyCustome,
  } = useContext(PersonalPageContext);
  const { nfcGet, pageUuid, title, formvalue } = useContext(PageItemContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  const [formValue, setformValue] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  /**
   * Empty feilds function
   */

  const empltyFeild = () => {
    setContactDetail({
      name: "",
      companyName: "",
      designation: "",
      mobile1: "",
      dialCode1: "+91",
      mobile2: "",
      dialCode2: "+91",
      mobile3: "",
      email: "",
      location: "",
      webUrl: "",
      street: "",
      city: "",
      state: "",
      country: "",
    });
    setLogo("");
    setBaseUrl("");
    setChange("");
    setLabel("");
    setValue("");
    setType("");
    setFilds([
      {
        id1: randomStr(5),
        name: "",
        url: "",
        discription: "",
      },
    ]);
    setSliderFilds([
      {
        id1: randomStr(5),
        name: "",
        url: "",
      },
    ]);
    setDialCode("+91");
    setLoaderShow(false);
  };

  /**
   * Get Dropdown List Dainamicly Function And useEffect
   */

  const getDropDownItem = async () => {
    try {
      let paramObj = {
        showAll: true,
        sortBy: "label",
        sortOrder: "ASC",
        isThemeWise: true,
      };
      const { data } = await DropDownPageItemList(paramObj);
      if (data.status === 200) {
        if (
          formvalue?.filter(
            (elem) => elem?.itemMaser?.type === PAGE_ITEM_TYPE.GALLAYRY
          )?.length === 5
        ) {
          setformValue(
            data.payload.data.filter(
              (elem) => elem?.type !== PAGE_ITEM_TYPE.GALLAYRY
            )
          );
        } else {
          setformValue(data.payload.data);
        }
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDropDownItem();
  }, []);

  /**
   * Uploade Files
   */

  const pageItemUploadFile = async ({ files, type }) => {
    let formData1 = new FormData();
    formData1.append("type", type);
    formData1.append("file", files[0]);

    try {
      const { data } = await FILE_API(formData1);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });

        return {
          imageUrl: data.payload.imageUrl,
          baseUrl: data.payload.baseUrl,
        };
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Add page Item Function to call on Submit
   */

  const AddPageItem = async (e) => {
    setLoaderShow(!loaderShow);
    try {
      const formData = {
        itemId: change,
        pageUuid: pageUuid || localStorage.getItem("pageUuidForPageItem"),
        label,
        value:
          type === PAGE_ITEM_TYPE.SHARE
            ? shareValue
            : type === PAGE_ITEM_TYPE.SAVE_CONTACT
            ? PAGE_ITEM_TYPE.SAVE_CONTACT
            : type === PAGE_ITEM_TYPE.INQUIRY
            ? PAGE_ITEM_TYPE.INQUIRY
            : type === PAGE_ITEM_TYPE.QR_CODE
            ? PAGE_ITEM_TYPE.QR_CODE
            : value,
        sortOrder: tabelData.length + 1,
      };
      if (type === PAGE_ITEM_TYPE.SLIDER) {
        formData["isObject"] = true;
        formData["value"] = silderFeild;
      } else if (type === PAGE_ITEM_TYPE.SAVE_CONTACT) {
        formData["isObject"] = true;
        const { dialCode1, dialCode2, ...other } = contactDetail;
        formData["value"] = {
          ...other,
          mobile1: `${dialCode1} ${other.mobile1}`,
          mobile2: `${dialCode2} ${other.mobile2}`,
        };
      } else if (type === PAGE_ITEM_TYPE.LOCATION_IFRAME) {
        formData["value"] = value.split(`src="`)[1].split(`"`)[0];
      } else if (
        type === PAGE_ITEM_TYPE.VIDEO ||
        type === PAGE_ITEM_TYPE.GALLAYRY
      ) {
        formData["isObject"] = true;
        formData["value"] = feilds;
      }
      if (
        type === PAGE_ITEM_TYPE.WHATSAPP ||
        type === PAGE_ITEM_TYPE.WHATSAPP_SOCIAL ||
        type === PAGE_ITEM_TYPE.CALL
      ) {
        formData["value"] = `${dialCode} ${value}`;
      }

      if (e.target.download_file && e.target.download_file.files.length > 0) {
        const { imageUrl, baseUrl } = await pageItemUploadFile({
          files: e.target.download_file.files,
          type: FILE_TYPE.DOWNLOADE,
        });
        formData["value"] = baseUrl + imageUrl;
      }
      if (e.target.about_icon && e.target.about_icon.files.length > 0) {
        const { imageUrl } = await pageItemUploadFile({
          files: e.target.about_icon.files,
          type: FILE_TYPE.PAGE_ITEM,
        });
        formData["icon"] = imageUrl;
      }
      if (e.target.custome_icon && e.target.custome_icon.files.length > 0) {
        const { imageUrl } = await pageItemUploadFile({
          files: e.target.custome_icon.files,
          type: FILE_TYPE.PAGE_ITEM,
        });
        formData["icon"] = imageUrl;
      }
      if (e.target.gallery_icon && e.target.gallery_icon.files.length > 0) {
        const { imageUrl } = await pageItemUploadFile({
          files: e.target.gallery_icon.files,
          type: FILE_TYPE.GALLERY,
        });
        formData["icon"] = imageUrl;
      }
      if (type === PAGE_ITEM_TYPE.GALLAYRY) {
        if (e.target.url && e.target.url.length > 0) {
          for (let i = 0; i < e.target.url.length; i++) {
            const { imageUrl } = await imageUploade({
              files: e.target.url[i].files,
              type: FILE_TYPE.GALLERY,
            });
            feilds[i].url = imageUrl;
          }
          setFilds(feilds);
        } else {
          const { imageUrl } = await imageUploade({
            files: e.target.url.files,
            type: FILE_TYPE.GALLERY,
          });
          feilds[0].url = imageUrl;
          // }
          setFilds(feilds);
        }
        if (isGalleryCustome === false) {
          formData["icon"] = "";
        }
      }
      if (type === PAGE_ITEM_TYPE.SLIDER) {
        if (e.target.slider && e.target.slider.length > 0) {
          for (let i = 0; i < e.target.slider.length; i++) {
            const { imageUrl } = await imageUploade({
              files: e.target.slider[i].files,
              type: FILE_TYPE.SLIDER_FOR_LANDING,
            });
            silderFeild[i].url = imageUrl;
          }
          setSliderFilds(silderFeild);
        } else {
          const { imageUrl } = await imageUploade({
            files: e.target.slider.files,
            type: FILE_TYPE.SLIDER_FOR_LANDING,
          });
          silderFeild[0].url = imageUrl;
          setSliderFilds(silderFeild);
        }
      }

      const { data } = await CreateOrgPageItem(formData);
      if (data.status === 200) {
        nfcGet();
        empltyFeild();
        setModal(false);
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * Edit Page Item Function
   */
  const EditPageItem = async (e) => {
    setLoaderShow(!loaderShow);
    let formData = {
      itemId: change,
      pageUuid: pageUuid || localStorage.getItem("pageUuidForPageItem"),
      label,
      value:
        type === PAGE_ITEM_TYPE.SHARE
          ? shareValue
          : type === PAGE_ITEM_TYPE.SAVE_CONTACT
          ? PAGE_ITEM_TYPE.SAVE_CONTACT
          : type === PAGE_ITEM_TYPE.INQUIRY
          ? PAGE_ITEM_TYPE.INQUIRY
          : type === PAGE_ITEM_TYPE.QR_CODE
          ? PAGE_ITEM_TYPE.QR_CODE
          : value,
      sortOrder: "1",
    };
    if (logo) {
      formData["icon"] = logo;
    }
    if (type === PAGE_ITEM_TYPE.SLIDER) {
      formData["isObject"] = true;
      formData["value"] = silderFeild;
    } else if (type === PAGE_ITEM_TYPE.SAVE_CONTACT) {
      formData["isObject"] = true;
      const { dialCode1, dialCode2, ...other } = contactDetail;
      formData["value"] = {
        ...other,
        mobile1: `${dialCode1} ${other.mobile1}`,
        mobile2: `${dialCode2} ${other.mobile2}`,
      };
    } else if (type === PAGE_ITEM_TYPE.LOCATION_IFRAME) {
      formData["value"] = value.split(`src="`)[1].split(`"`)[0];
    } else if (
      type === PAGE_ITEM_TYPE.VIDEO ||
      type === PAGE_ITEM_TYPE.GALLAYRY
    ) {
      formData["isObject"] = true;
      formData["value"] = feilds;
    } else if (
      type === PAGE_ITEM_TYPE.WHATSAPP ||
      type === PAGE_ITEM_TYPE.WHATSAPP_SOCIAL ||
      type === PAGE_ITEM_TYPE.CALL
    ) {
      formData["value"] = `${dialCode} ${value}`;
    }
    if (e.target.download_file && e.target.download_file.files.length > 0) {
      const { imageUrl, baseUrl } = await pageItemUploadFile({
        files: e.target.download_file.files,
        type: 3,
      });
      formData["value"] = baseUrl + imageUrl;
    }
    if (e.target.about_icon && e.target.about_icon.files.length > 0) {
      const { imageUrl } = await pageItemUploadFile({
        files: e.target.about_icon.files,
        type: 2,
      });
      formData["icon"] = imageUrl;
    }
    if (e.target.custome_icon && e.target.custome_icon.files.length > 0) {
      const { imageUrl } = await pageItemUploadFile({
        files: e.target.custome_icon.files,
        type: 2,
      });
      formData["icon"] = imageUrl;
    }
    if (e.target.gallery_icon && e.target.gallery_icon.files.length > 0) {
      const { imageUrl } = await pageItemUploadFile({
        files: e.target.gallery_icon.files,
        type: FILE_TYPE.GALLERY,
      });
      formData["icon"] = imageUrl;
    }
    if (type === PAGE_ITEM_TYPE.GALLAYRY) {
      if (e.target.url && e.target.url.length > 0) {
        for (let i = 0; i < e.target.url.length; i++) {
          if (e.target.url[i].files.length) {
            const { imageUrl } = await imageUploade({
              files: e.target.url[i].files,
              type: FILE_TYPE.GALLERY,
            });
            feilds[i].url = imageUrl;
          }
        }
        setFilds(feilds);
      } else {
        if (e.target.url.files.length) {
          const { imageUrl } = await imageUploade({
            files: e.target.url.files,
            type: FILE_TYPE.GALLERY,
          });
          feilds[0].url = imageUrl;
          setFilds(feilds);
        }
      }
      if (isGalleryCustome === false) {
        formData["icon"] = "";
      }
    }
    if (type === PAGE_ITEM_TYPE.SLIDER) {
      if (e.target.slider && e.target.slider.length > 0) {
        for (let i = 0; i < e.target.slider.length; i++) {
          if (e.target.slider[i].files.length) {
            const { imageUrl } = await imageUploade({
              files: e.target.slider[i].files,
              type: FILE_TYPE.SLIDER_FOR_LANDING,
            });
            silderFeild[i].url = imageUrl;
          }
        }
        setSliderFilds(silderFeild);
      } else {
        if (e.target.slider.files.length) {
          const { imageUrl } = await imageUploade({
            files: e.target.slider.files,
            type: FILE_TYPE.SLIDER_FOR_LANDING,
          });
          silderFeild[0].url = imageUrl;
          setSliderFilds(silderFeild);
        }
      }
    }
    try {
      const { data } = await Edit_OrgPageItem(formData, qpiId);
      if (data.status === 200) {
        nfcGet();
        empltyFeild();
        setModal(false);
        toast.success(data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * submit to form as a additem time and edititem time
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (
      !formValid &&
      (type === PAGE_ITEM_TYPE.SLIDER || type === PAGE_ITEM_TYPE.GALLAYRY)
    ) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (editIndex > -1 && !title) {
        EditPageItem(e);
      } else {
        AddPageItem(e);
      }
    }
  };

  /**
   *Only set To show image function
   */
  const readUrl = async (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setLogo(reader.result);
    };
  };

  /**
   *dropdown handle change function to get value,label and type
   */
  const handleChange = (e) => {
    const [item] = formValue.filter((elem) => {
      return elem.itemId === +e.target.value;
    });
    setChange(e.target.value);
    setLabel(item.label);
    setType(item.type);
  };

  /**
   *max length of number field function
   */
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  /**
   * onChange function are use to CK editor
   */

  // const onChange = (evt) => {
  //   const newContent = evt.editor.getData();
  //   setValue(newContent);
  // };
  const onChange = (event) => {
    setValue(event);
  };

  /**
   * Popup close to empty feild
   */

  const close = () => {
    empltyFeild();
    setModal(!modal);
  };

  /**
   * Image add function
   */

  const imageUploade = async ({ files, type }) => {
    let formData1 = new FormData();
    formData1.append("type", type);
    formData1.append("file", files[0]);

    try {
      const { data } = await FILE_API(formData1);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });

        return {
          imageUrl: data.payload.imageUrl,
          baseUrl: data.payload.baseUrl,
        };
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * Add more Function and remove function call
   */

  const handleAdd = (e) => {
    e.preventDefault();
    setFilds([
      ...feilds,
      {
        id1: randomStr(5),
        name: "",
        url: "",
        discription: "",
      },
    ]);
  };

  const hanldeRemove = (i, e) => {
    e.preventDefault();
    const values = [...feilds];
    values.splice(i, 1);
    setFilds(values);
  };

  const handleInputChange = (i, e) => {
    const { name, value } = e.target;

    if (name === "url") {
      const allowedExtensions = ["jpg", "png", "jpeg"];
      const file = e.target.files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (file.size <= 1024 * 1024) {
        if (allowedExtensions.includes(extension)) {
          let param = [...feilds];
          param[i][name] = value;
          setFilds(param);
        } else {
          let fileInput = document.getElementById("formFile");
          if (fileInput) {
            fileInput.value = null;
          }
          TostMessage({
            message: "file type required only .jpg, .png, .jpeg",
            type: "error",
            position: "custome",
          });
        }
      } else {
        let fileInput = document.getElementById("formFile");
        if (fileInput) {
          fileInput.value = null;
        }
        TostMessage({
          message: "Maximum File Size for Upload: 1MB",
          type: "error",
          position: "custome",
        });
      }
    } else {
      let param = [...feilds];
      if (name === "video") {
        param[i]["url"] = value;
      } else {
        param[i][name] = value;
      }
      setFilds(param);
    }
  };
  /**
   * Add more Function and remove function call for slider
   */

  const handleAddSlider = (e) => {
    e.preventDefault();
    setSliderFilds([
      ...silderFeild,
      {
        id1: randomStr(5),
        name: "",
        url: "",
      },
    ]);
  };

  const hanldeRemoveSlider = (i, e) => {
    e.preventDefault();
    const values = [...silderFeild];
    values.splice(i, 1);
    setSliderFilds(values);
  };

  const handleInputChangeSlider = (i, e) => {
    const allowedExtensions = ["jpg", "png", "jpeg"];
    const file = e.target?.files ? e.target?.files[0] : "";
    const extension = e.target?.files
      ? file?.name?.split(".")?.pop()?.toLowerCase()
      : "";
    if (
      e.target.name === "link" ||
      (e.target.name === "slider" && allowedExtensions.includes(extension))
    ) {
      let param = [...silderFeild];
      param[i][e.target.name] = e.target.value;
      setSliderFilds(param);
    } else {
      const fileInput = document.getElementById("fileInputSlider");
      if (fileInput) {
        fileInput.value = null;
      }
      TostMessage({
        message: "file type required only .jpg, .png, .jpeg",
        type: "error",
        position: "custome",
      });
    }
  };

  const handleShareValue = () => {
    if (shareValue === "true") {
      setShareValue("false");
    } else if (shareValue === "false") {
      setShareValue("true");
    }
  };

  return (
    <Modal size="xl" isOpen={modal} toggle={() => close()}>
      <ModalHeader toggle={() => close()}>
        {title ? (
          <span>{TITLES.ADD_PERSONAL_PAGE}</span>
        ) : (
          <span>{TITLES.EDIT_PERSONAL_PAGE}</span>
        )}
      </ModalHeader>
      <ModalBody>
        {/* <LoaderResponse /> */}
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => onSubmit(e)}
                >
                  {type === PAGE_ITEM_TYPE.GALLAYRY && isGalleryCustome ? (
                    <Row>
                      <Col md="4 mb-4 mt-4">
                        <div className="user-image">
                          <div className="avatar">
                            <Media
                              body
                              alt=""
                              src={baseUrl + logo}
                              style={{
                                width: "9rem",
                                height: "9rem",
                                borderRadius: "45rem",
                                border: "1px solid ",
                              }}
                              data-intro="This is Profile image"
                            />
                          </div>
                          <div
                            className="icon-wrapper"
                            data-intro="Change Profile image here"
                          >
                            <i className="icofont icofont-pencil-alt-5">
                              <Input
                                className="upload"
                                type="file"
                                name="gallery_icon"
                                id="gallery__icon"
                                onChange={(e) => readUrl(e)}
                                style={{
                                  border: "2px solid red",
                                  width: "10rem",
                                  position: "absolute",
                                  bottom: "-1rem",
                                  height: "12rem",
                                }}
                              />
                            </i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {type === PAGE_ITEM_TYPE.CUSTOM ? (
                    <Row>
                      <Col md="4 mb-4 mt-4">
                        <div className="user-image">
                          <div className="avatar">
                            <Media
                              body
                              alt=""
                              src={baseUrl + logo}
                              style={{
                                width: "9rem",
                                height: "9rem",
                                borderRadius: "45rem",
                                border: "1px solid ",
                              }}
                              data-intro="This is Profile image"
                            />
                          </div>
                          <div
                            className="icon-wrapper"
                            data-intro="Change Profile image here"
                          >
                            <i className="icofont icofont-pencil-alt-5">
                              <Input
                                className="upload "
                                type="file"
                                name="custome_icon"
                                onChange={(e) => readUrl(e)}
                                style={{
                                  border: "2px solid red",
                                  width: "10rem",
                                  position: "absolute",
                                  bottom: "-1rem",
                                  height: "12rem",
                                }}
                              />
                            </i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {type === PAGE_ITEM_TYPE.ABOUT_ME ||
                  type === PAGE_ITEM_TYPE.POLICY ? (
                    <Row>
                      <Col md="4 mb-4 mt-4">
                        <div className="user-image">
                          <div className="avatar">
                            <Media
                              body
                              alt=""
                              src={baseUrl + logo}
                              style={{
                                width: "9rem",
                                height: "9rem",
                                borderRadius: "45rem",
                                border: "1px solid ",
                              }}
                              data-intro="This is Profile image"
                            />
                          </div>
                          <div
                            className="icon-wrapper"
                            data-intro="Change Profile image here"
                          >
                            <i className="icofont icofont-pencil-alt-5">
                              <Input
                                className="upload"
                                type="file"
                                name="about_icon"
                                onChange={(e) => readUrl(e)}
                                style={{
                                  border: "2px solid red",
                                  width: "10rem",
                                  position: "absolute",
                                  bottom: "-1rem",
                                  height: "12rem",
                                }}
                              />
                            </i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom4" className="d-flex">
                        {"Types"}{" "}
                        {type === PAGE_ITEM_TYPE.GALLAYRY && (
                          <div className="d-flex gap-2 mx-2 ">
                            ({" "}
                            <label htmlFor="isCheck" className="m-0">
                              Custome Icon
                            </label>
                            <input
                              type="checkbox"
                              checked={isGalleryCustome}
                              onChange={(e) => {
                                setIsGalleyCustome(!isGalleryCustome);
                                let fileInput =
                                  document.getElementById("gallery__icon");
                                if (fileInput) {
                                  fileInput.value = null;
                                }
                                setLogo("");
                                setBaseUrl("");
                              }}
                              id="isCheck"
                            />
                            )
                          </div>
                        )}
                      </Label>

                      <select
                        className="form-select "
                        style={{ marginBottom: "12px" }}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="select">--select--</option>
                        {formValue.map((elem) => {
                          return (
                            <option
                              key={elem.itemId}
                              value={elem.itemId}
                              name={elem.label}
                              selected={
                                elem.itemId === change ? "selected" : ""
                              }
                            >
                              {elem.label}
                            </option>
                          );
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom4">{"Title"}</Label>
                      <Input
                        className="form-control text-dark"
                        name="name"
                        id="validationCustom4"
                        type="text"
                        placeholder="Title"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        min={0}
                        maxLength={20}
                        onInput={maxLengthCheck}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>

                  {type === PAGE_ITEM_TYPE.ABOUT_ME ||
                  type === PAGE_ITEM_TYPE.POLICY ? (
                    <Row>
                      <Col md="12 mb-4">
                        {/* <CKEditors
                          activeclassName="p10"
                          content={value}
                          events={{
                            change: onChange,
                          }}
                        /> */}
                        <Editor value={value} onChange={onChange} />
                      </Col>
                    </Row>
                  ) : type === PAGE_ITEM_TYPE.VIDEO ? (
                    <>
                      {feilds.map((elem, i) => (
                        <>
                          <div key={elem.id1}>
                            <Row>
                              <Col md="6 mb-4">
                                <Label htmlFor="validationCustom4">
                                  {"Video Title"}
                                </Label>
                                <Input
                                  className="form-control"
                                  name="name"
                                  id="validationCustom4"
                                  type="text"
                                  placeholder="Name"
                                  value={elem.name}
                                  maxLength={20}
                                  onInput={maxLengthCheck}
                                  onChange={(e) => handleInputChange(i, e)}
                                  required
                                />
                                <div className="invalid-feedback">
                                  {"Please Provide a Valid Admin Name."}
                                </div>
                              </Col>
                              <Col md="6 mb-4">
                                <Label htmlFor="validationCustom4">
                                  {"YouTube Video Link"}
                                </Label>
                                <Input
                                  className="form-control"
                                  name="video"
                                  id="validationCustom4"
                                  type="text"
                                  placeholder="Video URL"
                                  value={elem.url}
                                  onChange={(e) => handleInputChange(i, e)}
                                  required
                                />
                                <div className="invalid-feedback">
                                  {"Please Provide a Valid Admin Name."}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="11 mb-4">
                                <Label htmlFor="validationCustom4">
                                  {"Description (optional)"}
                                </Label>
                                <Input
                                  className="form-control"
                                  name="Discription"
                                  id="validationCustom4"
                                  type="textarea"
                                  placeholder="Description"
                                  value={elem.discription}
                                  onChange={(e) => handleInputChange(i, e)}
                                />
                              </Col>
                              <Col
                                md="1 mb-4"
                                className="d-flex justify-content-center  align-items-center "
                                style={{
                                  top: "12px",
                                }}
                              >
                                <IconButton
                                  aria-label="delete"
                                  style={{
                                    background: "#e1d7d7",
                                  }}
                                  disabled={i === 0}
                                  onClick={(e) => hanldeRemove(i, e)}
                                >
                                  <DeleteForever style={{ color: "red" }} />
                                </IconButton>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ))}
                      <div className="d-flex justify-content-center  ">
                        <IconButton
                          aria-label="add"
                          style={{ background: "#dcf1dc" }}
                          onClick={(e) => handleAdd(e)}
                        >
                          <AddCircle style={{ color: "green" }} />
                        </IconButton>
                      </div>
                    </>
                  ) : type === PAGE_ITEM_TYPE.GALLAYRY ? (
                    <>
                      {feilds.map((elem, i) => (
                        <>
                          <div key={elem.id1}>
                            <Row>
                              <Col md="6 mb-4">
                                <Label htmlFor="validationCustom4">
                                  {"URL"}
                                </Label>
                                <Input
                                  className="form-control"
                                  type="file"
                                  name="url"
                                  id="formFile"
                                  onChange={(e) => handleInputChange(i, e)}
                                />
                                <p style={{ color: "red" }}>
                                  {simpleValidator.current.message(
                                    "Image",
                                    elem.url,
                                    "required"
                                  )}
                                </p>
                              </Col>
                              <Col md="5 mb-4">
                                <Label htmlFor="validationCustom4">
                                  {"Image Title (Optional)"}
                                </Label>
                                <Input
                                  className="form-control"
                                  name="name"
                                  id="validationCustom4"
                                  type="text"
                                  placeholder="Name"
                                  value={elem.name}
                                  onChange={(e) => handleInputChange(i, e)}
                                />
                                <div className="invalid-feedback">
                                  {"Please Provide a Valid Admin Name."}
                                </div>
                              </Col>
                              <Col
                                md="1 mb-4"
                                className="d-flex justify-content-center  align-items-center "
                                style={{
                                  top: "12px",
                                }}
                              >
                                <IconButton
                                  aria-label="delete"
                                  style={{
                                    background: "#e1d7d7",
                                  }}
                                  disabled={i === 0}
                                  onClick={(e) => hanldeRemove(i, e)}
                                >
                                  <DeleteForever style={{ color: "red" }} />
                                </IconButton>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ))}
                      {feilds.length !== 10 && (
                        <div className="d-flex justify-content-center ">
                          <IconButton
                            aria-label="add"
                            style={{ background: "#dcf1dc" }}
                            onClick={(e) => handleAdd(e)}
                          >
                            <AddCircle style={{ color: "green" }} />
                          </IconButton>
                        </div>
                      )}
                    </>
                  ) : type === PAGE_ITEM_TYPE.SLIDER ? (
                    <>
                      {silderFeild.map((elem, i) => (
                        <>
                          <div key={elem.id1}>
                            <Row>
                              <Col md="5 mb-4">
                                <Label htmlFor="validationCustom4">
                                  {"URL (png, jpg, jpeg) & Size (1853 X 579)"}
                                </Label>
                                <Input
                                  className="form-control"
                                  type="file"
                                  name="slider"
                                  id="fileInputSlider"
                                  onChange={(e) =>
                                    handleInputChangeSlider(i, e)
                                  }
                                />
                                <p style={{ color: "red" }}>
                                  {simpleValidator.current.message(
                                    "slider",
                                    elem.slider,
                                    "required"
                                  )}
                                </p>
                              </Col>
                              <Col md="5 mb-4">
                                <Label htmlFor="validationCustom4">
                                  {"Link"}
                                </Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="link"
                                  id="fileInputSlider"
                                  placeholder="Link"
                                  value={elem.link}
                                  onChange={(e) =>
                                    handleInputChangeSlider(i, e)
                                  }
                                />
                              </Col>
                              <Col
                                md="1 mb-4"
                                className="d-flex justify-content-center  align-items-center "
                                style={{
                                  top: "12px",
                                }}
                              >
                                <IconButton
                                  aria-label="delete"
                                  style={{
                                    background: "#e1d7d7",
                                  }}
                                  disabled={i === 0}
                                  onClick={(e) => hanldeRemoveSlider(i, e)}
                                >
                                  <DeleteForever style={{ color: "red" }} />
                                </IconButton>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ))}
                      <div className="d-flex justify-content-center ">
                        <IconButton
                          aria-label="add"
                          style={{ background: "#dcf1dc" }}
                          onClick={(e) => handleAddSlider(e)}
                        >
                          <AddCircle style={{ color: "green" }} />
                        </IconButton>
                      </div>
                    </>
                  ) : type === PAGE_ITEM_TYPE.SAVE_CONTACT ? (
                    <>
                      <SaveContact />
                    </>
                  ) : (
                    <Row>
                      {type === PAGE_ITEM_TYPE.WHATSAPP_SOCIAL ||
                      type === PAGE_ITEM_TYPE.WHATSAPP ||
                      type === PAGE_ITEM_TYPE.CALL ? (
                        <Col md="3 mb-3">
                          <Label htmlFor="validationCustom03">
                            {"Dial Code*"}
                          </Label>
                          <PhoneInput
                            className="form-control"
                            international
                            defaultCountry="IN"
                            placeholder="Enter Phone Number "
                            value={dialCode}
                            onChange={setDialCode}
                            style={{ height: "42px" }}
                          />
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col
                        md={
                          type === PAGE_ITEM_TYPE.WHATSAPP_SOCIAL ||
                          type === PAGE_ITEM_TYPE.WHATSAPP ||
                          type === PAGE_ITEM_TYPE.CALL
                            ? `9 mb-4`
                            : `12 mb-4`
                        }
                      >
                        {type === PAGE_ITEM_TYPE.SHARE ||
                        type === PAGE_ITEM_TYPE.SAVE_CONTACT ||
                        type === PAGE_ITEM_TYPE.INQUIRY ||
                        type === PAGE_ITEM_TYPE.INQUIRY ||
                        type === PAGE_ITEM_TYPE.QR_CODE ? null : (
                          <Label htmlFor="validationCustom4">{"Value"}</Label>
                        )}
                        {type === PAGE_ITEM_TYPE.WHATSAPP_SOCIAL ||
                        type === PAGE_ITEM_TYPE.WHATSAPP ||
                        type === PAGE_ITEM_TYPE.CALL ? (
                          <Input
                            className="form-control"
                            name="value"
                            id="validationCustom4"
                            type="number"
                            placeholder="Value"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            min={0}
                            maxLength={10}
                            onInput={maxLengthCheck}
                            required
                          />
                        ) : type === PAGE_ITEM_TYPE.DOWNLOAD ? (
                          <Input
                            className="form-control"
                            name="download_file"
                            id="validationCustom4"
                            type="file"
                          />
                        ) : type === PAGE_ITEM_TYPE.SAVE_CONTACT ||
                          type === PAGE_ITEM_TYPE.INQUIRY ||
                          type === PAGE_ITEM_TYPE.QR_CODE ? null : type ===
                          PAGE_ITEM_TYPE.SHARE ? (
                          +localStorage.getItem("pageLimit") !== 1 && (
                            <div className="checkbox checkbox-primary">
                              <input
                                id="checkbox-primary-1"
                                type="checkbox"
                                // onClick={onCheck}
                                checked={shareValue === "true"}
                                onChange={(e) => handleShareValue(e)}
                              />
                              <Label htmlFor="checkbox-primary-1">
                                {"With Dropdown"}
                              </Label>
                            </div>
                          )
                        ) : (
                          <Input
                            className="form-control"
                            name="value"
                            id="validationCustom4"
                            type="text"
                            placeholder="Value"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            required
                          />
                        )}
                        <div className="invalid-feedback">
                          {"Please Provide a Valid Admin Name."}
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col
                      md="12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "18px",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        style={{ width: "15rem" }}
                        disabled={loaderShow === true}
                      >
                        {"Submit"}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PersonalPageAddmodal;
