import React, { useContext } from "react";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import { OpenModalCommonFunc, TostMessage } from "../../../utils";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import ItemsDragAndDrops from "../../../common/ItemsDragAndDrops";
import { maxLengthCheck } from "../../../../utils/helper";
import { sort_service_counter } from "../apis/ServiceCounterApi/ServiceCounterApi";
import ServiceCounterContext from "../../../context/ServiceCounterContext/ServiceCounterContext";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";
import AllExportsImage from "../../../Modals/imageUploade/InputFileUpload";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import { IconButton } from "@material-ui/core";
import { Close } from "@mui/icons-material";
import { UploadCloud } from "react-feather";
import Dividers from "../../../common/divider";
import { ThemeNo } from "../../../../constant";

const ServiceCounterPopup = (props) => {
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);
  const {
    handleChangeStatus,
    imageUpload,
    emptyData,
    simpleValidator,
    handleDelete,
    handleEdit,
    getServiceCounterParentList,
    theme,
  } = props;
  const {
    formDataChild,
    setFormDataChild,
    setFilter,
    filter,
    isEdit,
    setApiDataOurTeam: setApiDataImageParent,
    apiDataOurTeam: apiDataImageParent,
  } = useContext(ServiceCounterContext);
  const { setLoaderShow } = useContext(LoaderContext);

  /**
   * drag and drop
   * @param {drag data} result
   * @returns
   */
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(apiDataImageParent.data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setApiDataImageParent({ ...formDataChild, data: items });
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({ serviceCounterId: items[item].Id, order: +item + 1 });
      }
      const { data } = await sort_service_counter(itemOrder);
      if (data.status === 200) {
        TostMessage({ message: data, type: "success" });
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
    }
  };

  /**
   * create new latent news
   */
  const hanldenewCreate = () => {
    emptyData();
  };

  /**
   * reupload image
   */
  const reupload = () => {
    setFormDataChild((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };

  /**
   * input value change
   * @param {event} e
   */
  const InputValueChange = (e) => {
    const { value, name, checked } = e.target;
    setFormDataChild((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: formDataChild.image
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          TostMessage({ message: data, type: "success", position: "center" });
          setFormDataChild({ ...formDataChild, image: "" });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      TostMessage({ message: error, type: "error" });
      setLoaderShow(false);
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="6">
            <div>
              <h6>Total Count : {apiDataImageParent?.data?.length}</h6>
              {/* <p className="pt-2">You can add upto 25 Images</p> */}
              <Row>
                <Col md="6">
                  <Input
                    className="form-control mt-3 "
                    name="search"
                    type="text"
                    placeholder="search"
                    style={{ borderRadius: "0", color: "black" }}
                    value={filter}
                    onChange={(e) => {
                      getServiceCounterParentList({ filter: e.target.value });
                      setFilter(e.target.value);
                    }}
                  />
                </Col>

                <Col
                  md="6"
                  className="d-flex justify-content-center align-items-center mt-3"
                >
                  <Button
                    color="primary"
                    className="rounded-0"
                    onClick={() => hanldenewCreate()}
                  >
                    Add New
                  </Button>
                </Col>
              </Row>
              {apiDataImageParent.isLoading ? (
                <div className="mt-4">
                  <h6>Loading ...</h6>
                </div>
              ) : (
                <div className="mt-4">
                  <ItemsDragAndDrops
                    handleOnDragEnd={handleOnDragEnd}
                    characters={apiDataImageParent?.data}
                    handleChangeStatus={handleChangeStatus}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                </div>
              )}
            </div>
          </Col>
          <Col sm="12" xl="6">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : " Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={formDataChild?.isTitleShow}
                      onChange={InputValueChange}
                    />{" "}
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={formDataChild.title}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    min={0}
                    maxLength={50}
                    onInput={maxLengthCheck}
                  />
                  <div className="text-end mt-3 ">
                    <span>{formDataChild.title.length} / 50</span>
                  </div>
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "title",
                      formDataChild.title,
                      "required"
                    )}
                  </p>
                </Col>
                {/* <Col md="12 mb-1">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title"
                    value={formDataChild.subTitle}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col> */}
                <Col md="6 mb-1">
                  <Label htmlFor="validationCustom03">
                    {"Counter Value *"}
                  </Label>
                  <Input
                    className="form-control"
                    name="value"
                    type="text"
                    placeholder="Counter Value *"
                    value={formDataChild.value}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Counter Value",
                      formDataChild.value,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="6 mb-1">
                  <Label htmlFor="validationCustom03">{"Value"}</Label>
                  <Input
                    className="form-control"
                    name="onlyValue"
                    type="text"
                    placeholder="Value"
                    value={formDataChild.onlyValue}
                    onChange={(e) => InputValueChange(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col>
              </Row>
              {(theme == ThemeNo.ThemeTwo || theme == ThemeNo.ThemeThree) && (
                <>
                  <Dividers divide="IMAGE" />
                  <p className="mb-4">
                    {AllExportsImage.fileUploadMessage} (50px X 50px)
                  </p>
                  <Row>
                    <Col md="6 mb-3">
                      <div
                        className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        {!formDataChild.image && (
                          <span
                            onClick={() => {
                              OpenModalCommonFunc(
                                Cases.PRODUCT_UPLOAD,
                                setAllPopupState
                              );
                            }}
                          >
                            <UploadCloud />
                          </span>
                        )}

                        {formDataChild.image && (
                          <img
                            src={formDataChild.image || ""}
                            alt=""
                            style={{
                              width: "80%",
                              height: "98%",
                              objectFit: "contain",
                              position: "absolute",
                            }}
                            onClick={() => {
                              deleteImagePermanent();
                            }}
                          />
                        )}
                        {formDataChild.image && (
                          <>
                            {" "}
                            <IconButton
                              edge="start"
                              color="inherit"
                              aria-label="close"
                              style={{
                                color: "red",
                                position: "absolute",
                                top: "-22px",
                                left: "1px",
                              }}
                              onClick={() => deleteImagePermanent()}
                            >
                              <Close
                                style={{ fontSize: "25px", fontWeight: "bold" }}
                              />
                            </IconButton>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
        </Row>
        <ProductImageUploadPopup
          allPopupState={allPopupState}
          setAllPopupState={setAllPopupState}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          image={formDataChild.image}
          setImage={setFormDataChild}
          imageUpload={imageUpload}
          isEdit={isEdit}
          reupload={reupload}
          // aspect={1.41/1}
        />
      </Container>
    </>
  );
};

export default ServiceCounterPopup;
