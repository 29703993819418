import React, { useContext, useEffect, useState } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { Border, fontWeight } from "../../../../../constant";
import { Switch } from "@material-ui/core";
import HeaderContext from "../../../../context/HeaderContext/HeaderContext";
import { FontList_List } from "../../../../../api/FontListApi/FontListApi";
import CommonColor from "../../../../common/CommonColor";
import CustomStyle from "../../../../common/CustomStyle";
import HeaderImageUpload from "./HeaderImageUpload";

const NavbarStyleThree = (props) => {
  const { imageUpload } = props;
  const {
    settingFormData,
    setSettingFormData,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(HeaderContext);
  const [fontListGet, setFontListGet] = useState([]);

  /**
   * input value change
   * @param {event} e
   */

  const handleChangeLogoWidth = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".custom-nav .navbar-brand img": {
          ...prev[".custom-nav .navbar-brand img"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomNav = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".custom-nav": {
          ...prev[".custom-nav"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeMenuItem = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".custom-nav .navbar-nav .nav-link": {
          ...prev[".custom-nav .navbar-nav .nav-link"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeDropDownMenu = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".custom-nav .navbar-nav .dropdown-menu": {
          ...prev[".custom-nav .navbar-nav .dropdown-menu"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeDropDownMenuItem = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item": {
          ...prev[".custom-nav .navbar-nav .dropdown-menu .dropdown-item"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeDropDownSubTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".custom-nav .navbar-nav .dropdown-mega .subtitle": {
          ...prev[".custom-nav .navbar-nav .dropdown-mega .subtitle"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeListGroupItem = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item": {
          ...prev[
            ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
          ],
          [name]: value,
        },
      };
    });
  };

  const handleChangeMenuIconLinks = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".custom-nav .left-menu-icons .links": {
          ...prev[".custom-nav .left-menu-icons .links"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeIsCenterNavs = (e) => {
    const { name, checked } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: checked,
      };
    });
  };
  const handleChangeDropdownToggle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".custom-nav .login-user .dropdown-toggle": {
          ...prev[".custom-nav .login-user .dropdown-toggle"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const fontListData = async () => {
    try {
      const { data } = await FontList_List();
      if (data.status === 200) {
        setFontListGet(data.payload.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const checkActive =
      settingFormData?.[
        ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
      ];
    if (checkActive?.["background-color"]) {
      setIsBackgroundColor(true);
    }
    fontListData();
  }, []);

  const handleChangeFontFamily = (e) => {
    const { name, value } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value ? JSON?.parse(value) : "",
      };
    });
  };
  return (
    <>
      <HeaderImageUpload imageUpload={imageUpload} />
      <Col sm="12" xl="12">
        <div className="mt-4 d-flex flex-column justify-content-center ">
          <Row className="mt-4">
            <h4 className="mb-3">Make change in Navbar Show Center or not 3</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Navbar Center (Default : false)"}
              </Label>
              <Switch
                name="isCenterNavs"
                checked={settingFormData?.isCenterNavs}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(e) => {
                  handleChangeIsCenterNavs(e);
                }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-4">
            <h4 className="mb-3">Make change in Navbar Background Color</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={settingFormData?.[".custom-nav"]?.["background-color"]}
                setSettingFormData={setSettingFormData}
                className={".custom-nav"}
                classProperty={"background-color"}
                label={"Select Background Color"}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (Default : 15px)"}
              </Label>
              <Input
                className="form-control"
                name="padding"
                type="number"
                placeholder="padding"
                value={settingFormData?.[".custom-nav"]?.["padding"]?.replace(
                  /px/g,
                  ""
                )}
                onChange={(e) => handleChangeCustomNav(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change logo width</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin Bottom (Default : 200)"}
              </Label>
              <Input
                className="form-control"
                name="max-width"
                type="number"
                placeholder="Max Width"
                value={settingFormData?.[".custom-nav .navbar-brand img"]?.[
                  "max-width"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeLogoWidth(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in Menu Hover Color</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                  ]?.["color"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                }
                classProperty={"color"}
                label={"Select Color"}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"BackGround color On Active (Default : white)"}
              </Label>
              <Switch
                checked={isBackgroundColor}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(e) => {
                  setIsBackgroundColor(e.target.checked);
                  setSettingFormData((prev) => {
                    if (e.target.checked) {
                      return {
                        ...prev,
                        ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
                          {
                            ...prev?.[
                              ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                            ],
                            "background-color": {
                              r: 255,
                              g: 255,
                              b: 255,
                              a: 100,
                            },
                          },
                      };
                    } else {
                      return {
                        ...prev,
                        ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active":
                          {
                            color:
                              settingFormData?.[
                                ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                              ]?.["color"],
                          },
                      };
                    }
                  });
                }}
              />
              {isBackgroundColor && (
                <CommonColor
                  color={
                    settingFormData?.[
                      ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                    ]?.["background-color"]
                  }
                  setSettingFormData={setSettingFormData}
                  className={
                    ".custom-nav .navbar-nav .nav-link:hover,.custom-nav .navbar-nav .nav-link.active"
                  }
                  classProperty={"background-color"}
                  label={"Select Color"}
                />
              )}
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in Menu Item</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font Size (Default : 14)"}
              </Label>
              <Input
                className="form-control"
                name="font-size"
                type="number"
                placeholder="Font Size"
                value={settingFormData?.[".custom-nav .navbar-nav .nav-link"]?.[
                  "font-size"
                ]?.replace(/px/g, "")}
                onChange={(e) => handleChangeMenuItem(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font Weight (Default : 500)"}
              </Label>
              <select
                name="font-weight"
                id="font-weight"
                className="form-control"
                value={
                  settingFormData?.[".custom-nav .navbar-nav .nav-link"]?.[
                    "font-weight"
                  ]
                }
                onChange={(e) => handleChangeMenuItem(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {fontWeight.map((elem) => {
                  return (
                    <option
                      value={elem["font-weight"]}
                      key={elem["font-weight"]}
                    >
                      {elem["font-weight"]}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (Default : 13 - 10)"}
              </Label>
              <div className="d-flex justify-content-between">
                <Input
                  className="form-control"
                  name="p1"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    ".custom-nav .navbar-nav .nav-link"
                  ]?.["p1"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeMenuItem(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
                <Input
                  className="form-control"
                  name="p2"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    ".custom-nav .navbar-nav .nav-link"
                  ]?.["p2"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeMenuItem(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
            </Col>
            {/* <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Padding (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="p1"
                    type="number"
                    placeholder="Padding"
                    value={settingFormData?.[
                      ".custom-nav .navbar-nav .nav-link"
                    ]?.["p1"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeMenuItem(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col> */}
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0 - 10)"}
              </Label>
              <div className="d-flex justify-content-between">
                <Input
                  className="form-control"
                  name="m1"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    ".custom-nav .navbar-nav .nav-link"
                  ]?.["m1"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeMenuItem(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
                <Input
                  className="form-control"
                  name="m2"
                  type="number"
                  placeholder="Padding"
                  value={settingFormData?.[
                    ".custom-nav .navbar-nav .nav-link"
                  ]?.["m2"]?.replace(/px/g, "")}
                  onChange={(e) => handleChangeMenuItem(e)}
                  style={{ borderRadius: "0", color: "black" }}
                />
              </div>
            </Col>
            {/* <Col md="4 mb-3">
                  <Label htmlFor="validationCustom03">
                    {"Margin (Default : 15)"}
                  </Label>
                  <Input
                    className="form-control"
                    name="m2"
                    type="number"
                    placeholder="Margin"
                    value={settingFormData?.[
                      ".custom-nav .navbar-nav .nav-link"
                    ]?.["m2"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeMenuItem(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </Col> */}
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[".custom-nav .navbar-nav .nav-link"]?.[
                    "color"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".custom-nav .navbar-nav .nav-link"}
                classProperty={"color"}
                label={"Select Menu Font Color"}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in dropdown menu</h4>
            <Col md="6 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Top (Default : 3 solid pink)"}
              </Label>
              <div className="d-flex ">
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="borderPx"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      ".custom-nav .navbar-nav .dropdown-menu"
                    ]?.["borderPx"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeDropDownMenu(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <select
                    name="borderThik"
                    id="borderThik"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".custom-nav .navbar-nav .dropdown-menu"
                      ]?.["borderThik"]
                    }
                    onChange={(e) => handleChangeDropDownMenu(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {Border.map((elem) => {
                      return (
                        <option value={elem.border} key={elem.border}>
                          {elem.border}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div>
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".custom-nav .navbar-nav .dropdown-menu"
                      ]?.["borderColor"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".custom-nav .navbar-nav .dropdown-menu"}
                    classProperty={"borderColor"}
                    label={"Border Color"}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in Dropdown Item</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font Size (Default : 14)"}
              </Label>
              <Input
                className="form-control"
                name="font-size"
                type="number"
                placeholder="Font Size"
                value={settingFormData?.[
                  ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
                ]?.["font-size"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeDropDownMenuItem(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font Weight (Default : 500)"}
              </Label>
              <select
                name="font-weight"
                id="font-weight"
                className="form-control"
                value={
                  settingFormData?.[
                    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
                  ]?.["font-weight"]
                }
                onChange={(e) => handleChangeDropDownMenuItem(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {fontWeight.map((elem) => {
                  return (
                    <option
                      value={elem["font-weight"]}
                      key={elem["font-weight"]}
                    >
                      {elem["font-weight"]}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Line Hight (Default : 2)"}
              </Label>
              <Input
                className="form-control"
                name="line-height"
                type="number"
                placeholder="Line Hight"
                value={
                  settingFormData?.[
                    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
                  ]?.["line-height"]
                }
                onChange={(e) => handleChangeDropDownMenuItem(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border (Default : 1 solid lightGray)"}
              </Label>
              <div className="d-flex">
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="borderPx"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
                    ]?.["borderPx"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeDropDownMenuItem(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <select
                    name="borderThik"
                    id="borderThik"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
                      ]?.["borderThik"]
                    }
                    onChange={(e) => handleChangeDropDownMenuItem(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {Border.map((elem) => {
                      return (
                        <option value={elem.border} key={elem.border}>
                          {elem.border}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
                      ]?.["borderColor"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={
                      ".custom-nav .navbar-nav .dropdown-menu .dropdown-item"
                    }
                    classProperty={"borderColor"}
                    label={"Border Color"}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in mega menu sub title</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font SIze (Default : 16)"}
              </Label>
              <Input
                className="form-control"
                name="font-size"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".custom-nav .navbar-nav .dropdown-mega .subtitle"
                ]?.["font-size"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeDropDownSubTitle(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font Weight (Default : 500)"}
              </Label>
              <select
                name="font-weight"
                id="font-weight"
                className="form-control"
                value={
                  settingFormData?.[
                    ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
                  ]?.["font-weight"]
                }
                onChange={(e) => handleChangeDropDownSubTitle(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {fontWeight.map((elem) => {
                  return (
                    <option
                      value={elem["font-weight"]}
                      key={elem["front-weight"]}
                    >
                      {elem["font-weight"]}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin Bottom (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="margin-bottom"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".custom-nav .navbar-nav .dropdown-mega .subtitle"
                ]?.["margin-bottom"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeDropDownSubTitle(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in mega menu list group item</h4>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font SIze (Default : 14)"}
              </Label>
              <Input
                className="form-control"
                name="font-size"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
                ]?.["font-size"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeListGroupItem(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Font Weight (Default : 500)"}
              </Label>
              <select
                name="font-weight"
                id="font-weight"
                className="form-control"
                value={
                  settingFormData?.[
                    ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
                  ]?.["font-weight"]
                }
                onChange={(e) => handleChangeListGroupItem(e)}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {fontWeight.map((elem) => {
                  return (
                    <option
                      value={elem["font-weight"]}
                      key={elem["font-weight"]}
                    >
                      {elem["font-weight"]}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Line Height (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="line-height"
                type="number"
                placeholder="px"
                value={
                  settingFormData?.[
                    ".custom-nav .navbar-nav .dropdown-mega .list-group .list-group-item"
                  ]?.["line-height"]
                }
                onChange={(e) => handleChangeListGroupItem(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in dropdown item before color</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
                  ]?.["background-color"]
                }
                setSettingFormData={setSettingFormData}
                className={
                  ".custom-nav .navbar-nav .dropdown-menu .dropdown-item:before"
                }
                classProperty={"background-color"}
                label={"Select Background Color"}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in menu icons links</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[".custom-nav .left-menu-icons .links"]?.[
                    "color"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".custom-nav .left-menu-icons .links"}
                classProperty={"color"}
                label={"Select Color"}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Padding (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="padding"
                type="number"
                placeholder="px"
                value={settingFormData?.[
                  ".custom-nav .left-menu-icons .links"
                ]?.["padding"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeMenuIconLinks(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Make change in menu icons links</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    ".custom-nav .login-user .dropdown-toggle"
                  ]?.["background-color"]
                }
                setSettingFormData={setSettingFormData}
                className={".custom-nav .login-user .dropdown-toggle"}
                classProperty={"background-color"}
                label={"Select Background Color"}
              />
            </Col>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[
                    ".custom-nav .login-user .dropdown-toggle"
                  ]?.["color"]
                }
                setSettingFormData={setSettingFormData}
                className={".custom-nav .login-user .dropdown-toggle"}
                classProperty={"color"}
                label={"Select Color"}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 15)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[
                  ".custom-nav .login-user .dropdown-toggle"
                ]?.["border-radius"]?.replace(/px/g, "")}
                onChange={(e) => handleChangeDropdownToggle(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border (Default : 1 solid white)"}
              </Label>
              <div className="d-flex">
                <div style={{ width: "100px" }}>
                  <Input
                    className="form-control"
                    name="borderPx"
                    type="number"
                    placeholder="px"
                    value={settingFormData?.[
                      ".custom-nav .login-user .dropdown-toggle"
                    ]?.["borderPx"]?.replace(/px/g, "")}
                    onChange={(e) => handleChangeDropdownToggle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                </div>
                <div style={{ width: "100px" }}>
                  <select
                    name="borderThik"
                    id="borderThik"
                    className="form-control"
                    value={
                      settingFormData?.[
                        ".custom-nav .login-user .dropdown-toggle"
                      ]?.["borderThik"]
                    }
                    onChange={(e) => handleChangeDropdownToggle(e)}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    <option value="">--select--</option>
                    {Border.map((elem) => {
                      return (
                        <option value={elem.border} key={elem.border}>
                          {elem.border}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <CommonColor
                    color={
                      settingFormData?.[
                        ".custom-nav .login-user .dropdown-toggle"
                      ]?.["borderColor"]
                    }
                    setSettingFormData={setSettingFormData}
                    className={".custom-nav .login-user .dropdown-toggle"}
                    classProperty={"borderColor"}
                    label={"Border Color"}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">Add Navbar Buttton Color for Small Screen</h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[".custom-nav .navbar-toggler"]?.["fill"]
                }
                setSettingFormData={setSettingFormData}
                className={".custom-nav .navbar-toggler"}
                classProperty={"fill"}
                label={"Select Color"}
              />
            </Col>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[".custom-nav .navbar-toggler"]?.[
                    "border-color"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".custom-nav .navbar-toggler"}
                classProperty={"border-color"}
                label={"Select Border color"}
              />
            </Col>
          </Row>
          <hr />
          <Row className="mt-2">
            <h4 className="mb-3">
              Make Change Menu Background Color at Small Screen
            </h4>
            <Col md="4 mb-3">
              <CommonColor
                color={
                  settingFormData?.[".custom-nav .navbar-collapse"]?.[
                    "background-color"
                  ]
                }
                setSettingFormData={setSettingFormData}
                className={".custom-nav .navbar-collapse"}
                classProperty={"background-color"}
                label={"Select Background"}
              />
            </Col>
          </Row>
          <hr />

          <CustomStyle
            handleChange={handleChangeCustomStyle}
            value={settingFormData?.custom}
          />
          <Row className="mt-2">
            <div className="d-flex align-items-center gap-4 mb-3">
              {" "}
              <h4>Change Font Family</h4>
            </div>
            <Col md="6 mb-3">
              <Label htmlFor="validationCustom03">{"Font Family"}</Label>
              <select
                name="fontFamily"
                id="fontFamily"
                className="form-select"
                value={JSON.stringify(settingFormData?.fontFamily)}
                onChange={handleChangeFontFamily}
                style={{ borderRadius: "0", color: "black" }}
              >
                <option value="">--select--</option>
                {fontListGet?.map((elem) => {
                  return (
                    <option
                      value={JSON.stringify({
                        fontFamily: elem?.fontFamily,
                        googleFontScript: elem?.googleFontScript,
                      })}
                      key={elem?.fontId}
                    >
                      {elem?.title}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default NavbarStyleThree;
