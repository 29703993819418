import React, { useContext } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import TestimonialMultipageContext from "../../../context/TestimonialMultipageContext/TestimonialMultipageContext";
import IsShowTitleORNot from "../../../common/isShowTitleORNot";
import { slugUrlConverter } from "../../../../utils/helper";
import { checkSlugUrl } from "../apis/OurTeamApi/OurTeamApi";
import { Zoom, toast } from "react-toastify";
import { TestimonialTheme } from "../../../constant";
import {
  TestimonialThemeFour,
  TestimonialThemeOne,
  TestimonialThemeThree,
  TestimonialThemeTwo,
  ThemeNo,
} from "../../../../constant";

const TestimonialParentPopup = (props) => {
  const { setFormData, fromData, isEdit } = useContext(
    TestimonialMultipageContext
  );
  const { simpleValidator } = props;

  /**
   * input value change
   * @param {event} e
   */
  const changeFormValue = (e) => {
    const { value, name, checked } = e.target;
    setFormData((prev) => {
      if (name === "isTitleShow") {
        return {
          ...prev,
          [name]: checked,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeThree) {
        return {
          ...prev,
          [name]: value,
          style: TestimonialThemeThree,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeFour) {
        return {
          ...prev,
          [name]: value,
          style: TestimonialThemeFour,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeTwo) {
        return {
          ...prev,
          [name]: value,
          style: TestimonialThemeTwo,
        };
      } else if (name === "theme" && value == ThemeNo.ThemeOne) {
        return {
          ...prev,
          [name]: value,
          style: TestimonialThemeOne,
        };
      } else if (name === "title") {
        return {
          ...prev,
          [name]: value,
          slugUrl: slugUrlConverter({ value }),
        };
      } else if (name === "slugUrl") {
        return {
          ...prev,
          [name]: slugUrlConverter({ value }),
        };
      } else {
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };
  const handleFocusChange = async () => {
    if (setFormData.slugUrl) {
      try {
        let paramObj = {
          slugUrl: setFormData.slugUrl,
          orgId: localStorage.getItem("orgId"),
        };
        await checkSlugUrl(paramObj);
      } catch (error) {
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col sm="12" xl="12">
            <div className="mt-4 d-flex flex-column justify-content-center ">
              <h6>{isEdit ? "Edit" : "Add New"}</h6>
              <Row className="mt-4">
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">
                    Title *{" "}
                    <IsShowTitleORNot
                      checked={fromData?.isTitleShow}
                      onChange={changeFormValue}
                    />
                  </Label>
                  <Input
                    className="form-control"
                    name="title"
                    type="text"
                    placeholder="Title *"
                    value={fromData?.title}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Title",
                      fromData?.title,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Slug Url *"}</Label>
                  <Input
                    className="form-control"
                    name="slugUrl"
                    type="text"
                    placeholder="Slug Url *"
                    value={fromData.slugUrl}
                    onChange={(e) => changeFormValue(e)}
                    onBlur={() => handleFocusChange()}
                    style={{ borderRadius: "0", color: "black" }}
                  />
                  <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "slugUrl",
                      fromData.slugUrl,
                      "required"
                    )}
                  </p>
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Sub Title"}</Label>
                  <Input
                    className="form-control"
                    name="subTitle"
                    type="text"
                    placeholder="Sub Title"
                    value={fromData?.subTitle}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                  {/* <p style={{ color: "red" }}>
                    {simpleValidator.current.message(
                      "Title",
                      fromData?.title,
                      "required"
                    )}
                  </p> */}
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Button text"}</Label>
                  <Input
                    className="form-control"
                    name="buttonText"
                    type="text"
                    placeholder="Button text"
                    value={fromData?.buttonText}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Item Limit"}</Label>
                  <Input
                    className="form-control"
                    name="itemLimit"
                    type="number"
                    placeholder="Item Limit"
                    value={fromData?.itemLimit}
                    onChange={(e) => changeFormValue(e)}
                    style={{ borderRadius: "0", color: "black" }}
                    required
                  />
                </Col>
                <Col md="12 mb-3">
                  <Label htmlFor="validationCustom03">{"Theme"}</Label>
                  <select
                    name="theme"
                    id="theme"
                    className="form-select"
                    onChange={(e) => changeFormValue(e)}
                    value={fromData?.theme}
                    style={{ borderRadius: "0", color: "black" }}
                  >
                    {TestimonialTheme?.map((elem) => {
                      return (
                        <option value={elem.theme}>{elem?.themeName}</option>
                      );
                    })}
                  </select>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TestimonialParentPopup;
