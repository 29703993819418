import React, { useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ORG_ADD, VALIDATION_MESSAGES } from "../../constant";
import { Trash2 } from "react-feather";
import { IconButton } from "@material-ui/core";
import { Zoom, toast } from "react-toastify";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { useParams } from "react-router";
import SettingMultiWebContext from "../../context/SettingMultiWebContext/SettingMultiWebContext";
import { CreateWebConfigPage } from "../../components ByteCard/components/MultipageByteCardBuilder/apis/NavApi/NavApi";
import { randomStr } from "../../utils/helper";

const SettingMultiWebAddWebConfigPage = ({
  ByteMiniWeb_Dashboard,
  isDashboardClick = false,
  selectedNavName = "",
}) => {
  const {
    closeModel,
    WebContentMap_List,
    modalConfig,
    WebNavBarMaster_List,
    WebContentMaster_List,
    WebNavBarMap_List,

    addField,
    setAddField,
    selectedOptions,
    setSelectedOptions,
    formvalueWebContentMaster,
    navMapID,
  } = useContext(SettingMultiWebContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  let { orgId } = useParams();

  useEffect(() => {
    WebNavBarMaster_List(orgId);
    WebContentMaster_List();
  }, []);
  useEffect(() => {
    WebContentMap_List(orgId, true);
    // WebNavBarMap_List({ orgId: orgId, showAll: true });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !addField.every((elem) => elem?.label?.length > 0 && +elem?.navMapId > 0)
    ) {
      toast?.error(VALIDATION_MESSAGES, {
        autoClose: 1000,
        transition: Zoom,
      });
    } else {
      setLoaderShow(!loaderShow);
      let formData = {
        orgId: +orgId,
        configs: addField.map(({ id, limit, ...rest }) => {
          if (limit) {
            return {
              ...rest,
              limit,
            };
          } else {
            return {
              ...rest,
            };
          }
        }),
      };
      try {
        const { data } = await CreateWebConfigPage(formData);

        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          if (isDashboardClick) {
            ByteMiniWeb_Dashboard();
          } else {
            WebNavBarMap_List({ orgId: orgId });
          }
          // WebConfigPage_List(orgId);
          closeModel();
        }
      } catch (error) {
        setLoaderShow(false);
        toast.error(error.response.data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  const removeUnSelectedDrop = ({ index }) => {
    let newSelectedOp = [...selectedOptions];
    newSelectedOp = newSelectedOp.filter((fil) => fil !== +index);
    return newSelectedOp;
  };

  const handleChangeParent = async (e, i) => {
    const { name, value, options, selectedIndex } = e.target;
    let old = [...addField];

    if (name === "contentId") {
      let newSelectedOp = removeUnSelectedDrop({
        index: old[i].contentId,
      });
      setSelectedOptions((prev) => {
        return prev.filter((fil) => fil !== old[i].contentId);
      });
      old[i][name] = +value;
      old[i]["order"] = i + 1;
      old[i]["navMapId"] = navMapID;
      old[i]["label"] = options[selectedIndex].text;
      old[i]["isLimitEnter"] =
        formvalueWebContentMaster?.find((elem) => +elem.contentId === +value)
          ?.isLimitEnter || false;

      if (newSelectedOp.includes(value)) {
        alert("This option has already been selected.");
        return;
      }
      setSelectedOptions([...newSelectedOp, +value]);
    } else {
      let old = [...addField];
      old[i][name] =
        name === "label" ? value : name === "slugUrl" ? value : value;
    }
    setAddField(old);
  };

  const deleteMoreInput = (id, index) => {
    setAddField((prev) => {
      return prev.filter((item) => item.id !== id);
    });
    let newSelectedOp = removeUnSelectedDrop({
      index,
    });
    setSelectedOptions([...newSelectedOp]);
  };

  const addMoreInput = () => {
    setAddField((prev) => {
      return [
        ...prev,
        {
          id: randomStr(2),
          order: "",
          navMapId: "",
          label: "",
          limit: "",
          contentId: "",
          isLimitEnter: false,
        },
      ];
    });
  };

  return (
    <Modal size="lg" isOpen={modalConfig}>
      {/* <LoaderResponse /> */}
      <ModalHeader toggle={() => closeModel()}>
        <span>Add Section ({selectedNavName ? selectedNavName : "Menu"})</span>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <>
                    <Row>
                      {/* <Col md="12 mb-4">
                        <Label htmlFor="validationCustom14">
                          {"Pages (Menu)"}
                        </Label>
                        <Select
                          aria-labelledby="aria-label"
                          inputId="aria-example-input"
                          defaultValue={formDataWebConfig.navBar}
                          options={formvalueNav.map((elem, i) => {
                            return {
                              value: elem.navMapId,
                              label: elem?.label || elem?.navbarDetail?.label,
                            };
                          })}
                          onChange={(opt) => {
                            handleChangeNav(opt);
                          }}
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Admin Name."}
                        </div>
                      </Col> */}
                      {addField.map((ele, i) => {
                        return (
                          <div>
                            <div className="d-flex gap-2 pt-2 align-items-center">
                              <div className="w-50">
                                <Label htmlFor="validationCustom14">
                                  {"Select Section"}
                                </Label>
                                <select
                                  name="contentId"
                                  id=""
                                  className="form-select"
                                  value={ele.contentId}
                                  onChange={(e) => handleChangeParent(e, i)}
                                >
                                  <option value="">--select--</option>
                                  {formvalueWebContentMaster.map((elem) => {
                                    return (
                                      <option
                                        value={elem.contentId}
                                        key={elem.contentId}
                                        disabled={selectedOptions.includes(
                                          elem.contentId
                                        )}
                                      >
                                        {elem.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div>
                                <Label htmlFor="validationCustom14">
                                  {"Title"}
                                </Label>
                                <input
                                  type="text"
                                  name="label"
                                  value={ele.label}
                                  className="form-control"
                                  placeholder="Enter Title"
                                  onChange={(e) => handleChangeParent(e, i)}
                                />
                              </div>
                              {ele?.isLimitEnter && (
                                <div>
                                  <Label htmlFor="validationCustom14">
                                    {"Limit"}
                                  </Label>
                                  <input
                                    type="number"
                                    name="itemLimit"
                                    value={ele.itemLimit}
                                    className="form-control"
                                    placeholder="Enter Limit"
                                    onChange={(e) => handleChangeParent(e, i)}
                                  />
                                </div>
                              )}

                              {addField.length >= 2 && (
                                <IconButton
                                  edge="start"
                                  color="inherit"
                                  style={{
                                    marginLeft: "5px",
                                    color: "red",
                                    marginTop: "19px",
                                  }}
                                  onClick={() =>
                                    deleteMoreInput(ele.id, ele.contentId)
                                  }
                                >
                                  <Trash2 />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-center pt-2">
                        <Button onClick={() => addMoreInput()} color="primary">
                          + Add More
                        </Button>
                      </div>
                    </Row>
                  </>
                  <Row style={{ paddingTop: "30px" }}>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                      // onClick={createhwId}
                    >
                      {ORG_ADD.SUBMIT}
                    </button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SettingMultiWebAddWebConfigPage;
