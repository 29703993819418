export const btnName = {
  THEME: "Select Your Card Color",
  BUSINESS: "Manage Your Business Details",
  COMPANY: "Add / View Company Logo, Tagline & Services",
  SEO: "Manage Your Company Appearance On Google",
  ABOUT_US: "Write About Your Company",
  PRODUCTS: "Add / View Product OR Service",
  VIDEOS: "Add / View Video",
  DOWNLOADS: "Add / View Download",
  // IMAGES: "Manage Images",
  IMAGES: "Add / View Images",
  PAYMETDETAILS_QR: "Manage Payment Details",
  CONTACT_US: "Add / View Contact Us",
  SOCIAL_MEDIA: "Add / View Social Media Link",
  TESTIMONIAL: "Add / View TestiMonial",
  DRAG_DROP: "Set Your Section Position",
  SLIDER: "Add / View Slider Image",
  OUR_TEAM: "Manage Our Team",
  DETAILS: "Manage Details",
  SERVICE: "Manage Services",
  OUR_CLIENT: "Manage Our Client",
  B_W_T_And_BUTTON: "Manage Banner",
  PRODUCT_FEATURES: "Manage Product Features",
  FAQS: "Manage FAQ's",
  DATA_COUNTER: "Manage Data Counter",
  BLOG: "Manage Blog",
  PACKAGES: "Manage Packages",
  PAYMENT_ITEMS: "Add / View Items",
  ITEM_FEATURE: "Manage Item Feature",
  VIDEO_BANNER: "Manage Video Banner",
  DOWNLOAD: "Manage Download",
  SERVICE_CATEGORY: "Manage Service category",
  STEPS: "Manage Steps",
  SERVICE_COUNTER: "Manage Service Counter",
  LATEST_NEWS: "Manage Latest News",
  CAREER: "Manage Career",
  PRODUCT: "Manage Product",
  POPUP_DIALOG: "Manage Popup Dialog",
};

/** model open cases name */
export const Cases = {
  THEME: "theme",
  BUSINESS: "business",
  SUB_DOMAIN: "subDomain",
  COMPANY: "company",
  SEO: "seo",
  POPUP_DIALOG: "popupDialog",
  ABOUT_US: "aboutus",
  ABOUT_US_PARENT: "aboutUsParent",
  PRODUCTS: "product",
  PRODUCTS_PARENT: "productParent",
  PRODUCT_UPLOAD: "productUpload",
  VIDEOS: "video",
  VIDEOS_PARENT: "videoParent",
  DOWNLOADS: "downloads",
  IMAGES: "images",
  IMAGES_PARENT: "imagesParent",
  IMAGES_CHILES: "imageChilds",
  VIDEOS_CHILES: "videosChilds",
  LATEST_NEWS_PARENT: "latestNewsParent",
  SETTING_LATEST_PARENT: "settingLatestParent",
  LATEST_NEWS: "latestNews",
  ITEM_FEATURE_PARENT: "itemFeatureParent",
  SETTING_ITEM_PARENT: "settingItemParent",
  ITEM_FEATURE: "itemFeature",
  PACKAGES_PARENT: "packagesParent",
  PACKAGES: "packages",
  SERVICE_COUNTER_PARENT: "serviceCounterParent",
  SERVICE_COUNTER: "serviceCounter",
  OUR_CLIENT_PARENT: "ourClientParent",
  OUR_CLIENT: "ourClient",
  IMAGE_BANNER_PARENT: "imageBannerParent",
  IMAGE_BANNER: "imageBanner",
  VIDEO_BANNER_PARENT: "videoBannerParent",
  VIDEO_BANNER: "videoBanner",
  FAQS_PARENT: "faqsParent",
  FAQS: "faqs",
  INQUIRY: "inquiry",
  INQUIRY_PARENT: "inquiryParent",
  STEPS_PARENT: "stepsParent",
  STEPS: "steps",
  CAREER_PARENT: "careerParent",
  CAREER: "career",
  GET_WHATSAPP_LINK_PARENT: "getWhatsAppLinkParent",
  GET_WHATSAPP_LINK: "getWhatsAppLink",
  SLIDER_MULTIPAGE_PARENT: "sliderMultipageParent",
  SLIDER_MULTIPAGE: "sliderMultipage",
  TESTIMONIAL_MULTIPAGE_PARENT: "testimonialMultipageParent",
  TESTIMONIAL_MULTIPAGE: "testimonialMultipage",
  DOWNLOAD_MULTIPAGE_PARENT: "downloadMultipageParent",
  DOWNLOAD_MULTIPAGE: "downloadMultipage",
  SOCIALMEDIA_MULTIPAGE_PARENT: "socialMediaMultipageParent",
  SOCIALMEDIA_MULTIPAGE: "socialMediaMultipage",
  SETTING_IMAGES_PARENT: "settingImagesParent",
  SETTING_ABOUT_PARENT: "settingAboutParent",
  SETTING_SLIDER_PARENT: "settingSliderParent",
  SETTING_SOCIAL_MEDIA_PARENT: "settingSocialMediaParent",
  SETTING_PACKAGES_PARENT: "settingPackagesParent",
  SETTING_SERVICECOUNTER_PARENT: "settingServiceCounterParent",
  PAYMETDETAILS_QR: "paymentDetails",
  CONTACT_US: "contactus",
  SOCIAL_MEDIA: "socialmedia",
  TESTIMONIAL: "testimonial",
  DRAG_DROP: "drag_drop",
  SLIDER: "slider",
  OUR_TEAM: "ourTeam",
  OUR_TEAM_MEMBER: "ourTeamMember",
  PAGE_TITLE: "pageTitle",
  BLOG: "blog",
  BLOG_DETAIL: "blogDetail",
  SERVICE_: "service_",
  SERVICE_CATEGORY: "serviceCategory",
  DETAILS: "details",
  SERVICE: "service",
  B_W_T_And_BUTTON: "B_W_T_And_Button",
  PRODUCT_FEATURES: "productFeatures",
  DATA_COUNTER: "dataCounter",
  PAYMENT: "payment",
  PAYMENT_ITEM: "paymentItem",
  SETTING_VIDEO_BANNER: "settingVideoParent",
  SETTING_FAQS_PARENT: "settingFaqsParent",
  SETTING_INQUIRY_PARENT: "settingInquiryParent",
  SETTING_OUR_CLIENT_PARENT: "settingOurClientParent",
  SETTING_IMAGE_BANNER_PARENT: "settingImageBannerParent",
  SETTING_IMAGE_MULTI_PAGE_PARENT: "settingImageMultiPageParent",
  SETTING_CONtACT_US_PARENT: "settingContactUsParent",
  SETTING_SERVICE_CATEGORY_PARENT: "settingServiceCategoryParent",
  SETTING_OUR_TEAM_PARENT: "settingOurTeamParent",
  SETTING_DOWNLOAD_PARENT: "SettingDownloadParent",
  SETTING_BLOG_PARENT: "SettingBlogParent",
  SETTING_TESTIMONIAL_PARENT: "SettingTestimonialParent",
  SETTING_GET_LINK_START_PARENT: "SettingGetLinkStartParent",
  SETTING_STEPS_PARENT: "SettingStepsParent",
  SETTING_CAREER_PARENT: "SettingCareerParent",
  SETTING_CONTACT_US_PARENT: "SettingContactUsParent",
  SETTING_DETAILS_PARENT: "SettingDetailsParent",
  SETTING_PAGE_TITLE_PARENT: "SettingPageTitleParent",
  SETTING_VIDEO_PARENT: "SettingVideoParent",
  SETTING_PRODUCT_PARENT: "SettingProductParent",
  POPUP_DIALOG_PARENT: "popupDialogParent",
  SETTING_POPUP_DIALOG_PARENT: "SettingPopupDialogParent",
  SETTING_NAVBAR_PARENT: "SettingNavbarParent",
  SETTING_FOOTER: "SettingFooter",
  FOOTER_FORM: "Footer Form",
  PRODUCT_BUY: "ProductBuy",
  FOOTER: "Footer",
  FOOTER_TOP_HEADER: "FooterTopHeader",
  FOOTER_ONE: "FooterOne",
  FOOTER_TWO: "FooterTwo",
  SETTING_PRODUCT_BUY_PARENT: "settingProductBuyParent",
  SETTING_PRODUCT_BUY_PARENT_STYLE: "settingProductBuyParentStyle",
  SETTING_PRODUCT_BUY_PARENT_CHECk_OUT_STYLE:
    "settingProductBuyParentCheckOutStyle",
  SETTING_PRODUCT_BUY_PARENT_MY_ACCOUNT_STYLE:
    "settingProductBuyParentMyAccountStyle",
  SETTING_PRODUCT_BUY_PARENT_PAGE: "settingProductBuyParentPage",
  SETTING_PRODUCT_BUY_PARENT_CART_PAGE: "settingProductBuyParentCartPage",
  SETTING_FOOTER_PARENT: "settingFooterParent",
  SETTING_FOOTER_TOP_HEADER: "settingFooterTopHeader",
  SETTING_FOOTER_STYLE_ONE: "settingFooterStyleOne",
  SETTING_FOOTER_STYLE_TWO: "settingFooterStyleTwo",
  SETTING_COMMON_STYLE_PARENT: "SettingCommonStyleParent",
  CUSTOM_FORM_PARENT: "CustomFormParent",
  CUSTOM_FORM_POPUP: "CustomFormPopup",
};

/**model state object name  */
export const StateObjectPopup = {
  COLOR_POPUP: "colorPopup",
  BUSINESS_POPUP: "businessPopup",
  SUB_DOMAIN_POPUP: "subDomainPopup",
  COMPANY_POPUP: "companyPopup",
  SEO_POPUP: "seoPopup",
  ABOUT_US_POPUP: "aboutUsPopup",
  PRODUCTS_POPUP: "productsPopup",
  PRODUCTS_PARENT_POPUP: "productsParentPopup",
  PRODUCTS_UPLOAD_POPUP: "productUploadPopup",
  VIDEOS_POPUP: "videosPopup",
  VIDEOS_PARENT_POPUP: "videosParentPopup",
  DOWNLOADS_POPUP: "downloadsPopup",
  IMAGES_POPUP: "imagesPopup",
  IMAGES_PARENT_POPUP: "imagesParentPopup",
  IMAGES_CHILD_POPUP: "imagesChildPopup",
  VIDEOS_CHILD_POPUP: "videosChildPopup",
  LATEST_NEWS_POPUP: "latestNewsPopup",
  LATEST_NEWS_CHILD_POPUP: "latestNewsChildPopup",
  SLIDER_MULTIPAGE_POPUP: "sliderMultipagePopup",
  SLIDER_MULTIPAGE_CHILD_POPUP: "sliderMultipageChildPopup",
  INQUIRY_POPUP: "inquiryPopup",
  INQUIRY_CHILD_POPUP: "inquiryChildPopup",
  INQUIRY_PARENT_POPUP: "inquirySettingPopup",
  TESTIMONIAL_MULTIPAGE_POPUP: "testimonialMultipagePopup",
  TESTIMONIAL_MULTIPAGE_CHILD_POPUP: "testimonialMultipageChildPopup",
  DOWNLOAD_MULTIPAGE_POPUP: "downloadMultipagePopup",
  DOWNLOAD_MULTIPAGE_CHILD_POPUP: "downloadMultipageChildPopup",
  SOCIALMEDIA_MULTIPAGE_POPUP: "socialMediaMultipagePopup",
  SOCIALMEDIA_MULTIPAGE_CHILD_POPUP: "socialMediaMultipageChildPopup",
  ITEM_FEATURE_POPUP: "itemFeaturePopup",
  ITEM_FEATURE_CHILD_POPUP: "itemFeatureChildPopup",
  PACKAGES_POPUP: "packagesPopup",
  PACKAGES_CHILD_POPUP: "packagesChildPopup",
  PAYMETDETAILS_QR_POPUP: "paymentdetails_qrPopup",
  CONTACT_US_POPUP: "contactusPopup",
  SOCIAL_MEDIA_POPUP: "socialmediaPopup",
  TESTIMONIAL_POPUP: "testimonialPopup",
  DRAG_DROP_POPUP: "dragDropPopup",
  SLIDER_POPUP: "sliderPopup",
  OUR_TEAM_POPUP: "ourteamPopup",
  OUR_TEAM_MEMBER_POPUP: "ourteamMemberPopup",
  BLOG_POPUP: "blogPopup",
  BLOG_DETAIL_POPUP: "blogDetailPopup",
  SERVICE_CATEGORY_POPUP: "serviceCategory",
  SERVICE__POPUP: "service_",
  DETAILS_POPUP: "detailsPopup",
  SERVICE_POPUP: "servicePopup",
  B_W_T_And_BUTTON_POPUP: "B_W_T_And_Button_Popup",
  PRODUCT_FEATURES_POPUP: "productFeaturesPopup",
  DATA_COUNTER_POPUP: "dataCounterPopup",
  PAYMENT_POPUP: "paymentPopup",
  PAYMENT_ITEMS_POPUP: "paymentItemsPopup",
  SETTING_POPUP: "settingPopup",
  ABOUT_SETTING_POPUP: "aboutSettingPopup",
  SLIDER_SETTING_POPUP: "sliderSettingPopup",
  PACKAGES_SETTING_POPUP: "packagesSettingPopup",
  ITEM_SETTING_POPUP: "itemSettingPopup",
  ABOUT_US_PARENT_POPUP: "aboutUsParentPopup",
  LATEST_PARENT_POPUP: "latestSettingPopup",
  SERVICE_COUNTER_PARENT_POPUP: "serviceCOunterSettingPopup",
  SERVICE_COUNTER_POPUP: "serviceCounterPopup",
  SERVICE_COUNTER_CHILD_POPUP: "serviceCounterChildPopup",
  OUR_CLIENT_POPUP: "ourClientPopup",
  OUR_CLIENT_CHILD_POPUP: "ourClientChildPopup",
  PAGE_TITLE_POPUP: "pageTitlePopup",
  IMAGE_BANNER_POPUP: "imageBannerPopup",
  IMAGE_BANNER_CHILD_POPUP: "imageBannerChildPopup",
  VIDEO_BANNER_POPUP: "videoBannerPopup",
  VIDEO_BANNER_CHILD_POPUP: "videoBannerChildPopup",
  VIDEO_BANNER_SETTING_POPUP: "videoBannerSettingPopup",
  FAQS_POPUP: "faqsPopup",
  FAQS_CHILD_POPUP: "faqsChildPopup",
  STEPS_POPUP: "stepsPopup",
  STEPS_CHILD_POPUP: "stepsChildPopup",
  FAQS_PARENT_POPUP: "faqsParentPopup",
  CAREER_CHILD_POPUP: "careerChildPopup",
  GET_WHATSAPP_LINK_POPUP: "getWhatsAppLinkPopup",
  GET_WHATSAPP_LINK_CHILD_POPUP: "getWhatsAppLinkChildPopup",
  IMAGE_BANNER_SETTING_POPUP: "imageBannerSettingPopup",
  SERVICE_CATEGORY_SETTING_POPUP: "serviceAndCategorySettingPopup",
  SOCIAL_MEDIA_SETTING_POPUP: "socialMediaSettingPopup",
  IMAGE_MULTI_PAGE_SETTING_POPUP: "imageMultiPageSettingPopup",
  OUR_TEAM_SETTING_POPUP: "ourTeamSettingPopup",
  DOWNLOAD_SETTING_POPUP: "downloadSettingPopup",
  BLOG_SETTING_POPUP: "blogSettingPopup",
  TESTIMONIAL_SETTING_POPUP: "testimonialSettingPopup",
  GET_LINK_START_SETTING_POPUP: "getLinkStartSettingPopup",
  STEPS_PARENT_POPUP: "stepsParenPopup",
  CAREER_POPUP: "careerPopup",
  CAREER_SETTING_POPUP: "careerSettingPopup",
  CONTACT_US_SETTING_POPUP: "contactUsSettingPopup",
  OUR_CLIENT_PARENT_POPUP: "ourClientParentSetting",
  DETAILS_PARENT_POPUP: "detailsSettingPopup",
  PAGE_TITLE_PARENT_POPUP: "pageTitleSettingPopup",
  VIDEO_PARENT_POPUP: "videoParentSetting",
  PRODUCT_PARENT_POPUP: "productSettingPopup",
  POPUP_DIALOG_POPUP: "popupDialogPopup",
  POPUP_DIALOG_CHILD_POPUP: "popupDialogChildPopup",
  POPUP_DIALOG_CHILD_SETTING_POPUP: "popupDialogSettingPopup",
  NAVBAR_SETTING_POPUP: "navbarSettingPopup",
  PRODUCT_BUY_POPUP: "productBuyPopup",
  PRODUCT_BUY_SETTING_POPUP: "productBuySettingPopup",
  PRODUCT_BUY_SETTING_POPUP_STYLE: "productBuySettingPopupStyle",
  PRODUCT_BUY_SETTING_POPUP_CHECK_OUT_STYLE:
    "productBuySettingPopupCheckOutStyle",
  PRODUCT_BUY_SETTING_POPUP_MY_ACCOUNT_STYLE:
    "productBuySettingPopupMyAccountStyle",
  PRODUCT_BUY_SETTING_POPUP_PAGE_STYLE: "productBuySettingPopupPageStyle",
  PRODUCT_BUY_SETTING_POPUP_CART_PAGE_STYLE:
    "productBuySettingPopupCartPageStyle",
  FOOTER_SETTING_POPUP: "footerSettingPopup",
  FOOTER_POPUP: "footerPopup",
  FOOTER_TOP_HEADER_STYLE_POPUP: "footerTopHeaderStylePopup",
  FOOTER_ONE_STYLE_POPUP: "footerOneStylePopup",
  FOOTER_TWO_STYLE_POPUP: "footerTwoStylePopup",
  FOOTER_TOP_HEADER_POPUP: "footerTopHeaderPopup",
  FOOTER_ONE: "footerOnePopup",
  FOOTER_TWO: "footerTwoPopup",
  SETTING_FOOTER: "settingFooter",
  FOOTER_FORM_POPUP: "footerFormPopup",
  COMMON_STYLE_POPUP: "commonStyleSettingPopup",
  CUSTOM_FORM_POPUP: "customFormPopup",
  CUSTOM_FORM_CHILD_POPUP: "customFormChildPopup",
};

export const modalsName = {
  SELECT_THEME: "Select Color",
  BUSINESS_DETAILS: "Business Details",
  SET_SUB_DOMAIN: "Set Subdomain",
  COMPANY_LOGO: "Company Logo, Tagline & Services",
  SEO_DETAILS: "SEO Details",
  ABOUTUS: "About Us",
  ABOUT_US_PARENT: "About Us",
  PRODUCTS: "Products",
  VIDEOS: "Videos Items",
  VIDEOS_PARENT: "Create Video Section",
  DOWNLOADS: "Downloads",
  IMAGES: "Images Items",
  POPUP_DIALOG: "Popup Dialog",
  IMAGES_PARENT: "Create Gallery Section",
  IMAGES_CHILES: "Image Gallery Sub Child",
  LATEST_NEWS_PARENT: "Latest News",
  LATEST_NEWS: "Latest News Items",
  ITEM_FEATURE_PARENT: "Item Feature",
  ITEM_FEATURE: "Item Feature Items",
  PACKAGES_PARENT: "Packages",
  PRODUCT_BUY: "Product Buy",
  FOOTER: "Footer",
  FOOTER_TOP_HEADER: "Top Header",
  PACKAGES: "Packages Items",
  SERVICE_COUNTER_PARENT: "Service Counter",
  SERVICE_COUNTER: "Service Counter Items",
  OUR_CLIENT_PARENT: "Our Client",
  OUR_CLIENT: "Our Client Items",
  IMAGE_BANNER_PARENT: "Image Banner",
  IMAGE_BANNER: "Image Banner Items",
  VIDEO_BANNER_PARENT: "Video Banner",
  VIDEO_BANNER: "Video Banner Items",
  FAQS_PARENT: "FAQ's",
  INQUIRY_PARENT: "Inquiry",
  INQUIRY: "Inquiry Items",
  FAQS: "FAQ's Items",
  STEPS_PARENT: "Steps",
  STEPS: "Steps Items",
  CAREER_PARENT: "Career",
  CAREER: "Career Item",
  GET_WHATSAPP_LINK_PARENT: "Get Link On Whats App",
  GET_WHATSAPP_LINK: "Get Link On Whats App Item",
  SETTING_IMAGES_PARENT: "Setting",
  SETTING_PACKAGES_PARENT: "Setting",
  SETTING_SERVICECOUNTER_PARENT: "Setting",
  SETTING_ABOUT_PARENT: "Setting",
  SETTING_LATEST_PARENT: "Latest News",
  SETTING_SLIDER_PARENT: "Setting",
  SETTING_SOCIAL_MEDIA_PARENT: "Setting",
  PAYMENT_DETAILS: "Payment Detail",
  CONATCTUS: "Contact Us",
  SOCIALMEDIA: "Social Media",
  TESTIMONIAL: "Testimonial",
  DRAG_DROP: "Section Position",
  SLIDER: "Slider Item",
  SLIDER_PARENT: "Slider ",
  TESTIMONIAL_MULTIPAGE: "Testimonial Item",
  TESTIMONIAL_MULTIPAGE_PARENT: "Testimonial ",
  DOWNLOAD_MULTIPAGE: "Download Item",
  DOWNLOAD_MULTIPAGE_PARENT: "Download ",
  PRODUCT_MULTIPAGE_PARENT: "Products Parent ",
  SOCIALMEDIA_MULTIPAGE: "Social Media Item",
  SOCIALMEDIA_MULTIPAGE_PARENT: "Social Media ",
  OUR_TEAM: "Our Team",
  OUR_TEAM_MEMBER: "Our Team Member",
  PAGE_TITLE: "Page Title",
  BLOG: "Blog",
  BLOG_DETAIL: "Blog Detail",
  SERVICE_: "Service",
  SERVICE_CATEGORY: "Service Category",
  DETAILS: "Details",
  SERVICE: "Service",
  B_W_T_And_BUTTON: "Banner with Title & Button",
  PRODUCT_FEATURES: "Product Features",
  DATA_COUNTER: "Data Counter",
  PAYMENT: "Create Payment Section",
  PAYMENT_ITEMS: "Payment Items",
  SETTING_ITEM_PARENT: "Setting",
  SETTING_VIDEO_BANNER: "Setting",
  SETTING_OUR_CLIENT_PARENT: "Setting",
  SETTING_IMAGE_BANNER_PARENT: "Setting",
  SETTING_SERVICE_CATEGORY_PARENT: "Setting",
  SETTING_OUR_TEAM_PARENT: "Setting",
  SETTING_DOWNLOAD_PARENT: "Setting",
  SETTING_BLOG_PARENT: "Setting",
  SETTING_GET_LINK_START_PARENT: "Setting",
  SETTING_STEPS_PARENT: "Setting",
  SETTING_CAREER_PARENT: "Setting",
  SETTING_CONTACT_US_PARENT: "Setting",
  SETTING_DETAILS_PARENT: "Setting",
  SETTING_PAGE_TITLE_PARENT: "Setting",
  SETTING_VIDEO_PARENT: "Setting",
  SETTING_PRODUCT_PARENT: "Setting",
  POPUP_DIALOG_PARENT: "Popup Dialog",
  SETTING_POPUP_DIALOG_PARENT: "Setting",
  FOOTER_POPUP: "Footer",
  SETTING_PRODUCT_BUY_PARENT: "Setting",
  SETTING_PRODUCT_BUY_PARENT_STYLE: "Product Style Setting",
  SETTING_PRODUCT_BUY_PARENT_CHECk_OUT_STYLE: "CheckOut Style Setting",
  SETTING_PRODUCT_BUY_PARENT_MY_ACCOUNT_STYLE: "My Account Style Setting",
  SETTING_PRODUCT_BUY_PARENT_PAGE: "Product Page Style Setting",
  SETTING_PRODUCT_BUY_PARENT_CART_PAGE: "Cart Page Style Setting",
  SETTING_FOOTER_PARENT: "Setting",
  SETTING_FOOTER_TOP_HEADER: "Top Header Setting",
  SETTING_FOOTER_STYLE_ONE: "Footer 1 Setting",
  SETTING_FOOTER_STYLE_TWO: "Footer 2 Setting",
  FOOTER_ONE: "Footer Theme 1",
  FOOTER_TWO: "Footer Theme 2",
  SETTING_COMMON_STYLE_PARENT: "Setting",
  CUSTOM_FORM_PARENT: "Custom Form",
  CUSTOM_FORM_POPUP: "Custom Popup",
  SETTING_FAQS_PARENT: "Faq's",
};

export const webId = 1;
/**
 * confirmation message
 */

export const DELETE_CONFIRM = "Are you sure you want to delete ?";

/**
 * dial code array
 */
export const options = [
  { value: "+93", label: "+93" },
  { value: "+355", label: "+355" },
  { value: "+213", label: "+213" },
  { value: "+1-684", label: "+1-684" },
  { value: "+376", label: "+376" },
  { value: "+244", label: "+244" },
  { value: "+1-264", label: "+1-264" },
  { value: "+672", label: "+672" },
  { value: "+1-268", label: "+1-268" },
  { value: "+54", label: "+54" },
  { value: "+374", label: "+374" },
  { value: "+297", label: "+297" },
  { value: "+61", label: "+61" },
  { value: "+43", label: "+43" },
  { value: "+994", label: "+994" },
  { value: "+1-242", label: "+1-242" },
  { value: "+973", label: "+973" },
  { value: "+880", label: "+880" },
  { value: "+1-246", label: "+1-246" },
  { value: "+375", label: "+375" },
  { value: "+32", label: "+32" },
  { value: "+501", label: "+501" },
  { value: "+229", label: "+229" },
  { value: "+1-441", label: "+1-441" },
  { value: "+975", label: "+975" },
  { value: "+591", label: "+591" },
  { value: "+387", label: "+387" },
  { value: "+267", label: "+267" },
  { value: "+55", label: "+55" },
  { value: "+246", label: "+246" },
  { value: "+1-284", label: "+1-284" },
  { value: "+673", label: "+673" },
  { value: "+359", label: "+359" },
  { value: "+226", label: "+226" },
  { value: "+257", label: "+257" },
  { value: "+855", label: "+855" },
  { value: "+237", label: "+237" },
  { value: "+1", label: "+1" },
  { value: "+238", label: "+238" },
  { value: "+1-345", label: "+1-345" },
  { value: "+236", label: "+236" },
  { value: "+235", label: "+235" },
  { value: "+56", label: "+56" },
  { value: "+86", label: "+86" },
  { value: "+61", label: "+61" },
  { value: "+61", label: "+61" },
  { value: "+57", label: "+57" },
  { value: "+269", label: "+269" },
  { value: "+682", label: "+682" },
  { value: "+506", label: "+506" },
  { value: "+385", label: "+385" },
  { value: "+53", label: "+53" },
  { value: "+599", label: "+599" },
  { value: "+357", label: "+357" },
  { value: "+420", label: "+420" },
  { value: "+243", label: "+243" },
  { value: "+45", label: "+45" },
  { value: "+253", label: "+253" },
  { value: "+1-767", label: "+1-767" },
  { value: "+1-809, 1-829, 1-849", label: "+1-809, 1-829, 1-849" },
  { value: "+670", label: "+670" },
  { value: "+593", label: "+593" },
  { value: "+20", label: "+20" },
  { value: "+503", label: "+503" },
  { value: "+240", label: "+240" },
  { value: "+291", label: "+291" },
  { value: "+372", label: "+372" },
  { value: "+251", label: "+251" },
  { value: "+500", label: "+500" },
  { value: "+298", label: "+298" },
  { value: "+679", label: "+679" },
  { value: "+358", label: "+358" },
  { value: "+33", label: "+33" },
  { value: "+689", label: "+689" },
  { value: "+241", label: "+241" },
  { value: "+220", label: "+220" },
  { value: "+995", label: "+995" },
  { value: "+49", label: "+49" },
  { value: "+233", label: "+233" },
  { value: "+350", label: "+350" },
  { value: "+30", label: "+30" },
  { value: "+299", label: "+299" },
  { value: "+1-473", label: "+1-473" },
  { value: "+1-671", label: "+1-671" },
  { value: "+502", label: "+502" },
  { value: "+44-1481", label: "+44-1481" },
  { value: "+224", label: "+224" },
  { value: "+245", label: "+245" },
  { value: "+592", label: "+592" },
  { value: "+509", label: "+509" },
  { value: "+504", label: "+504" },
  { value: "+852", label: "+852" },
  { value: "+36", label: "+36" },
  { value: "+354", label: "+354" },
  { value: "+91", label: "+91" },
  { value: "+62", label: "+62" },
  { value: "+98", label: "+98" },
  { value: "+964", label: "+964" },
  { value: "+353", label: "+353" },
  { value: "+44-1624", label: "+44-1624" },
  { value: "+972", label: "+972" },
  { value: "+39", label: "+39" },
  { value: "+225", label: "+225" },
  { value: "+1-876", label: "+1-876" },
  { value: "+81", label: "+81" },
  { value: "+44-1534", label: "+44-1534" },
  { value: "+962", label: "+962" },
  { value: "+7", label: "+7" },
  { value: "+254", label: "+254" },
  { value: "+686", label: "+686" },
  { value: "+383", label: "+383" },
  { value: "+965", label: "+965" },
  { value: "+996", label: "+996" },
  { value: "+856", label: "+856" },
  { value: "+371", label: "+371" },
  { value: "+961", label: "+961" },
  { value: "+266", label: "+266" },
  { value: "+231", label: "+231" },
  { value: "+218", label: "+218" },
  { value: "+423", label: "+423" },
  { value: "+370", label: "+370" },
  { value: "+352", label: "+352" },
  { value: "+853", label: "+853" },
  { value: "+389", label: "+389" },
  { value: "+261", label: "+261" },
  { value: "+265", label: "+265" },
  { value: "+60", label: "+60" },
  { value: "+960", label: "+960" },
  { value: "+223", label: "+223" },
  { value: "+356", label: "+356" },
  { value: "+692", label: "+692" },
  { value: "+222", label: "+222" },
  { value: "+230", label: "+230" },
  { value: "+262", label: "+262" },
  { value: "+52", label: "+52" },
  { value: "+691", label: "+691" },
  { value: "+373", label: "+373" },
  { value: "+377", label: "+377" },
  { value: "+976", label: "+976" },
  { value: "+382", label: "+382" },
  { value: "+1-664", label: "+1-664" },
  { value: "+212", label: "+212" },
  { value: "+258", label: "+258" },
  { value: "+95", label: "+95" },
  { value: "+264", label: "+264" },
  { value: "+674", label: "+674" },
  { value: "+977", label: "+977" },
  { value: "+31", label: "+31" },
  { value: "+599", label: "+599" },
  { value: "+687", label: "+687" },
  { value: "+64", label: "+64" },
  { value: "+505", label: "+505" },
  { value: "+227", label: "+227" },
  { value: "+234", label: "+234" },
  { value: "+683", label: "+683" },
  { value: "+850", label: "+850" },
  { value: "+1-670", label: "+1-670" },
  { value: "+47", label: "+47" },
  { value: "+968", label: "+968" },
  { value: "+92", label: "+92" },
  { value: "+680", label: "+680" },
  { value: "+970", label: "+970" },
  { value: "+507", label: "+507" },
  { value: "+675", label: "+675" },
  { value: "+595", label: "+595" },
  { value: "+51", label: "+51" },
  { value: "+63", label: "+63" },
  { value: "+64", label: "+64" },
  { value: "+48", label: "+48" },
  { value: "+351", label: "+351" },
  { value: "+1-787, 1-939", label: "+1-787, 1-939" },
  { value: "+974", label: "+974" },
  { value: "+242", label: "+242" },
  { value: "+262", label: "+262" },
  { value: "+40", label: "+40" },
  { value: "+7", label: "+7" },
  { value: "+250", label: "+250" },
  { value: "+590", label: "+590" },
  { value: "+290", label: "+290" },
  { value: "+1-869", label: "+1-869" },
  { value: "+1-758", label: "+1-758" },
  { value: "+590", label: "+590" },
  { value: "+508", label: "+508" },
  { value: "+1-784", label: "+1-784" },
  { value: "+685", label: "+685" },
  { value: "+378", label: "+378" },
  { value: "+239", label: "+239" },
  { value: "+966", label: "+966" },
  { value: "+221", label: "+221" },
  { value: "+381", label: "+381" },
  { value: "+248", label: "+248" },
  { value: "+232", label: "+232" },
  { value: "+65", label: "+65" },
  { value: "+1-721", label: "+1-721" },
  { value: "+421", label: "+421" },
  { value: "+386", label: "+386" },
  { value: "+677", label: "+677" },
  { value: "+252", label: "+252" },
  { value: "+27", label: "+27" },
  { value: "+82", label: "+82" },
  { value: "+211", label: "+211" },
  { value: "+34", label: "+34" },
  { value: "+94", label: "+94" },
  { value: "+249", label: "+249" },
  { value: "+597", label: "+597" },
  { value: "+47", label: "+47" },
  { value: "+268", label: "+268" },
  { value: "+46", label: "+46" },
  { value: "+41", label: "+41" },
  { value: "+963", label: "+963" },
  { value: "+886", label: "+886" },
  { value: "+992", label: "+992" },
  { value: "+255", label: "+255" },
  { value: "+66", label: "+66" },
  { value: "+228", label: "+228" },
  { value: "+690", label: "+690" },
  { value: "+676", label: "+676" },
  { value: "+1-868", label: "+1-868" },
  { value: "+216", label: "+216" },
  { value: "+90", label: "+90" },
  { value: "+993", label: "+993" },
  { value: "+1-649", label: "+1-649" },
  { value: "+688", label: "+688" },
  { value: "+1-340", label: "+1-340" },
  { value: "+256", label: "+256" },
  { value: "+380", label: "+380" },
  { value: "+971", label: "+971" },
  { value: "+44", label: "+44" },
  { value: "+1", label: "+1" },
  { value: "+598", label: "+598" },
  { value: "+998", label: "+998" },
  { value: "+678", label: "+678" },
  { value: "+379", label: "+379" },
  { value: "+58", label: "+58" },
  { value: "+84", label: "+84" },
  { value: "+681", label: "+681" },
  { value: "+212", label: "+212" },
  { value: "+967", label: "+967" },
  { value: "+260", label: "+260" },
  { value: "+263", label: "+263" },
];
/**
 * currency array
 */
export const Currency = [
  { label: "United Arab Emirates Dirham (د.إ)", value: "د.إ" },
  { label: "Afghan Afghani (Af)", value: "Af" },
  { label: "Albanian Lek (Lek)", value: "Lek" },
  { label: "Armenian Dram (դ)", value: "դ" },
  { label: "Netherlands Antillean Guilder (ƒ)", value: "ƒ" },
  { label: "Angolan Kwanza (Kz)", value: "Kz" },
  { label: "Argentine Peso ($)", value: "$" },
  { label: "Australian Dollar ($)", value: "$" },
  { label: "Aruban Florin (ƒ)", value: "ƒ" },
  { label: "Azerbaijani Manat (₼)", value: "₼" },
  { label: "Bosnia-Herzegovina Convertible Mark (KM)", value: "KM" },
  { label: "Barbadian Dollar ($)", value: "$" },
  { label: "Bangladeshi Taka (৳)", value: "৳" },
  { label: "Bulgarian Lev (лв)", value: "лв" },
  { label: "Bahraini Dinar (.د.ب)", value: ".د.ب" },
  { label: "Burundian Franc (FBu)", value: "FBu" },
  { label: "Bermudian Dollar ($)", value: "$" },
  { label: "Brunei Dollar ($)", value: "$" },
  { label: "Bolivian Boliviano ($b)", value: "$b" },
  { label: "Brazilian Real (R$)", value: "R$" },
  { label: "Bahamian Dollar ($)", value: "$" },
  { label: "Bhutanese Ngultrum (Nu.)", value: "Nu." },
  { label: "Botswana Pula (P)", value: "P" },
  { label: "Belarusian Ruble (p.)", value: "p." },
  { label: "Belize Dollar (BZ$)", value: "BZ$" },
  { label: "Canadian Dollar ($)", value: "$" },
  { label: "Congolese Franc (FC)", value: "FC" },
  { label: "Swiss Franc (CHF)", value: "CHF" },
  { label: "Chilean Unit of Account (UF) (UF)", value: "UF" },
  { label: "Chilean Peso ($)", value: "$" },
  { label: "Chinese Yuan (¥)", value: "¥" },
  { label: "Colombian Peso ($)", value: "$" },
  { label: "Costa Rican Colón (₡)", value: "₡" },
  { label: "Cuban Peso (⃌)", value: "⃌" },
  { label: "Cape Verdean Escudo ($)", value: "$" },
  { label: "Czech Koruna (Kč)", value: "Kč" },
  { label: "Djiboutian Franc (Fdj)", value: "Fdj" },
  { label: "Danish Krone (kr)", value: "kr" },
  { label: "Dominican Peso (RD$)", value: "RD$" },
  { label: "Algerian Dinar (دج)", value: "دج" },
  { label: "Egyptian Pound (E£)", value: "E£" },
  { label: "Ethiopian Birr (Br)", value: "Br" },
  { label: "Euro (€)", value: "€" },
  { label: "Fijian Dollar ($)", value: "$" },
  { label: "Falkland Islands Pound (£)", value: "£" },
  { label: "British Pound Sterling (£)", value: "£" },
  { label: "Georgian Lari (ლ)", value: "ლ" },
  { label: "Ghanaian Cedi (¢)", value: "¢" },
  { label: "Gibraltar Pound (£)", value: "£" },
  { label: "Gambian Dalasi (D)", value: "D" },
  { label: "Guinean Franc (FG)", value: "FG" },
  { label: "Guatemalan Quetzal (Q)", value: "Q" },
  { label: "Guyanaese Dollar ($)", value: "$" },
  { label: "Hong Kong Dollar ($)", value: "$" },
  { label: "Honduran Lempira (L)", value: "L" },
  { label: "Croatian Kuna (kn)", value: "kn" },
  { label: "Haitian Gourde (G)", value: "G" },
  { label: "Hungarian Forint (Ft)", value: "Ft" },
  { label: "Indonesian Rupiah (Rp)", value: "Rp" },
  { label: "Israeli New Shekel (₪)", value: "₪" },
  { label: "Indian Rupee (₹)", value: "₹" },
  { label: "Iraqi Dinar (ع.د)", value: "ع.د" },
  { label: "Iranian Rial (﷼)", value: "﷼" },
  { label: "Icelandic Króna (kr)", value: "kr" },
  { label: "Jersey Pound (£)", value: "£" },
  { label: "Jamaican Dollar (J$)", value: "J$" },
  { label: "Jordanian Dinar (JD)", value: "JD" },
  { label: "Japanese Yen (¥)", value: "¥" },
  { label: "Kenyan Shilling (KSh)", value: "KSh" },
  { label: "Kyrgystani Som (лв)", value: "лв" },
  { label: "Cambodian Riel (៛)", value: "៛" },
  { label: "Comorian Franc (CF)", value: "CF" },
  { label: "North Korean Won (₩)", value: "₩" },
  { label: "South Korean Won (₩)", value: "₩" },
  { label: "Kuwaiti Dinar (د.ك)", value: "د.ك" },
  { label: "Cayman Islands Dollar ($)", value: "$" },
  { label: "Kazakhstani Tenge (₸)", value: "₸" },
  { label: "Laotian Kip (₭)", value: "₭" },
  { label: "Lebanese Pound (£)", value: "£" },
  { label: "Sri Lankan Rupee (₨)", value: "₨" },
  { label: "Liberian Dollar ($)", value: "$" },
  { label: "Lesotho Loti (L)", value: "L" },
  { label: "Lithuanian Litas (Lt)", value: "Lt" },
  { label: "Latvian Lats (Ls)", value: "Ls" },
  { label: "Libyan Dinar (ل.د)", value: "ل.د" },
  { label: "Moroccan Dirham (د.م.)", value: "د.م." },
  { label: "Moldovan Leu (L)", value: "L" },
  { label: "Malagasy Ariary (Ar)", value: "Ar" },
  { label: "Macedonian Denar (ден)", value: "ден" },
  { label: "Myanma Kyat (K)", value: "K" },
  { label: "Mongolian Tugrik (₮)", value: "₮" },
  { label: "Macanese Pataca (MOP$)", value: "MOP$" },
  { label: "Mauritanian Ouguiya (UM)", value: "UM" },
  { label: "Mauritian Rupee (₨)", value: "₨" },
  { label: "Maldivian Rufiyaa (.ރ)", value: ".ރ" },
  { label: "Malawian Kwacha (MK)", value: "MK" },
  { label: "Mexican Peso ($)", value: "$" },
  { label: "Malaysian Ringgit (RM)", value: "RM" },
  { label: "Mozambican Metical (MT)", value: "MT" },
  { label: "Namibian Dollar ($)", value: "$" },
  { label: "Nigerian Naira (₦)", value: "₦" },
  { label: "Nicaraguan Córdoba (C$)", value: "C$" },
  { label: "Norwegian Krone (kr)", value: "kr" },
  { label: "Nepalese Rupee (₨)", value: "₨" },
  { label: "New Zealand Dollar ($)", value: "$" },
  { label: "Omani Rial (﷼)", value: "﷼" },
  { label: "Panamanian Balboa (B/.)", value: "B/." },
  { label: "Peruvian Sol (S/.)", value: "S/." },
  { label: "Papua New Guinean Kina (K)", value: "K" },
  { label: "Philippine Peso (₱)", value: "₱" },
  { label: "Pakistani Rupee (₨)", value: "₨" },
  { label: "Polish Złoty (zł)", value: "zł" },
  { label: "Paraguayan Guarani (Gs)", value: "Gs" },
  { label: "Qatari Rial (﷼)", value: "﷼" },
  { label: "Romanian Leu (lei)", value: "lei" },
  { label: "Serbian Dinar (Дин)", value: "Дин" },
  { label: "Russian Ruble (₽)", value: "₽" },
  { label: "Rwandan Franc (ر.س)", value: "ر.س" },
  { label: "Saudi Riyal (﷼)", value: "﷼" },
  { label: "Solomon Islands Dollar ($)", value: "$" },
  { label: "Seychellois Rupee (₨)", value: "₨" },
  { label: "Sudanese Pound (£)", value: "£" },
  { label: "Swedish Krona (kr)", value: "kr" },
  { label: "Singapore Dollar ($)", value: "$" },
  { label: "Saint Helena Pound (£)", value: "£" },
  { label: "Sierra Leonean Leone (Le)", value: "Le" },
  { label: "Somali Shilling (S)", value: "S" },
  { label: "Surinamese Dollar ($)", value: "$" },
  { label: "São Tomé and Príncipe Dobra (Db)", value: "Db" },
  { label: "Salvadoran Colón ($)", value: "$" },
  { label: "Syrian Pound (£)", value: "£" },
  { label: "Swazi Lilangeni (L)", value: "L" },
  { label: "Thai Baht (฿)", value: "฿" },
  { label: "Tajikistani Somoni (TJS)", value: "TJS" },
  { label: "Turkmenistani Manat (m)", value: "m" },
  { label: "Tunisian Dinar (د.ت)", value: "د.ت" },
  { label: "Tongan Paʻanga (T$)", value: "T$" },
  { label: "Turkish Lira (₤)", value: "₤" },
  { label: "Trinidad and Tobago Dollar ($)", value: "$" },
  { label: "New Taiwan Dollar (NT$)", value: "NT$" },
  { label: "Tanzanian Shilling (TSh)", value: "TSh" },
  { label: "Ukrainian Hryvnia (₴)", value: "₴" },
  { label: "Ugandan Shilling (USh)", value: "USh" },
  { label: "United States Dollar ($)", value: "$" },
  { label: "Uruguayan Peso ($U)", value: "$U" },
  { label: "Uzbekistani Som (лв)", value: "лв" },
  { label: "Venezuelan Bolívar (Bs)", value: "Bs" },
  { label: "Vietnamese Dong (₫)", value: "₫" },
  { label: "Vanuatu Vatu (VT)", value: "VT" },
  { label: "Samoan Tala (WS$)", value: "WS$" },
  { label: "Central African CFA Franc (FCFA)", value: "FCFA" },
  { label: "East Caribbean Dollar ($)", value: "$" },
  { label: "Special Drawing Rights (SDR)", value: "SDR" },
  { label: "West African CFA Franc (FCFA)", value: "FCFA" },
  { label: "CFP Franc (F)", value: "F" },
  { label: "Yemeni Rial (﷼)", value: "﷼" },
  { label: "South African Rand (R)", value: "R" },
  { label: "Zambian Kwacha (ZK)", value: "ZK" },
  { label: "Zimbabwean Dollar (ZWL)", value: "ZWL" },
];

export const socialMediaTypes = {
  custome: "custom",
  whatsapp: "whatsapp",
};
export const socialMediaTypesMultipage = {
  custome: "11",
  whatsapp: "9",
};

/**
 * template wise limits
 */

export const SinglePage = {
  WebCategory: "singal-page",
  About_Limit: 1,
  Video_Parent_Limit: 1,
  Image_Parent_Limit: 1,
  Payment_Parent_Limit: 1,
};

// about us children list for theme
export const AboutUsTheme = [
  { theme: 1, themeName: "Theme 1" },
  { theme: 2, themeName: "Theme 2" },
  { theme: 3, themeName: "Theme 3" },
  { theme: 4, themeName: "Theme 4" },
];
export const CustomFormTheme = [{ theme: 1, themeName: "Theme 1" }];
export const CustomFormType = [
  { value: "popup", themeName: "Popup" },
  { value: "form", themeName: "Form" },
];

export const ImagesMultiPageTheme = [
  { theme: 1, themeName: "Theme 1" },
  { theme: 2, themeName: "Theme 2" },
  { theme: 3, themeName: "Theme 3" },
  { theme: 4, themeName: "Theme 4" },
];

export const PageTitleTheme = [{ theme: 1, themeName: "Theme 1" }];
export const OurTeamTheme = [
  { theme: 1, themeName: "Theme 1" },
  { theme: 2, themeName: "Theme 2" },
];

export const ServiceAndCategoryTheme = [
  { theme: 1, themeName: "Theme 1" },
  { theme: 2, themeName: "Theme 2" },
  { theme: 3, themeName: "Theme 3" },
  { theme: 4, themeName: "Theme 4" },
  { theme: 5, themeName: "Theme 5" },
  { theme: 6, themeName: "Theme 6" },
  { theme: 7, themeName: "Theme 7" },
  { theme: 8, themeName: "Theme 8" },
  { theme: 9, themeName: "Theme 9" },
  { theme: 10, themeName: "Theme 10" },
  { theme: 11, themeName: "Theme 11" },
  { theme: 12, themeName: "Theme 12" },
  { theme: 13, themeName: "Theme 13" },
  { theme: 14, themeName: "Theme 14" },
  { theme: 15, themeName: "Theme 15" },
];
export const ProductTheme = [
  { theme: 1, themeName: "Theme 1" },
  { theme: 2, themeName: "Theme 2" },
  { theme: 3, themeName: "Theme 3" },
];

export const ServiceCounterTheme = [{ theme: 1, themeName: "Theme 1" }];
export const TestimonialTheme = [
  { theme: 1, themeName: "Theme 1" },
  { theme: 2, themeName: "Theme 2" },
  { theme: 3, themeName: "Theme 3" },
  { theme: 4, themeName: "Theme 4" },
];
export const FaqsTheme = [
  { theme: 1, themeName: "Theme 1" },
  { theme: 2, themeName: "Theme 2" },
  { theme: 3, themeName: "Theme 3" },
];
export const aboutUsTheme1 = {
  ".about-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".about-section.bg-image": {
    "background-image": "",
  },
  ".about-section.bg-image::before": {
    "background-color": "",
  },
  // 000000CC
  ".about-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".about-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".about-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".about-section .about-img": {
    margin: "0 auto",
    display: "block",
  },
  ".about-section .about-details": {
    padding: "20px",
  },
  ".about-section .about-details h5": {
    "font-size": "24px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
    "line-height": "1.4",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".about-section .about-details p": {
    "font-size": "17px",
    "font-weight": "500",
    color: "rgb(0 0 0 / 100)",
    "line-height": "1.6",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".about-section .about-details ul": {
    "margin-bottom": "30px",
  },
  ".about-section .about-details ul li": {
    "font-size": "16px",
    "font-weight": "500",
    color: "rgb(0 0 0 / 100)",
    margin: "14px 0",
  },
  ".about-section .about-details ul li:before": {
    color: "rgb(255 73 124 / 100)",
    "margin-right": "10px",
  },
  ".about-section .about-details ul li:first-child": {
    "margin-top": "0",
  },
  ".about-section .about-details .btn-section": {
    "justify-content": "start",
  },
  ".about-section .about-details .btn": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
  },
};

export const aboutUsTheme2 = {
  ".about2-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
    width: "",
    margin: "",
    "border-radius": "",
  },
  ".about2-section.bg-image": {
    "background-image": "",
  },
  ".about2-section.bg-image::before": {
    "background-color": "",
  },
  // "#000000CC"
  ".about2-section .section-title-area": {
    "margin-bottom": "50px",
  },
  ".about2-section .section-title-area .section-subtitle": {
    "font-size": "16px",
    "font-weight": "600",
    color: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 0.2)",
    padding: "5px 20px",
    p1: "5px",
    p2: "20px",
    "border-radius": "15px",
    "margin-bottom": "15px",
  },
  ".about2-section .section-title-area .section-title": {
    "font-size": "44px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
  },
  ".about2-section .about-img": {
    margin: "0 auto",
    display: "block",
  },
  ".about2-section .about-details": {
    padding: "20px",
  },
  ".about2-section .about-details h5": {
    "font-size": "24px",
    "font-weight": "600",
    color: "rgb(0 0 0 / 100)",
    "line-height": "1.4",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".about2-section .about-details p": {
    "font-size": "17px",
    "font-weight": "500",
    color: "rgb(0 0 0 / 100)",
    "line-height": "1.6",
    "margin-bottom": "20px",
    "text-align": "justify",
  },
  ".about2-section .about-details ul": {
    "margin-bottom": "30px",
  },
  ".about2-section .about-details ul li": {
    "font-size": "16px",
    "font-weight": "500",
    color: "rgb(0 0 0 / 100)",
    margin: "14px 0",
  },
  ".about2-section .about-details ul li:before": {
    color: "rgb(255 73 124 / 100)",
    "margin-right": "10px",
  },
  ".about2-section .about-details ul li:first-child": {
    "margin-top": "0",
  },
  ".about2-section .about-details .btn-section": {
    "justify-content": "start",
  },
  ".about2-section .about-details .btn": {
    "background-color": "rgb(255 73 124 / 100)",
    border: "2px solid rgb(255 73 124 / 100)",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    padding: "8px 25px",
    p1: "8px",
    p2: "25px",
  },
};

export const productBuyStyle = {
  ".products-section .product-catlist": {
    "text-align": "center",
    "margin-bottom": "30px",
  },
  ".products-section .product-catlist .btn": {
    border: "2px solid rgb(255 73 124 / 100)",
    borderColor: "rgb(255 73 124 / 100)",
    color: "rgb(255 73 124 / 100)",
    padding: "6px 20px",
    margin: "5px",
  },
  ".products-section .product-catdata .box": {
    "margin-bottom": "15px",
    outline: "none",
    "background-color": "rgb(255 255 255 / 100)",
    "box-shadow": "0 5px 10px 0 rgb(7 28 37 / 0.098)",
    shedowColor: "rgb(7 28 37 / 0.098)",
    "border-radius": "0px",
  },
  ".products-section .product-catdata .product-img .taglabel": {
    "background-color": "rgb(0 138 73 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "14px",
    "font-weight": "400",
    padding: "8px 15px",
    "border-radius": "5px",
    top: "10px",
    left: "10px",
  },
  ".products-section .product-catdata .product-img .post-date": {
    "background-color": "rgb(255 255 255 / 100)",
    "border-radius": "5px",
    color: "rgb(255 73 124 / 100)",
    "font-size": "14px",
    "font-weight": "400",
    padding: "8px 15px",
    "text-align": "center",
    top: "10px",
    right: "10px",
  },
  ".products-section .product-catdata .product-content": {
    padding: "20px",
    float: "left",
    width: "100%",
    "text-align": "center",
  },
  ".products-section .product-catdata .product-content .shop a": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "20px",
  },
  ".products-section .product-catdata .product-content .shop a:hover": {
    color: "rgb(255 73 124 / 100)",
  },
  ".products-section .product-catdata .product-content .price": {
    color: "rgb(51 51 51 / 100)",
    margin: "15px 5px 10px 5px",
  },
  ".products-section .product-catdata .product-content .price del": {
    color: "rgb(153 153 153 / 100)",
    "margin-right": "15px",
  },
  ".products-section .product-catdata .product-content .price ins": {
    "text-decoration": "none",
    color: "rgb(0 0 0 / 100)",
  },
  ".products-section .product-catdata .product-content .add-cart": {
    border: "2px solid rgb(255 73 124 / 100)",
    borderColor: "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
    "font-size": "14px",
    "font-weight": "500",
    padding: "4px 15px",
    "box-shadow": "none",
  },
  ".products-section .product-catdata .product-content .add-cart:hover": {
    opacity: "0.8",
  },
  ".shop-section": {
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".shop-section .filter-switch": {
    "border-bottom": "1px solid rgb(215 226 230 / 100)",
    borderColor: "rgb(215 226 230 / 100)",
  },
  ".shop-section .filter-switch .switch-text": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "16px",
    "font-weight": "600",
  },
  ".shop-section .filter-switch .switch-toggle": {
    "background-color": "rgb(230 230 230 / 100)",
    "border-radius": "13px",
  },
  ".shop-section .filter-switch .switch-toggle.opened": {
    "background-color": "rgb(255 73 124 / 100)",
  },
};

export const productBuyCheckOutStyle = {
  ".step-checkout-section": {
    padding: "100px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".step-checkout-div .steps": {
    "border-radius": "15px",
    border: "1px solid rgb(236 236 236 / 100)",
    borderColor: "rgb(236 236 236 / 100)",
  },
  ".step-checkout-div .steps .icondiv": {
    "background-color": "rgb(255 255 255 / 100)",
    color: "rgb(153 153 153 / 100)",
  },
  ".step-checkout-div .steps.activestep .icondiv, .step-checkout-div .steps.donestep .icondiv":
    {
      color: "rgb(255 255 255 / 100)",
      "background-color": "rgb(255 73 124 / 100)",
    },
  ".step-checkout-div .steps .step-ttl": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "22px",
  },
  ".step-checkout-div .steps .before-login .desq": {
    color: "rgb(102 102 102 / 100)",
  },
  ".step-checkout-div .steps .btn-login": {
    "background-color": "transparent",
    border: "1px solid rgb(59 183 126 / 100)",
    borderColor: "rgb(59 183 126 / 100)",
    "font-size": "14px",
    color: "rgb(59 183 126 / 100)",
    padding: "6px 20px",
  },
  ".step-checkout-div .steps .btn-green": {
    "background-color": "rgb(59 183 126 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".step-checkout-div .steps .addresslist h4": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "20px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".step-checkout-div .steps .addresslist p": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "16px",
    "font-weight": "normal",
  },
  ".step-checkout-div .steps .addresslist .btn": {
    color: "rgb(255 255 255 / 100)",
    "background-color": "rgb(59 183 126 / 100)",
  },
  ".step-checkout-total": {
    "border-radius": "15px",
    border: "1px solid rgb(236 236 236 / 100)",
    borderColor: "rgb(236 236 236 / 100)",
  },
  ".step-checkout-total .product-list": {
    "border-bottom": "1px solid rgb(238 238 238 / 100)",
    borderColor: "rgb(238 238 238 / 100)",
  },
  ".step-checkout-total .product-list .product-img": {
    width: "90px",
    border: "1px solid rgb(238 238 238 / 100)",
    borderColor: "rgb(238 238 238 / 100)",
    "border-radius": "15px",
    padding: "5px",
  },
  ".step-checkout-total .product-list .name": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "16px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".step-checkout-total .product-list .sub-ttl": {
    "font-weight": "600",
  },
  ".step-checkout-total .product-list .qty .count": {
    color: "rgb(0 0 0 / 100)",
    "font-size": "22px",
    "font-weight": "600",
  },
  ".step-checkout-total .product-list input:disabled": {
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".step-checkout-total .product-list .qty .plus": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "26px",
  },
  ".step-checkout-total .product-list .qty .minus": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "26px",
  },
  ".step-checkout-total .product-list .fa-trash": {
    color: "rgb(255 0 0 / 100)",
  },
  ".step-checkout-total .table-responsive .text-brand": {
    color: "rgb(59 183 126 / 100)",
  },
};

export const productBuyMyAccountStyle = {
  ".my-order-section": {
    padding: "60px 0",
    "background-color": "rgb(255 255 255 / 100)",
  },
  ".my-order-section .nav-pills": {
    "margin-bottom": "15px",
  },
  ".my-order-section .nav-pills .nav-link": {
    color: "rgb(255 73 124 / 100)",
    "font-size": "16px",
    padding: "15px",
    "font-weight": "700",
    border: "1px solid rgb(255 73 124 / 100)",
    borderColor: "rgb(255 73 124 / 100)",
    "border-radius": "5px",
  },
  ".my-order-section .nav-pills .nav-link.active": {
    color: "rgb(255 255 255 / 100)",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".my-order-section .tab-content a": {
    color: "rgb(255 73 124 / 100)",
  },
  ".my-order-section .tab-content .form-control": {
    border: "1px solid rgb(212 212 212 / 100)",
    borderColor: "rgb(212 212 212 / 100)",
    "border-radius": "4px",
    "font-size": "14px",
  },
  ".my-order-section .tab-content .btn-primary": {
    "border-color": "rgb(255 73 124 / 100)",
    "background-color": "rgb(255 73 124 / 100)",
  },
};

export const TopHeaderStyleReset = {
  ".top-header-section": {
    padding: "2px 0",
    // padding: "10px 0",
    "background-color": { r: 255, g: 73, b: 124, a: 100 },
  },
  ".top-header-section .top-left": {
    margin: "0px 0",
    "line-height": "2",
  },
  ".top-header-section .top-left a": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    margin: "2px 5px",
    ml: "5px",
    "font-size": "13px",
    "letter-spacing": "0.5px",
  },
  // ".top-header-section .marquee-link a": {
  //   color: { r: 255, g: 255, b: 255, a: 100 },
  //   margin: "2px 5px",
  //   ml: "5px",
  // },
  ".top-header-section .top-social": {
    float: "right",
  },
  ".top-header-section .top-social a": {
    margin: "2px 5px",
    ml: "5px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "18px",
  },
  ".goog-te-gadget .goog-te-combo": {
    // margin:  0 !important;
    // border: "1px solid #FF497C",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    background: { r: 255, g: 73, b: 124, a: 100 },
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".goog-te-gadget": {
    "border-radius": "3px",
    border: "1px solid #ffffff",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
  },
  // ".goog-te-gadget": {
  //     height: 23px !important;
  // }
};
export const TopHeaderStyle = {
  ".top-header-section": {
    padding: "2px 0",
    // padding: "10px 0",
    "background-color": "rgb(255 73 124 / 100)",
  },
  ".top-header-section .top-left": {
    margin: "0px 0",
    "line-height": "2",
  },
  ".top-header-section .top-left a": {
    color: "rgb(255 255 255 / 100)",
    margin: "2px 5px",
    ml: "5px",
  },
  // ".top-header-section .marquee-link a": {
  //   color: "rgb(255 255 255 / 100)",
  //   margin: "2px 5px",
  //   ml: "5px",
  // },
  ".top-header-section .top-social": {
    float: "right",
  },
  ".top-header-section .top-social a": {
    margin: "2px 5px",
    ml: "5px",
    color: "rgb(255 255 255 / 100)",
    "font-size": "18px",
  },
  ".goog-te-gadget .goog-te-combo": {
    // margin:  0 !important;
    borderColor: "rgb(255 73 124 / 100)",
    background: "rgb(255 73 124 / 100)",
    color: "rgb(255 255 255 / 100)",
  },
  ".goog-te-gadget": {
    "border-radius": "3px",
    border: "1px solid #ffffff",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(255 255 255 / 100)",
  },
};

export const footerStyle1Reset = {
  ".custom-footer .foot-other-info": {
    padding: "50px 0",
    "background-color": { r: 33, g: 37, b: 41, a: 100 },
    p1: "50px",
  },
  ".custom-footer .foot-about": {
    "margin-bottom": "20px",
  },
  ".custom-footer .foot-about .flogo": {
    "margin-bottom": "10px",
  },
  ".custom-footer .foot-about p": {
    "font-size": "16px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "line-height": "1.6",
  },
  ".custom-footer .foot-menu": {
    "margin-left": "10px",
    "margin-bottom": "20px",
  },
  ".custom-footer .foot-title": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "20px",
    "font-weight": "500",
    "margin-bottom": "15px",
  },
  ".custom-footer .foot-menulist li": {
    margin: "5px 0",
    m1: "5px",
  },
  ".custom-footer .foot-menulist li a": {
    color: { r: 255, g: 255, b: 255, a: 100 },
    "font-size": "14px",
    "font-weight": "500",
    "line-height": "1.2",
  },
  ".custom-footer .foot-menulist li a:hover": {
    opacity: "0.6",
  },
  ".custom-footer .foot-social-2 a": {
    margin: "2px 5px",
    m1: "2px",
    m2: "5px",
    width: "42px",
    height: "42px",
    border: "1px solid #ffffff",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "border-radius": "100%",
  },
  ".custom-footer .foot-social-2 a i": {
    "font-size": "18px",
    color: "#ffffff",
    "line-height": "0px",
  },
  ".custom-footer .foot-contacts": {
    "margin-bottom": "20px",
  },
  ".custom-footer .foot-contacts li": {
    margin: "5px 0",
    "padding-left": "30px",
    m1: "5px",
  },
  ".custom-footer .foot-contacts li .fa": {
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".custom-footer .foot-contacts li a": {
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  ".custom-footer .foot-contacts li a:hover": {
    opacity: "0.6",
  },
  custom: "",
  theme: "1",
};
export const footerStyle1Foot2Reset = {
  "footer.style-one::before": {
    height: "200px",
    "background-color": { r: 0, g: 0, b: 0, a: 100 },
    "border-radius": "100% 100% 0 0",
    br1: "100%",
    br2: "100%",
  },
  "footer.style-one::after": {
    // height: "calc(100% - (125px + 200px))",
    "background-color": { r: 0, g: 0, b: 0, a: 100 },
  },
  "footer.style-one .top-footer": {
    "padding-bottom": "45px",
  },
  "footer.style-one .top-footer .logo": {
    width: "250px",
    height: "250px",
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    "border-radius": "100%",
  },
  "footer.style-one .top-footer .logo img": {
    "max-width": "200px",
    "max-height": "200px",
  },
  "footer.style-one .top-footer p": {
    "font-size": "18px",
    color: { r: 255, g: 255, b: 255, a: 100 },
  },
  "footer.style-one .top-footer .social-list": {
    "font-size": "22px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    padding: "20px 0 50px",
    p1: "20px",
    p2: "50px",
  },
  "footer.style-one .top-footer .social-list a": {
    width: "42px",
    height: "42px",
    border: "1px solid #ffffff",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "border-radius": "100%",
  },
  "footer.style-one .top-footer .social-list a i": {
    "font-size": "18px",
    color: { r: 255, g: 255, b: 255, a: 100 },
    "line-height": "0px",
  },
  "footer.style-one .top-footer .social-list a:first-child": {
    // "margin-right": "10px",
    "margin-right": "0px",
  },
  "footer.style-one .top-footer .social-list a:nth-of-type(2)": {
    "margin-left": "0px",
    // "margin-left": "10px",
  },
  "footer.style-one .bottom-footer": {
    border: "1px solid #FFFFFF33",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    "border-radius": "10px",
    "max-width": "1111px",
  },
  "footer.style-one .bottom-footer .foot-icons": {
    border: "2px solid white",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
    width: "80px",
    height: "80px",
    "border-radius": "100%",
  },
  "footer.style-one .bottom-footer .foot-icons i": {
    "font-size": "38px",
    color: "white",
  },
  "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a":
    {
      "font-size": "16px",
      "line-height": "2",
      "font-weight": "500",
      color: { r: 255, g: 255, b: 255, a: 100 },
    },
  "footer.style-one .bottom-footer table tr td": {
    width: "25%",
    padding: "10px",
    "border-right": "1px solid #FFFFFF33",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: { r: 255, g: 255, b: 255, a: 100 },
  },
  "footer.style-one .bottom-footer table tr td:last-child": {
    "line-height": "1",
  },
  "footer.style-one .bottom-footer table tr td img": {
    "max-width": "200px",
  },
  "footer.style-one .copyright p": {
    "font-size": "18px",
    "font-weight": "500",
    color: { r: 255, g: 255, b: 255, a: 100 },
    padding: "40px 0",
  },
  custom: "",
  theme: "2",
};
export const footerStyle1Foot2 = {
  "footer.style-one::before": {
    height: "200px",
    "background-color": "rgb(0 0 0 / 100)",
    "border-radius": "100% 100% 0 0",
    br1: "100%",
    br2: "100%",
  },
  "footer.style-one::after": {
    // height: "calc(100% - (125px + 200px))",
    "background-color": "rgb(0 0 0 / 100)",
  },
  "footer.style-one .top-footer": {
    "padding-bottom": "45px",
  },
  "footer.style-one .top-footer .logo": {
    width: "250px",
    height: "250px",
    "background-color": "rgb(255 255 255 / 100)",
    "border-radius": "100%",
  },
  "footer.style-one .top-footer .logo img": {
    "max-width": "200px",
    "max-height": "200px",
  },
  "footer.style-one .top-footer p": {
    "font-size": "18px",
    color: "rgb(255 255 255 / 100)",
  },
  "footer.style-one .top-footer .social-list": {
    "font-size": "22px",
    color: "rgb(255 255 255 / 100)",
    padding: "20px 0 50px",
    p1: "20px",
    p2: "50px",
  },
  "footer.style-one .top-footer .social-list a": {
    width: "42px",
    height: "42px",
    border: "1px solid #ffffff",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(255 255 255 / 100)",
    "border-radius": "100%",
  },
  "footer.style-one .top-footer .social-list a i": {
    "font-size": "18px",
    color: "rgb(255 255 255 / 100)",
    "line-height": "0px",
  },
  "footer.style-one .top-footer .social-list a:first-child": {
    "margin-right": "10px",
  },
  "footer.style-one .top-footer .social-list a:nth-of-type(2)": {
    "margin-left": "10px",
  },
  "footer.style-one .bottom-footer": {
    border: "1px solid #FFFFFF33",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(255 255 255 / 100)",
    "border-radius": "10px",
    "max-width": "1111px",
  },
  "footer.style-one .bottom-footer .foot-icons": {
    border: "2px solid white",
    borderPx: "2px",
    borderThik: "solid",
    borderColor: "rgb(255 255 255 / 100)",
    width: "80px",
    height: "80px",
    "border-radius": "100%",
  },
  "footer.style-one .bottom-footer .foot-icons i": {
    "font-size": "38px",
    color: "rgb(255 255 255 / 100)",
  },
  "footer.style-one .bottom-footer table, footer.style-one .bottom-footer table tr td a":
    {
      "font-size": "16px",
      "line-height": "2",
      "font-weight": "500",
      color: "rgb(255 255 255 / 100)",
    },
  "footer.style-one .bottom-footer table tr td": {
    width: "25%",
    padding: "10px",
    "border-right": "1px solid #FFFFFF33",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(255 255 255 / 100)",
  },
  "footer.style-one .bottom-footer table tr td:last-child": {
    "line-height": "1",
  },
  "footer.style-one .bottom-footer table tr td img": {
    "max-width": "200px",
  },
  "footer.style-one .copyright p": {
    "font-size": "18px",
    "font-weight": "500",
    color: "rgb(255 255 255 / 100)",
    padding: "40px 0",
  },
  theme: "2",
};

export const footerStyle1 = {
  ".custom-footer .foot-other-info": {
    padding: "50px 0",
    "background-color": "rgb(33 37 41 / 100)",
    p1: "50px",
  },
  ".custom-footer .foot-about": {
    "margin-bottom": "20px",
  },
  ".custom-footer .foot-about .flogo": {
    "margin-bottom": "10px",
  },
  ".custom-footer .foot-about p": {
    "font-size": "16px",
    color: "rgb(255 255 255 / 100)",
    "line-height": "1.6",
  },
  ".custom-footer .foot-menu": {
    "margin-left": "10px",
    "margin-bottom": "20px",
  },
  ".custom-footer .foot-title": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "20px",
    "font-weight": "500",
    "margin-bottom": "15px",
  },
  ".custom-footer .foot-menulist li": {
    margin: "5px 0",
    m1: "5px",
  },
  ".custom-footer .foot-menulist li a": {
    color: "rgb(255 255 255 / 100)",
    "font-size": "14px",
    "font-weight": "500",
    "line-height": "1.2",
  },
  ".custom-footer .foot-menulist li a:hover": {
    opacity: "0.6",
  },
  ".custom-footer .foot-social-2 a": {
    margin: "2px 5px",
    m1: "2px",
    m2: "5px",
    width: "42px",
    height: "42px",
    border: "1px solid #ffffff",
    borderPx: "1px",
    borderThik: "solid",
    borderColor: "rgb(255 255 255 / 100)",
    "border-radius": "100%",
  },
  ".custom-footer .foot-social-2 a i": {
    "font-size": "18px",
    color: "rgb(255 255 255 / 100)",
    "line-height": "0px",
  },
  ".custom-footer .foot-contacts": {
    "margin-bottom": "20px",
  },
  ".custom-footer .foot-contacts li": {
    margin: "5px 0",
    "padding-left": "30px",
    m1: "5px",
  },
  ".custom-footer .foot-contacts li .fa": {
    color: "rgb(255 255 255 / 100)",
  },
  ".custom-footer .foot-contacts li a": {
    color: "rgb(255 255 255 / 100)",
  },
  ".custom-footer .foot-contacts li a:hover": {
    opacity: "0.6",
  },
  theme: "1",
};

export const footerStyle2Reset = {
  ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after":
    {
      "border-bottom": "50px solid transparent",
      borderBtPx: "50px",
      borderBtThik: "solid",
      borderBtColor: "transparent",
      "border-left": "50vw solid { r: 255, g: 255, b: 255, a: 100 }",
      borderLfPx: "50vw",
      borderLfThik: "solid",
      borderLfColor: { r: 255, g: 255, b: 255, a: 100 },
      "border-right": "50vw solid { r: 255, g: 255, b: 255, a: 100 }",
      borderRiPx: "50vw",
      borderRiThik: "solid",
      borderRiColor: { r: 255, g: 255, b: 255, a: 100 },
    },
  ".custom-footer .foot-contact": {
    "background-color": { r: 244, g: 244, b: 245, a: 100 },
    padding: "100px 0",
    p1: "50px",
    "text-align": "center",
  },
  ".custom-footer .foot-contact .icons": {
    "background-color": { r: 255, g: 255, b: 255, a: 100 },
    border: "5px solid { r: 255, g: 73, b: 124, a: 100 }",
    borderPx: "5px",
    borderThik: "solid",
    borderColor: { r: 255, g: 73, b: 124, a: 100 },
    "border-radius": "50%",
    padding: "30px",
    height: "120px",
    width: "120px",
    "margin-bottom": "20px",
  },
  ".custom-footer .foot-contact h6": {
    color: { r: 31, g: 39, b: 50, a: 100 },
    "font-size": "22px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".custom-footer .foot-contact p": {
    color: { r: 31, g: 39, b: 50, a: 100 },
    "font-size": "16px",
    "font-weight": "500",
  },
  custom: "",
};
export const footerStyle2 = {
  ".custom-footer .corner.corner-inverse:before, .custom-footer .corner.corner-inverse:after":
    {
      "border-bottom": "50px solid transparent",
      borderBtPx: "50px",
      borderBtThik: "solid",
      borderBtColor: "transparent",
      "border-left": "50vw solid rgb(255 255 255 / 100)",
      borderLfPx: "50vw",
      borderLfThik: "solid",
      borderLfColor: "rgb(255 255 255 / 100)",
      "border-right": "50vw solid rgb(255 255 255 / 100)",
      borderRiPx: "50vw",
      borderRiThik: "solid",
      borderRiColor: "rgb(255 255 255 / 100)",
    },
  ".custom-footer .foot-contact": {
    "background-color": "rgb(244 244 245 / 100)",
    padding: "100px 0",
    p1: "50px",
    "text-align": "center",
  },
  ".custom-footer .foot-contact .icons": {
    "background-color": "rgb(255 255 255 / 100)",
    border: "5px solid rgb(255 73 124 / 100)",
    borderPx: "5px",
    borderThik: "solid",
    borderColor: "rgb(255 73 124 / 100)",
    "border-radius": "50%",
    padding: "30px",
    height: "120px",
    width: "120px",
    "margin-bottom": "20px",
  },
  ".custom-footer .foot-contact h6": {
    color: "rgb(31 39 50 / 100)",
    "font-size": "22px",
    "font-weight": "600",
    "margin-bottom": "10px",
  },
  ".custom-footer .foot-contact p": {
    color: "rgb(31 39 50 / 100)",
    "font-size": "16px",
    "font-weight": "500",
  },
};

export const CUSTOM_FILED_TYPE = [
  {
    value: "Text Field",
  },
  { value: "Number Field" },
  { value: "Checkbox" },
  { value: "Single Selection" },
  { value: "Multiple Selection" },
  { value: "Dropdown" },
  { value: "Date" },
  { value: "Time" },
  { value: "Date Time" },
  { value: "Attachment" },
  { value: "Day & Month" },
  { value: "Month & Year" },
];

export const CUSTOM_FILED_TYPE_CHECK = [
  "Checkbox",
  "Single Selection",
  "Multiple Selection",
  "Dropdown",
];
