import React, { useContext } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { Border, TextAlign, fontWeight } from "../../../../constant";
import ServiceAndCategoryContext from "../../../context/ServiceAndCatagoryContext/ServiceAndCategoryContext";
import { Cases, DELETE_CONFIRM } from "../../../constant";
import { delete_Multi_Image_Permanent } from "../apis/SliderApi/SliderApi";
import { Zoom, toast } from "react-toastify";
import Switch from "@material-ui/core/Switch";
import { Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { UploadCloud } from "react-feather";
import LoaderContext from "../../../../context/LoaderContext/LoaderContext";
import ByteCardBuilderContext from "../../../context/BytecardBilderContext/ByteCardBuilderContext";
import ProductImageUploadPopup from "../../../Modals/imageUploade/productImageUploadPopup";
import Dividers from "../../../common/divider";
import { OpenModalCommonFunc } from "../../../utils";
import ServiceCategoryDetailStyle from "./ServiceCategoryDetailStyle";
import PaginationButtonStyle from "../../../common/paginationButtonStyle";
import CommonColor from "../../../common/CommonColor";
import CustomStyle from "../../../common/CustomStyle";

const ServiceThemeFifteen = (props) => {
  const {
    isEdit,
    settingFormData,
    setSettingFormData,
    backImageToggle,
    setBackImageToggle,
    boxSection,
    setBoxSection,
    setIsImageUpload,
    isBackgroundColor,
    setIsBackgroundColor,
  } = useContext(ServiceAndCategoryContext);
  const { setLoaderShow } = useContext(LoaderContext);
  const {
    allPopupState,
    setAllPopupState,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
  } = useContext(ByteCardBuilderContext);

  const { imageUpload } = props;

  const handleChangeServiceSection = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section": {
          ...prev[".service15-section"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionTitleArea = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .section-title-area": {
          ...prev[".service15-section .section-title-area"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionTitleAreaSubtitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .section-title-area .section-subtitle": {
          ...prev[".service15-section .section-title-area .section-subtitle"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionTitleAreaSectionTitle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .section-title-area .section-title": {
          ...prev[".service15-section .section-title-area .section-title"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionSectionDiv = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .servicediv": {
          ...prev[".service15-section .servicediv"],
          [name]: value,
        },
      };
    });
  };

  const handleChangeServiceServiceDivIcon = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .servicediv h4": {
          ...prev[".service15-section .servicediv h4"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeServiceServiceDivP = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .servicediv p": {
          ...prev[".service15-section .servicediv p"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeServiceServiceDivLinks = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .servicediv .links": {
          ...prev[".service15-section .servicediv .links"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeServiceSliderBox = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .service-slider .service-box": {
          ...prev[".service15-section .service-slider .service-box"],
          [name]: value,
        },
      };
    });
  };
  // const handleChangeSectionSliderBoxDiv = (e) => {
  //   const { value, name } = e.target;
  //   setSettingFormData((prev) => {
  //     return {
  //       ...prev,
  //       ".service15-section .service-slider .service-box .servicediv": {
  //         ...prev[
  //           ".service15-section .service-slider .service-box .servicediv"
  //         ],
  //         [name]: value,
  //       },
  //     };
  //   });
  // };
  const handleChangeSectionSliderBoxDivImg = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .service-slider .service-box .service-img": {
          ...prev[
            ".service15-section .service-slider .service-box .service-img"
          ],
          [name]: value,
        },
      };
    });
  };

  const handleChangeSectionSliderBoxLink = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .service-slider .service-box .cus-name-link": {
          ...prev[
            ".service15-section .service-slider .service-box .cus-name-link"
          ],
          [name]: value,
        },
      };
    });
  };
  const handleChangeSectionSliderBoxLinkAngle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .service-slider .service-box .cus-angle-link": {
          ...prev[
            ".service15-section .service-slider .service-box .cus-angle-link"
          ],
          [name]: value,
        },
      };
    });
  };
  const handelChangeSliderDots = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        ".service15-section .slick-dots li button:before": {
          ...prev[".service15-section .slick-dots li button:before"],
          [name]: value,
        },
      };
    });
  };
  const handleChangeCustomStyle = (e) => {
    const { value, name } = e.target;
    setSettingFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * delete image permanently
   */
  const deleteImagePermanent = async () => {
    try {
      let confirmation = window.confirm(DELETE_CONFIRM);
      if (confirmation) {
        setLoaderShow(true);
        let formData = {
          fileName: settingFormData?.[".service15-section.bg-image"]?.[
            "background-image"
          ]
            .split(`${process.env.REACT_APP_BUCKET_PRO_FOLDER_MULTI}/`)
            .pop(),
        };
        const { data } = await delete_Multi_Image_Permanent(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
            position: "top-center",
          });
          setSettingFormData({
            ...settingFormData,
            ".service15-section.bg-image": { "background-image": "" },
          });
          setLoaderShow(false);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };
  /**
   * image reupload
   */
  const reupload = () => {
    setSettingFormData((prev) => {
      return {
        ...prev,
        image: "",
      };
    });
  };
  return (
    <>
      <Row className="mt-4">
        <h4 className="mb-3">Make change in main Service section 15</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (default : 100)"}
          </Label>
          <Input
            className="form-control"
            name="padding"
            type="number"
            placeholder="Padding"
            value={settingFormData?.[".service15-section"]?.["padding"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handleChangeServiceSection(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <div className="d-flex align-items-center">
            <Label htmlFor="validationCustom03">
              {isBackgroundColor
                ? "Add Background Color"
                : "Remove Background Color"}
            </Label>
            <Switch
              checked={isBackgroundColor}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => {
                setIsBackgroundColor(e.target.checked);
                setSettingFormData((prev) => {
                  if (e.target.checked) {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".service15-section": {
                          "background-color": "#ffffff",
                          padding:
                            settingFormData?.[".service15-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".service15-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".service15-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".service15-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".service15-section": {
                          "background-color": "#ffffff",
                          padding:
                            settingFormData?.[".service15-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  } else {
                    if (boxSection) {
                      return {
                        ...prev,
                        ".service15-section": {
                          padding:
                            settingFormData?.[".service15-section"]?.[
                              "padding"
                            ],
                          width: settingFormData?.[".service15-section"]?.[
                            "width"
                          ]?.replace("rem)", ""),
                          margin: settingFormData?.[".service15-section"]?.[
                            "margin"
                          ]
                            ?.split(" ")[0]
                            ?.replace(/px/g, ""),
                          "border-radius": settingFormData?.[
                            ".service15-section"
                          ]?.["border-radius"]?.replace(/rem/g, ""),
                        },
                      };
                    } else {
                      return {
                        ...prev,
                        ".service15-section": {
                          padding:
                            settingFormData?.[".service15-section"]?.[
                              "padding"
                            ],
                        },
                      };
                    }
                  }
                });
              }}
            />
          </div>
          {isBackgroundColor && (
            <CommonColor
              color={
                settingFormData?.[".service15-section"]?.["background-color"]
              }
              setSettingFormData={setSettingFormData}
              className={".service15-section"}
              classProperty={"background-color"}
              label={"Select Background Color"}
            />
          )}
        </Col>
        <p>{"Box Section : convert your section to Box section"}</p>
        <div>
          <Switch
            checked={boxSection}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBoxSection(e.target.checked);
              setSettingFormData((prev) => {
                return {
                  ...prev,
                  ".service15-section": {
                    padding: settingFormData?.[".service15-section"]?.[
                      "padding"
                    ]
                      ?.split(" ")[0]
                      ?.replace(/px/g, ""),
                    "background-color":
                      settingFormData?.[".service15-section"]?.[
                        "background-color"
                      ],
                    width: e.target.checked ? "10" : "",
                    margin: e.target.checked ? "0" : "",
                    "border-radius": e.target.checked ? "3" : "",
                  },
                };
              });
            }}
          />
        </div>
        {boxSection && (
          <>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Width (Default : 10)"}
              </Label>
              <Input
                className="form-control"
                name="width"
                type="number"
                placeholder="width"
                value={settingFormData?.[".service15-section"]?.[
                  "width"
                ]?.replace("rem)", "")}
                onChange={(e) => handleChangeServiceSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Margin (Default : 0)"}
              </Label>
              <Input
                className="form-control"
                name="margin"
                type="number"
                placeholder="margin"
                value={settingFormData?.[".service15-section"]?.["margin"]
                  ?.split(" ")[0]
                  ?.replace(/px/g, "")}
                onChange={(e) => handleChangeServiceSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
            <Col md="4 mb-3">
              <Label htmlFor="validationCustom03">
                {"Border Radius (Default : 3)"}
              </Label>
              <Input
                className="form-control"
                name="border-radius"
                type="number"
                placeholder="Border Radius"
                value={settingFormData?.[".service15-section"]?.[
                  "border-radius"
                ]?.replace(/rem/g, "")}
                onChange={(e) => handleChangeServiceSection(e)}
                style={{ borderRadius: "0", color: "black" }}
              />
            </Col>
          </>
        )}
      </Row>
      <hr />
      <Row className="mt-4">
        <Col md="12 mb-3">
          <h4 className="mb-3">Add Background Image in section</h4>

          <p>do you want to upload Background image</p>
          <Switch
            checked={backImageToggle}
            inputProps={{ "aria-label": "controlled" }}
            onChange={(e) => {
              setBackImageToggle(e.target.checked);
              setSettingFormData((prev) => {
                if (e.target.checked) {
                  return {
                    ...prev,
                    image: "",
                    ".service15-section.bg-image": {},
                    ".service15-section.bg-image::before": {
                      "background-color": { r: 0, g: 0, b: 0, a: 0.8 },
                    },
                  };
                } else {
                  return {
                    ...prev,
                    image: "",
                    ".service15-section.bg-image": {},
                    ".service15-section.bg-image::before": {},
                  };
                }
              });
            }}
          />
          {backImageToggle && (
            <>
              {" "}
              <Col md="6 mb-3">
                <Dividers divide="IMAGE" />
                <p className="mb-4">
                  File type required JPG, PNG (1905px X 986px)
                </p>
              </Col>
              <Row>
                <Col md="4 mb-3">
                  <div
                    className="aj-uploader d-flex flex-wrap  justify-content-center  align-items-center"
                    style={{ cursor: "pointer" }}
                  >
                    {!settingFormData?.[".service15-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <span
                        onClick={() => {
                          OpenModalCommonFunc(
                            Cases.PRODUCT_UPLOAD,
                            setAllPopupState
                          );
                          setSettingFormData((prev) => {
                            return {
                              ...prev,
                              image:
                                settingFormData?.[
                                  ".service15-section.bg-image"
                                ]?.["background-image"],
                            };
                          });
                          setIsImageUpload((prev) => {
                            return {
                              isList: true,
                              isDetails: false,
                            };
                          });
                        }}
                      >
                        <UploadCloud />
                      </span>
                    )}

                    {settingFormData?.[".service15-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <img
                        src={
                          settingFormData?.[".service15-section.bg-image"]?.[
                            "background-image"
                          ] || ""
                        }
                        alt=""
                        style={{
                          width: "85%",
                          height: "90px",
                          objectFit: "contain",
                          position: "absolute",
                        }}
                        onClick={() => {
                          deleteImagePermanent();
                        }}
                      />
                    )}
                    {settingFormData?.[".service15-section.bg-image"]?.[
                      "background-image"
                    ] && (
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        style={{
                          color: "red",
                          position: "absolute",
                          top: "-22px",
                          left: "1px",
                        }}
                        onClick={() => deleteImagePermanent()}
                      >
                        <Close
                          style={{
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        />
                      </IconButton>
                    )}
                  </div>
                </Col>
                <Col md="6 mb-3">
                  <h4 className="mb-3">Add Background color in section</h4>
                  <Col md="6 mb-3">
                    <CommonColor
                      color={
                        settingFormData?.[
                          ".service15-section.bg-image::before"
                        ]?.["background-color"]
                      }
                      setSettingFormData={setSettingFormData}
                      className={".service15-section.bg-image::before"}
                      classProperty={"background-color"}
                      label={"Select Background Color"}
                    />
                  </Col>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make space in Title bottom</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".service15-section .section-title-area"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleArea(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section subtitle</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 16)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".service15-section .section-title-area .section-subtitle"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".service15-section .section-title-area .section-subtitle"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .section-title-area .section-subtitle"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".service15-section .section-title-area .section-subtitle"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .section-title-area .section-subtitle"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".service15-section .section-title-area .section-subtitle"
            }
            classProperty={"background-color"}
            label={"Select Background Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".service15-section .section-title-area .section-subtitle"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".service15-section .section-title-area .section-subtitle"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .section-title-area .section-subtitle"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Margin Bottom (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="margin-bottom"
            type="number"
            placeholder="Margin Bottom"
            value={settingFormData?.[
              ".service15-section .section-title-area .section-subtitle"
            ]?.["margin-bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSubtitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in section Title</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 44)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".service15-section .section-title-area .section-title"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Weight (Default : 600)"}
          </Label>
          <select
            name="font-weight"
            id="font-weight"
            className="form-select"
            value={
              settingFormData?.[
                ".service15-section .section-title-area .section-title"
              ]?.["font-weight"]
            }
            onChange={(e) => handleChangeSectionTitleAreaSectionTitle(e)}
            style={{ borderRadius: "0", color: "black" }}
          >
            <option value="">--select--</option>
            {fontWeight.map((elem) => {
              return (
                <option value={elem["font-weight"]}>
                  {elem["font-weight"]}
                </option>
              );
            })}
          </select>
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .section-title-area .section-title"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".service15-section .section-title-area .section-title"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>

      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in service box</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 10)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeServiceSliderBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Margin (Default : 10)"}</Label>
          <Input
            className="form-control"
            name="margin"
            type="number"
            placeholder="Margin"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box"
            ]?.["margin"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeServiceSliderBox(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in service box image </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 10)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .service-img"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxDivImg(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in service box name link </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Top (Default : 20)"}</Label>
          <Input
            className="form-control"
            name="top"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-name-link"
            ]?.["top"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLink(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Left (Default : 20)"}</Label>
          <Input
            className="form-control"
            name="left"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-name-link"
            ]?.["left"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLink(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-name-link"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLink(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="3 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .service-slider .service-box .cus-name-link"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".service15-section .service-slider .service-box .cus-name-link"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="3 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .service-slider .service-box .cus-name-link"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".service15-section .service-slider .service-box .cus-name-link"
            }
            classProperty={"background-color"}
            label={"Select Background color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 15)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-name-link"
            ]?.["border-radius"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLink(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 5 - 20)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".service15-section .service-slider .service-box .cus-name-link"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionSliderBoxLink(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".service15-section .service-slider .service-box .cus-name-link"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionSliderBoxLink(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <h4 className="mb-3">Make change in service box angle </h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Bottom (Default : 0)"}</Label>
          <Input
            className="form-control"
            name="bottom"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-angle-link"
            ]?.["bottom"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLinkAngle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Right (Default : 0)"}</Label>
          <Input
            className="form-control"
            name="right"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-angle-link"
            ]?.["right"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLinkAngle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 20)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-angle-link"
            ]?.["font-size"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLinkAngle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="3 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .service-slider .service-box .cus-angle-link"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".service15-section .service-slider .service-box .cus-angle-link"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="3 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .service-slider .service-box .cus-angle-link"
              ]?.["background-color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".service15-section .service-slider .service-box .cus-angle-link"
            }
            classProperty={"background-color"}
            label={"Select Background color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Width (Default : 50)"}</Label>
          <Input
            className="form-control"
            name="width"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-angle-link"
            ]?.["width"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLinkAngle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">{"Height (Default : 50)"}</Label>
          <Input
            className="form-control"
            name="height"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-angle-link"
            ]?.["height"]?.replace(/px/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLinkAngle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Border Radius (Default : 50)"}
          </Label>
          <Input
            className="form-control"
            name="border-radius"
            type="number"
            placeholder="Border Radius"
            value={settingFormData?.[
              ".service15-section .service-slider .service-box .cus-angle-link"
            ]?.["border-radius"]?.replace(/%/g, "")}
            onChange={(e) => handleChangeSectionSliderBoxLinkAngle(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Padding (Default : 12 - 3)"}
          </Label>
          <div className="d-flex justify-content-between">
            <Input
              className="form-control"
              name="p1"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".service15-section .service-slider .service-box .cus-angle-link"
              ]?.["p1"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionSliderBoxLinkAngle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
            <Input
              className="form-control"
              name="p2"
              type="number"
              placeholder="Padding"
              value={settingFormData?.[
                ".service15-section .service-slider .service-box .cus-angle-link"
              ]?.["p2"]?.replace(/px/g, "")}
              onChange={(e) => handleChangeSectionSliderBoxLinkAngle(e)}
              style={{ borderRadius: "0", color: "black" }}
            />
          </div>
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in slider dots</h4>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"Font Size (Default : 12)"}
          </Label>
          <Input
            className="form-control"
            name="font-size"
            type="number"
            placeholder="Font Size"
            value={settingFormData?.[
              ".service15-section .slick-dots li button:before"
            ]?.["font-size"]
              ?.split(" ")[0]
              ?.replace(/px/g, "")}
            onChange={(e) => handelChangeSliderDots(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .slick-dots li button:before"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".service15-section .slick-dots li button:before"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
        <Col md="4 mb-3">
          <Label htmlFor="validationCustom03">
            {"opacity (Default : 0.25)"}
          </Label>
          <Input
            className="form-control"
            name="opacity"
            type="number"
            placeholder="opacity"
            value={
              settingFormData?.[
                ".service15-section .slick-dots li button:before"
              ]?.["opacity"]?.split(" ")[0]
            }
            onChange={(e) => handelChangeSliderDots(e)}
            style={{ borderRadius: "0", color: "black" }}
          />
        </Col>
        <h4 className="mb-3">Active slider dots</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .slick-dots li.slick-active button:before"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={
              ".service15-section .slick-dots li.slick-active button:before"
            }
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-4">
        <h4 className="mb-3">Make change in slider next - prev icon color</h4>
        <Col md="4 mb-3">
          <CommonColor
            color={
              settingFormData?.[
                ".service15-section .service-slider .slick-arrow:before"
              ]?.["color"]
            }
            setSettingFormData={setSettingFormData}
            className={".service15-section .service-slider .slick-arrow:before"}
            classProperty={"color"}
            label={"Select Color"}
          />
        </Col>
      </Row>
      <hr />
      <ServiceCategoryDetailStyle />
      <hr />
      <PaginationButtonStyle
        mainClass={".service15-section"}
        settingFormData={settingFormData}
        setSettingFormData={setSettingFormData}
      />
      <CustomStyle
        handleChange={handleChangeCustomStyle}
        value={settingFormData?.custom}
      />
      <ProductImageUploadPopup
        allPopupState={allPopupState}
        setAllPopupState={setAllPopupState}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        image={settingFormData?.image}
        setImage={setSettingFormData}
        imageUpload={imageUpload}
        isEdit={isEdit}
        reupload={reupload}
        // aspect={1.93 / 1}
      />
    </>
  );
};

export default ServiceThemeFifteen;
