import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Col, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import SettingMultiWebContext from "../../context/SettingMultiWebContext/SettingMultiWebContext";
import { useParams } from "react-router";
import { removeSpaceWithJoinNum } from "../../utils/helper";
import {
  WebNavBarMapWithContentList,
  changeSectionPositionApi,
} from "../../components ByteCard/components/MultipageByteCardBuilder/apis/NavApi/NavApi";
import { Zoom, toast } from "react-toastify";
import Select from "react-select";

const SectionPositionChange = ({ isDashboardClick = false }) => {
  const {
    modalSectionPo,
    setModalSectionPo,
    WebConfigPage_List,
    positionChangeData,
    setPositionChangeData,
    selectedNavMap,
    setSelectedNavMap,
  } = useContext(SettingMultiWebContext);
  let { orgId } = useParams();
  const [navs, setNavs] = useState([]);
  const getWebNavBarMapList = async () => {
    try {
      let paramObj = {
        showAll: true,
        orgId,
      };
      const { data } = await WebNavBarMapWithContentList(paramObj);
      if (data.status === 200) {
        setNavs(data.payload.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    if (Object.keys(selectedNavMap)?.length === 0) {
      getWebNavBarMapList();
    }
    if (selectedNavMap && selectedNavMap !== "") {
      WebConfigPage_List(orgId, true, true, selectedNavMap?.value);
    }
  }, [selectedNavMap]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const items = [...positionChangeData];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPositionChangeData(items);
    try {
      let itemOrder = [];
      for (const item in items) {
        itemOrder.push({
          pageId: items[item].pageId,
          order: +item + 1,
        });
      }
      let formData = {
        updateOrder: [...itemOrder],
      };
      const { data } = await changeSectionPositionApi(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  return (
    <Modal size="sm" isOpen={modalSectionPo}>
      <ModalHeader
        toggle={() => {
          setModalSectionPo(false);
          setPositionChangeData([]);
          setSelectedNavMap({});
        }}
      >
        <span>Section Change</span>
      </ModalHeader>
      <ModalBody>
        {isDashboardClick === false && (
          <Col md="12 mb-4">
            <Label htmlFor="validationCustom14">{"Pages (Menu)"}</Label>
            <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input"
              defaultValue={selectedNavMap}
              options={navs.map((elem, i) => {
                return {
                  value: elem.navMapId,
                  label: elem?.label,
                };
              })}
              onChange={(opt) => {
                setSelectedNavMap(opt);
              }}
            />
            <div className="invalid-feedback">
              {"Please provide a valid Admin Name."}
            </div>
          </Col>
        )}
        <div>
          {positionChangeData?.length &&
          Object.keys(selectedNavMap)?.length !== 0 ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <ul
                    className="characters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {positionChangeData.map((elem, index) => {
                      return (
                        <Draggable
                          key={elem.pageId}
                          draggableId={removeSpaceWithJoinNum(
                            elem.label,
                            elem.pageId
                          )}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <p
                                style={{
                                  padding: "12px",
                                  background: "#7366ff",
                                  fontSize: "16px",
                                  marginBottom: "12px",
                                  color: "white",
                                }}
                              >
                                {elem?.contentMapDetaile?.contentDetail
                                  ?.label || elem.label}
                              </p>
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className="d-flex justify-content-center ">
              <h6>No Section Added into this Page</h6>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SectionPositionChange;
