import React, { Fragment, useContext, useEffect, useMemo } from "react";
import Breadcrumbs from "../../layout/breadcrumb";
import { tableId } from "../../utils/helper";
import TopLoadingBar from "../../utils/topLoadingBar";
import { useParams } from "react-router";
import SettingMultiWebItemWebNavBarMap from "./SettingMultiWebItemWebNavBarMap";
import SettingMultiWebAddWebNavBarMap from "./SettingMultiWebAddWebNavBarMap";
import SettingMultiWebAddWebConfigPage from "./SettingMultiWebAddWebConfigPage";
import SettingMultiWebContext from "../../context/SettingMultiWebContext/SettingMultiWebContext";
import SettingMultiWebAddChild from "./SettingMultiWebAddChilds";
import SectionPositionChange from "./SectionPositionChange";
import ManageWebsiteMenu from "./ManageWebsiteMenu";
import PagewiseSectionOrderSetup from "./PagewiseSectionOrderSetup";
import StyleBackUp from "./StyleBackUp";

const SettingMultiWeb = () => {
  const {
    pageSize,
    page,
    progress,
    setProgress,
    formvalue,

    modalNav,
    pageNumberNav,
    pageSizeNav,
    sortOrderNav,
    sortByNav,
    WebNavBarMap_List,

    pageNumberConfig,
    pageSizeConfig,
    sortOrderConfig,
    sortByConfig,
    modalConfig,
    WebConfigPage_List,

    modalChild,
    modalSectionPo,
  } = useContext(SettingMultiWebContext);

  /**useEffect to use of Organization() function call and toploading call to use  */
  let { orgId } = useParams();

  useEffect(() => {
    WebNavBarMap_List({ orgId: orgId });
  }, [pageNumberNav, pageSizeNav, sortOrderNav, sortByNav]);

  useEffect(() => {
    WebConfigPage_List(orgId);
  }, [pageNumberConfig, pageSizeConfig, sortOrderConfig, sortByConfig]);

  useMemo(() => {
    tableId(page, pageSize, formvalue);
  }, [page, pageSize, formvalue]);

  return (
    <Fragment>
      <Breadcrumbs parent="Settings" />
      <TopLoadingBar progress={progress} setProgress={setProgress} />
      <ManageWebsiteMenu />
      <PagewiseSectionOrderSetup />
      <StyleBackUp />
      <SettingMultiWebItemWebNavBarMap
        WebNavBarMap_List={WebNavBarMap_List}
        orgId={orgId}
      />
      {/* <SettingMultiWebItemWebConfigPage
        WebConfigPage_List={WebConfigPage_List}
        orgId={orgId}
      /> */}
      {modalNav && <SettingMultiWebAddWebNavBarMap />}
      {modalConfig && <SettingMultiWebAddWebConfigPage />}
      {modalChild && <SettingMultiWebAddChild />}
      {modalSectionPo && <SectionPositionChange />}
    </Fragment>
  );
};

export default SettingMultiWeb;
